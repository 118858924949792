import React, { useState } from "react";
import {
  TextField,
  Layout,
  Button,
  Card,
  Page,
  FormLayout,
} from "@shopify/polaris";

//axios instanse
import axiosInstance from "../../config/axiosInstance";
// Api
import { LOGIN } from "../../config/ApiConstant";
import { Buffer } from "buffer";
import { emailPattern } from "../../appconfig/Settings";
import ToasterToggle from "../../components/Toster";
import { ASSET_PATH } from "../../appconfig/Settings";

export default function Login() {
  document.title = "Login | Himalayan Stallion";
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isError, setErrors] = useState(false);
  const [login, setLogin] = useState({});
  const [toastMessage, setToastMessage] = useState();
  const [toasttoggle, setToastToggle] = useState(false);
  const [iserror, setisError] = useState(false);
  const _onHandleChange = (name, value) => {
    setLogin({ ...login, [name]: value });
  };

  //Validation registration data
  const validate = () => {
    let isValidate = true;
    let error = {};
    if (window.cn(login.email) && !window.cb(login.email)) {
      error = { ...error, email: "Enter Email*" };
      isValidate = false;
    } else if (!emailPattern.test(login.email)) {
      error = { ...error, email: "Enter Valid Email*" };
      isValidate = false;
    } else {
      error = { ...error, email: "" };
    }
    if (window.cn(login.password)) {
      isValidate = false;
      error["password"] = "Enter Password*";
    }
    setErrors(error);
    return isValidate;
  };

  const _submitForm = async () => {
    try {
      if (validate()) {
        setButtonLoading(true);

        const email = login.email.trim();
        let passwordBuff = Buffer.from(login.password).toString("base64");

        const requestData = {
          email: email,
          password: passwordBuff,
          type: "Admin",
        };

        let apiUrl = LOGIN;
        const response = await axiosInstance.post(apiUrl, requestData);

        if (response.data) {
          setToastToggle(true);
          setToastMessage("Login Successful");
          setisError(false);
        }

        //Set Token in Localstorage
        if (response.data.token) {
          const token = response.data.token;
          const name = response.data.user_details.first_name;

          //Set Localstorage
          localStorage.setItem("token", token);
          localStorage.setItem("name", name);
          setButtonLoading(false);

          //   Redirect to the Home
          window.location.href = "/home";
        }
      }
    } catch (error) {
      if (error.response) {
        setToastToggle(true);
        setToastMessage(error.response.data.error);
        setisError(true);
      }
    }
  };

  return (
    <Page>
      <div style={{ transform: "translate(0%,40%)" }}>
        <Layout>
          <Layout.AnnotatedSection
            title={<h4>Welcome To Himalayan Stallion</h4>}
            description={
              <h6>
                Himalayan Stallion founded by Jeet Selal in December 2016 in
                Melbourne, Australia with a sole purpose of promoting awareness
                on health and Fitness dedicated to Indian Youth.
              </h6>
            }
          >
            <Layout.Section oneHalf>
              <Card sectioned>
                <div style={{ textAlign: "center" }}>
                  <img
                    src={ASSET_PATH + "/front/logo.png"}
                    alt="No-Display"
                  ></img>
                </div>
                <br />
                <FormLayout>
                  <TextField
                    autoComplete="off"
                    label="Email"
                    placeholder="Please enter your email"
                    value={login.email || ""}
                    onChange={(value) => _onHandleChange("email", value)}
                    error={isError.email}
                  />
                  <TextField
                    autoComplete="off"
                    label="Password"
                    type="password"
                    placeholder="Please enter your password"
                    value={login.password || ""}
                    onChange={(value) => _onHandleChange("password", value)}
                    error={isError.password}
                  />
                  <Button primary loading={buttonLoading} onClick={_submitForm}>
                    Submit
                  </Button>
                </FormLayout>
              </Card>
            </Layout.Section>
          </Layout.AnnotatedSection>
        </Layout>
      </div>
      {toasttoggle && (
        <ToasterToggle
          toastmessage={toastMessage}
          toastactive={() => setToastToggle(false)}
          isErrorMessage={iserror}
        />
      )}
    </Page>
  );
}
