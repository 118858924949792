import React from "react";
import { Route, Switch } from "react-router-dom";
import AdminAppContainer from "../layouts/AdminAppContainer";

import Home from "../pages/homepage/Home";
import HomeBanner from "../pages/homepage/HomeBaneer/HomeBanner";
import AddHomeBanner from "../pages/homepage/HomeBaneer/AddHomeBanner";
import UpdateHomeBanner from "../pages/homepage/HomeBaneer/UpdateHomeBanner";
import AddMission from "../pages/homepage/Mission/AddMission";
import Mission from "../pages/homepage/Mission/Mission";
import UpdateMission from "../pages/homepage/Mission/UpdateMissionData";
import Founder from "../pages/homepage/Founder/Founder";
import AddFounder from "../pages/homepage/Founder/AddFounder";
import UpdateFounder from "../pages/homepage/Founder/UpdateFounder";
import Clients from "../pages/Common/Client/OurClients";
import AddClients from "../pages/Common/Client/AddClients";
import About from "../pages/about/About/About";
import AddAbout from "../pages/about/About/AddAbout";
import Program from "../pages/homepage/Program/Program";
import UpdateClients from "../pages/Common/Client/UpdateClients";
import Vision from "../pages/about/Vision/Vision";
import AddVision from "../pages/about/Vision/AddVision";
import UpdateVision from "../pages/about/Vision/UpdateVision";
import Events from "../pages/events/Events";
import AddEvents from "../pages/events/AddEvents";
import UpdateEvents from "../pages/events/UpdateEvents";
import Contact from "../pages/Contact/Contact";
import AddConnect from "../pages/Contact/AddConnect";
import UpdateAbout from "../pages/about/About/UpdateAbout";
import Academy from "../pages/academy/Academy/Academy";
import AddACademy from "../pages/academy/Academy/AddAcademy";
import UpdateACademy from "../pages/academy/Academy/UpdateAcademy";
import OnlineCourse from "../pages/academy/OnlineCourse/OnlineCourse";
import AddOnlineCourse from "../pages/academy/OnlineCourse/AddOnlineCourse";
import UpdateOnlineCourse from "../pages/academy/OnlineCourse/UpdateOnlineCourse";
import LiveClasses from "../pages/academy/LiveClasses/LiveClasses";
import AddLiveClasses from "../pages/academy/LiveClasses/AddLiveClasses";
import UpdateLiveClasses from "../pages/academy/LiveClasses/UpdateLiveClasses";
import LiveCourseCertification from "../pages/homepage/LiveCourseCertification/LiveCourseCertification";
import AddLiveCourseCertification from "../pages/homepage/LiveCourseCertification/AddLiveCourseCertification";
import UpdateLiveCourseCertifications from "../pages/homepage/LiveCourseCertification/UpdateLiveCourseCertifications";
import AddOnlineCourses from "../pages/onlinecourse/OnlineCourses/AddOnlineCourses";
import AllOnlineCourses from "../pages/onlinecourse/OnlineCourses/AllOnlineCourses";
import AddOnlineCourseBanner from "../pages/onlinecourse/OnlineCourseBanner/AddOnlineCourseBanner";
import AllOnlineCourseBanner from "../pages/onlinecourse/OnlineCourseBanner/AllOnlineCourseBanner";
import UpdateOnlineCourses from "../pages/onlinecourse/OnlineCourses/UpdateOnlineCourses";
import UpdateOnlineCourseBanner from "../pages/onlinecourse/OnlineCourseBanner/UpdateOnlineCourseBanner";
import AllFreeProgram from "../pages/freeprogram/AllFreeProgram";
import AddFreeProgram from "../pages/freeprogram/AddFreeProgram";
import UpdateFreeProgram from "../pages/freeprogram/UpdateFreeProgram";
import UpdateConnect from "../pages/Contact/UpdateConnect";
import UserReport from "../pages/reports/userReport/UserReport";
import OnlineCourseReport from "../pages/reports/onlinecoursereport/OnlineCourseReport";
import LiveClassReport from "../pages/reports/liveclassreport/LiveClassReport";
import Inquiry from "../pages/inquiry/Inquiry";
import AffiliateReport from "../pages/reports/affiliatereport/AffiliateReport";
import AffiliateDashboard from "../pages/homepage/AffiliateDashboard";
import ProfileDetails from "../pages/reports/affiliatereport/ProfileDetails";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <AdminAppContainer>
      <Switch>
        {/* ***** Home Page route ********** */}
        {/* Home Bannerr route */}
        <Route exact path="/home" component={Home} />
        <Route exact path="/homebanner" component={HomeBanner} />
        <Route exact path="/addhomebanner" component={AddHomeBanner} />
        <Route exact path="/updatehomebanner" component={UpdateHomeBanner} />
        <Route
          exact
          path="/affiliatedashboard"
          component={AffiliateDashboard}
        />
        {/* Mission route */}
        <Route exact path="/mission" component={Mission} />
        <Route exact path="/addmission" component={AddMission} />
        <Route exact path="/updatemission" component={UpdateMission} />
        {/* Program route */}
        <Route exact path="/program" component={Program} />
        {/* Live Course and Certfication route */}
        <Route
          exact
          path="/home/livecourse"
          component={LiveCourseCertification}
        />
        <Route
          exact
          path="/addlivecourse"
          component={AddLiveCourseCertification}
        />
        <Route
          exact
          path="/updatelivecourse"
          component={UpdateLiveCourseCertifications}
        />
        <Route
          exact
          path="/viewlivecourse"
          component={UpdateLiveCourseCertifications}
        />

        {/* about route */}
        <Route exact path="/home/about" component={Founder} />
        <Route exact path="/home/addabout" component={AddFounder} />
        <Route exact path="/home/updateabout" component={UpdateFounder} />
        {/* Our Clients Route */}
        <Route exact path="/clients" component={Clients} />
        <Route exact path="/addclients" component={AddClients} />
        <Route exact path="/updateclients" component={UpdateClients} />
        {/* ***** About Page Route ******* */}
        <Route exact path="/about" component={About} />
        <Route exact path="/addabout" component={AddAbout} />
        <Route exact path="/updateabout" component={UpdateAbout} />
        {/* About Page Vision Route start */}
        <Route exact path="/vision" component={Vision} />
        <Route exact path="/addvision" component={AddVision} />
        <Route exact path="/updatevision" component={UpdateVision} />
        {/* Event PAge Route */}
        <Route exact path="/events" component={Events} />
        <Route exact path="/addevents" component={AddEvents} />
        <Route exact path="/updateevents" component={UpdateEvents} />
        {/* Connect Page Route */}
        <Route exact path="/connect" component={Contact} />
        <Route exact path="/addconnect" component={AddConnect} />
        <Route exact path="/updateconnect" component={UpdateConnect} />
        {/******* Academy Module Route start  *******/}
        {/* Academy Page Route */}
        <Route exact path="/academy" component={Academy} />
        <Route exact path="/addacademy" component={AddACademy} />
        <Route exact path="/updateacademy" component={UpdateACademy} />
        {/* Online Course Page Route */}
        <Route exact path="/academy/onlinecourse" component={OnlineCourse} />
        <Route
          exact
          path="/academy/addonlinecourse"
          component={AddOnlineCourse}
        />
        <Route
          exact
          path="/academy/updateonlinecourse"
          component={UpdateOnlineCourse}
        />
        {/* Live CLasses Page Route */}
        <Route exact path="/liveclasses" component={LiveClasses} />
        <Route exact path="/addliveclasses" component={AddLiveClasses} />
        <Route exact path="/updateliveclasses" component={UpdateLiveClasses} />
        {/******* Academy Module Route ENd  *******/}

        {/* ******** Online course module / page route start ********* */}
        {/* All Online Course Page Route */}
        <Route exact path="/onlinecourses" component={AllOnlineCourses} />
        <Route exact path="/addonlinecourses" component={AddOnlineCourses} />
        <Route
          exact
          path="/updateonlinecourses"
          component={UpdateOnlineCourses}
        />
        <Route
          exact
          path="/onlinecoursebanner"
          component={AllOnlineCourseBanner}
        />
        <Route
          exact
          path="/addonlinecoursesbanner"
          component={AddOnlineCourseBanner}
        />

        <Route
          exact
          path="/updateonlinecoursebanner"
          component={UpdateOnlineCourseBanner}
        />

        {/* ******** Online course module / page route End ********* */}

        {/* ******** Free Program module / page route start ********* */}
        {/* All Free Program Page Route */}
        <Route exact path="/freeprogram" component={AllFreeProgram} />
        <Route exact path="/addfreeprogram" component={AddFreeProgram} />
        <Route exact path="/updatefreeprogram" component={UpdateFreeProgram} />

        {/* End Footer Page Route */}

        {/* All  Reports Page Route */}

        <Route exact path="/user-report" component={UserReport} />
        <Route
          exact
          path="/onlinecourse-report"
          component={OnlineCourseReport}
        />
        <Route exact path="/liveclass-report" component={LiveClassReport} />
        <Route exact path="/affiliate-report" component={AffiliateReport} />
        {/* End Reports Page Route */}

        {/* Contact Inquiry Page */}
        <Route exact path="/inquiry" component={Inquiry} />
        <Route exact path="/profiledetails/:id" component={ProfileDetails} />
      </Switch>
    </AdminAppContainer>
  );
};
