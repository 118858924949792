import React, { useCallback, useEffect, useState } from "react";
import { Banner, List, DropZone, Stack, Thumbnail } from "@shopify/polaris";
import { NoteMinor } from "@shopify/polaris-icons";

export default function AddImage({ getImagesFromAddImage }) {
  useEffect(() => {
    getImagesFromAddImage({
      getImages: sendImagesToParent,
      remove: dropzoneremove,
    });
  });

  const sendImagesToParent = () => {
    return files;
  };

  const validImageTypes = ["image/jpeg", "image/png"];
  const [files, setFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);

  const handleDropZone = useCallback(
    (_dropFiles, _acceptedFiles, _rejectedFiles) => {
      if (_rejectedFiles.length > 0) {
        setRejectedFiles((files) => [..._rejectedFiles]);
      }
      if (_acceptedFiles.length > 0) {
        setRejectedFiles([]);
        setFiles((files) => [..._acceptedFiles]);
      }
    }
  );

  const dropzoneremove = useCallback(() => {
    setFiles([]);
    uploadedFiles();
  }, [files]);

  const uploadedFiles = files.length > 0 && (
    <div style={{ margin: 10 }}>
      <Stack alignment={"center"} distribution={"center"} spacing={"loose"}>
        {files.map((file, index) => (
          <Stack alignment="center" spacing={"tight"} key={index}>
            <div className="show-image">
              <Thumbnail
                size="large"
                alt={file.name}
                source={
                  validImageTypes.includes(file.type)
                    ? window.URL.createObjectURL(file)
                    : NoteMinor
                }
              />
            </div>
          </Stack>
        ))}
      </Stack>
    </div>
  );

  const hasError = rejectedFiles.length > 0;

  const errorMessage = hasError && (
    <Banner
      title="The following images couldn\u2019t be uploaded:"
      status="critical"
    >
      <List type="bullet">
        {rejectedFiles.map((file, index) => (
          <List.Item key={index}>
            {`"${file?.name}" is not supported. File type must be .gif, .jpg, .png or .svg.`}
          </List.Item>
        ))}
      </List>
    </Banner>
  );

  return (
    <>
      {errorMessage}

      <div className="image_add_box">
        <DropZone
          accept="image/*"
          type="image"
          onDrop={handleDropZone}
          allowMultiple={false}
        >
          <DropZone.FileUpload />
        </DropZone>
      </div>

      {uploadedFiles}
    </>
  );
}
