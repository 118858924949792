import React, { Component } from "react";

// Import Polaris Component
import { Stack } from "@shopify/polaris";
import { IMAGE_BASEURL } from "../../appconfig/Settings";

class DataTableEmptyState extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <div className="Polaris-ResourceList__EmptySearchResultWrapper">
          <div className="Polaris-Stack Polaris-Stack--vertical Polaris-Stack--alignmentCenter">
            <Stack.Item>
              <img
                src={IMAGE_BASEURL}
                alt="search_img"
                className="Polaris-EmptySearchResult__Image"
              />
            </Stack.Item>
            <Stack.Item>
              <p className="Polaris-DisplayText Polaris-DisplayText--sizeSmall">
                No Data found
              </p>
            </Stack.Item>
          </div>
        </div>
      </>
    );
  }
}

export default DataTableEmptyState;
