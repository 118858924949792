import React, { useState, useEffect } from "react";
//shopify
import {
  Card,
  TextField,
  Page,
  Stack,
  FormLayout,
  Button,
  Thumbnail,
} from "@shopify/polaris";
import "../../assets/scss/helicore_common.scss";
import ToasterToggle from "../../components/Toster";
import { ADD_FREEPROGRAM, GET_FREEPROGRAM } from "../../config/ApiConstant";
import axiosInstance from "../../config/axiosInstance";
import { useHistory } from "react-router-dom";
import PdfUpload from "../../components/PdfUpload";
import { NoteMinor } from "@shopify/polaris-icons";

const UpdateFreeProgram = (props) => {
  const [freeProgram, setFreeProgram] = useState({});
  const [formValues, setFormValues] = useState([{ video_title: "" }]);
  const [videoArr] = useState([]);
  const [validation] = useState({
    title: false,
    descriptionData: false,
  });

  document.title = "Online Course | Himalayan Stallion";
  const [submitLoading, setSubmitLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState();
  const [toasttoggle, setToastToggle] = useState(false);
  const [isError, setisError] = useState(false);
  const [editId, setEditId] = useState(null);

  const history = useHistory();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getFreeProgramData();
  }, []);

  let addFormFields = () => {
    setFormValues([...formValues, { video_title: "" }]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    videoArr.splice(i, 1);
    setFormValues(newFormValues);
  };

  //images variables
  let getPdfRefFromAddPdf = null;

  //gets media images
  const getpdfFromAddPdf = (ref) => {
    getPdfRefFromAddPdf = ref;

    const getPdf = getPdfRefFromAddPdf?.getPdf();

    const getIndex = getPdfRefFromAddPdf?.getIndexNumber();
    if (getPdf.length > 0) {
      videoArr[getIndex] = getPdf[0];
    }
  };

  //Handle Change
  const handleChange = (name, value) => {
    setFreeProgram({ ...freeProgram, [name]: value });
  };

  let handleChangeData = (name, value, i) => {
    let newFormValues = [...formValues];
    newFormValues[i][name] = value;
    setFormValues(newFormValues);
  };

  //checks validation
  const validate = () => {
    let validate = true;

    return validate;
  };

  //get OnlineCourse data by id
  const getFreeProgramData = async () => {
    const onlineCourseId = props.location.state;
    setEditId(onlineCourseId);

    const response = await axiosInstance(
      GET_FREEPROGRAM + "?id=" + onlineCourseId
    );

    if (response) {
      const DataList = response.data.data;
      const { video_id } = DataList;
      setFreeProgram({ ...freeProgram, video_id: video_id });

      const { pdf } = DataList;

      setFormValues(JSON.parse(pdf));
    }
  };

  //Add data
  const onSubmit = async () => {
    try {
      const isValidate = validate();
      if (isValidate) {
        setSubmitLoading(true);

        const formData = new FormData();

        //set formData
        for (let i = 0; i < videoArr.length; i++) {
          formData.append("url[" + i + "]", videoArr[i]);
        }

        formData.append("video_id", freeProgram.video_id);

        formData.append("id", editId);
        formData.append("title", JSON.stringify(formValues));

        // Api call
        const response = await axiosInstance.post(ADD_FREEPROGRAM, formData);
        if (response.data) {
          setSubmitLoading(false);
          setFreeProgram({});
          setEditId(null);
          setTimeout(() => {
            history.push("/freeprogram");
          }, 2000);
          setToastToggle(true);
          setToastMessage(response.data.message);
          setisError(false);
        }
      }
    } catch (error) {
      if (error.response) {
        setSubmitLoading(false);
        setToastToggle(true);
        setToastMessage(error.response.data.error);
        setisError(true);
      }
    }
  };

  //CllearData
  const clearAll = () => {
    setFreeProgram({});
    setEditId(null);
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <Page
      title="Free Program"
      fullWidth
      breadcrumbs={[
        {
          content: "Reports",
          onAction: handleBack,
        },
      ]}
    >
      <Card
        sectioned
        secondaryFooterActions={[
          { content: "Cancel", onAction: () => clearAll() },
        ]}
        primaryFooterAction={{
          content: <>Update</>,
          loading: submitLoading,
          onAction: () => onSubmit(),
        }}
      >
        <FormLayout>
          <Stack.Item>
            <TextField
              label="Enter URL"
              value={freeProgram.video_id}
              onChange={(value) => handleChange("video_id", value)}
              autoComplete="off"
            />
          </Stack.Item>

          {formValues.map((element, index) => (
            <>
              <Stack.Item>
                <TextField
                  label="PDF Name"
                  value={formValues[index].title}
                  onChange={(value) => handleChangeData("title", value, index)}
                  autoComplete="off"
                  error={
                    validation.video_title ? "PDF Name can't be blank." : false
                  }
                />
              </Stack.Item>

              {formValues ? (
                <>
                  <label className="Polaris-Label__Text label-font">
                    Old PDF
                  </label>

                  <a
                    href={formValues[index].url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Thumbnail source={NoteMinor} size="large" alt="No PDF" />
                  </a>
                </>
              ) : (
                ""
              )}

              <Stack.Item>
                <label className="Polaris-Label__Text Polaris-Label__RequiredIndicator label-font">
                  PDF
                </label>
                <PdfUpload
                  indexNumber={index}
                  getPdfFromAddPdf={getpdfFromAddPdf}
                />
                {validation.image ? (
                  <div className="Polaris-InlineError">
                    <div className="Polaris-InlineError__Icon">
                      <span className="Polaris-Icon">
                        <span className="Polaris-VisuallyHidden"></span>
                        <svg
                          viewBox="0 0 20 20"
                          className="Polaris-Icon__Svg"
                          focusable="false"
                          aria-hidden="true"
                        >
                          <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zM9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"></path>
                        </svg>
                      </span>
                    </div>
                    <span>PDF is required</span>
                  </div>
                ) : null}
              </Stack.Item>

              <Stack distribution="leading">
                <Stack.Item>
                  {index ? (
                    <div style={{ color: "#bf0711", marginTop: "10px" }}>
                      <Button
                        monochrome
                        outline
                        onClick={() => removeFormFields(index)}
                      >
                        Remove PDF
                      </Button>
                    </div>
                  ) : null}
                </Stack.Item>

                <Stack.Item>
                  <div style={{ marginTop: "10px" }}>
                    <Button primary onClick={() => addFormFields()}>
                      Add More PDF
                    </Button>
                  </div>
                </Stack.Item>
              </Stack>

              <hr />
            </>
          ))}
        </FormLayout>
      </Card>

      {/* toaster */}
      {toasttoggle && (
        <ToasterToggle
          toastmessage={toastMessage}
          toastactive={() => setToastToggle(false)}
          isErrorMessage={isError}
        />
      )}
    </Page>
  );
};

export default UpdateFreeProgram;
