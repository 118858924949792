import React, { useState, useEffect } from "react";
//shopify
import {
  Card,
  TextField,
  TextStyle,
  Page,
  Stack,
  FormLayout,
  Select,
} from "@shopify/polaris";
import "../../../assets/scss/helicore_common.scss";
import ToasterToggle from "../../../components/Toster";
import {
  ADD_ACADEMYDATA,
  GET_ACADEMYDATA_BYID,
} from "../../../config/ApiConstant";
import axiosInstance from "../../../config/axiosInstance";
import CKEditor from "react-ckeditor-component";
import { useHistory } from "react-router-dom";
import { Selectoptions } from "../../../appconfig/Settings";
import UpdateImages from "../../../components/UpdateImages";

const UpdateLiveClasses = (props) => {
  const [onlineCourse, setOnllineCourse] = useState({});
  const [validation, setValidation] = useState({
    title: false,
    descriptionData: false,
    button_title: false,
    button_link: false,
    image: false,
  });

  document.title = "Live Classes | Himalayan Stallion";
  const [submitLoading, setSubmitLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState();
  const [toasttoggle, setToastToggle] = useState(false);
  const [isError, setisError] = useState(false);
  const [editId, setEditId] = useState(null);
  const [descriptionData, setDescriptionData] = useState();
  const [updatedImage, setUpdatedImage] = useState([]);
  const history = useHistory();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getLiveClassesData();
  }, []);

  //images variables
  let getImagesRefFromUpdateImage = null;

  //gets media images
  const getImagesFromUpdateImage = (ref) => {
    getImagesRefFromUpdateImage = ref;
  };

  // handleChange
  const handleChange = (name, value) => {
    let validations = true;
    if (value !== "" && value !== undefined) {
      validations = false;
    }
    setValidation((prev) => ({ ...prev, [name]: validations }));
    setOnllineCourse({ ...onlineCourse, [name]: value });
  };

  //onChange description
  const onChangeDesc = (name, event) => {
    var newContent = event.editor.getData();

    if (name === "description") {
      setDescriptionData(newContent);
    }

    let validations = true;
    if ([name] !== "" && [name] !== undefined) {
      validations = false;
    }

    setValidation((prev) => ({ ...prev, descriptionData: validations }));
  };

  //checks validation
  const validate = () => {
    let validate = true;
    const image = getImagesRefFromUpdateImage?.getImages();

    //Validation
    if (window.cn(onlineCourse.title)) {
      setValidation((prev) => ({ ...prev, title: true }));
      validate = false;
    }
    if (window.cn(onlineCourse.button_title)) {
      setValidation((prev) => ({ ...prev, button_title: true }));
      validate = false;
    }
    if (window.cn(onlineCourse.button_link)) {
      setValidation((prev) => ({ ...prev, button_link: true }));
      validate = false;
    }
    if (window.cn(descriptionData)) {
      setValidation((prev) => ({ ...prev, descriptionData: true }));
      validate = false;
    }
    if (editId == null || editId === "" || editId === undefined) {
      if (image.length <= 0) {
        setValidation((prev) => ({ ...prev, image: true }));
        validate = false;
      }
    }
    return validate;
  };

  //get OnlineCourse data by id
  const getLiveClassesData = async () => {
    const liveClassesId = props.location.state;
    setEditId(liveClassesId);

    const response = await axiosInstance(
      GET_ACADEMYDATA_BYID + "/" + liveClassesId
    );
    if (response) {
      const DataList = response.data.data;
      const { title, images, button_link, button_title, description } =
        DataList;

      setOnllineCourse({
        ...onlineCourse,
        title: title,
        button_title: button_title,
        button_link: button_link,
      });
      setUpdatedImage(JSON.parse(images));
      setDescriptionData(description);
    }
  };

  //Add data
  const onSubmit = async () => {
    try {
      const isValidate = validate();
      if (isValidate) {
        setSubmitLoading(true);

        const formData = new FormData();

        const images = getImagesRefFromUpdateImage?.getImages();

        const { title, button_title, button_link } = onlineCourse;

        //set formData
        for (let i = 0; i < images.length; i++) {
          formData.append("images[]", images[i]);
        }
        formData.append("title", title);
        formData.append("button_title", button_title);
        formData.append("button_link", button_link);
        formData.append("description", descriptionData);
        formData.append("id", editId);
        formData.append("content_type", "academy_liveClassesData");

        // Api call
        const response = await axiosInstance.post(ADD_ACADEMYDATA, formData);
        if (response.data) {
          setSubmitLoading(false);
          setOnllineCourse({});
          setDescriptionData("");
          setEditId(null);
          setUpdatedImage([]);
          setTimeout(() => {
            history.push("/liveclasses");
          }, 2000);
          setToastToggle(true);
          setToastMessage(response.data.message);
          setisError(false);
          getImagesRefFromUpdateImage?.remove();
        }
      }
    } catch (error) {
      if (error.response) {
        setSubmitLoading(false);
        setToastToggle(true);
        setToastMessage(error.response.data.error);
        setisError(true);
      }
    }
  };

  //CllearData
  const clearAll = () => {
    setOnllineCourse({});
    setDescriptionData("");
    setUpdatedImage([]);
    setEditId(null);
    getImagesRefFromUpdateImage?.remove();
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <Page
      title="Live Classes"
      fullWidth
      breadcrumbs={[
        {
          content: "Reports",
          onAction: handleBack,
        },
      ]}
    >
      <Card
        sectioned
        secondaryFooterActions={[
          { content: "Cancel", onAction: () => clearAll() },
        ]}
        primaryFooterAction={{
          content: <>Update</>,
          loading: submitLoading,
          onAction: () => onSubmit(),
        }}
      >
        <FormLayout>
          <TextField
            label="Page Title"
            value={onlineCourse.title}
            onChange={(value) => handleChange("title", value)}
            autoComplete="off"
            error={validation.title ? "Page Title can't be blank." : false}
          />

          <Stack.Item>
            <label className="Polaris-Label__Text Polaris-Label__RequiredIndicator label-font">
              Image
            </label>
            <UpdateImages
              getImagesFromUpdateImage={getImagesFromUpdateImage}
              getImageArray={updatedImage}
              getUpdatedId={editId}
              getContentType={"Academy"}
            />
            {validation.image ? (
              <div className="Polaris-InlineError">
                <div className="Polaris-InlineError__Icon">
                  <span className="Polaris-Icon">
                    <span className="Polaris-VisuallyHidden"></span>
                    <svg
                      viewBox="0 0 20 20"
                      className="Polaris-Icon__Svg"
                      focusable="false"
                      aria-hidden="true"
                    >
                      <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zM9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"></path>
                    </svg>
                  </span>
                </div>
                <span>Image is required</span>
              </div>
            ) : null}
            <TextStyle variation="warning">
              Note:Image size (1440px*760px).
            </TextStyle>
          </Stack.Item>

          <Stack.Item>
            <label className="Polaris-Label__Text label-font">
              Page Description
            </label>
            <CKEditor
              activeClass="p10"
              content={descriptionData || ""}
              events={{
                change: (content) => onChangeDesc("description", content),
              }}
            />

            {validation.descriptionData ? (
              <div className="Polaris-InlineError">
                <div className="Polaris-InlineError__Icon">
                  <span className="Polaris-Icon">
                    <span className="Polaris-VisuallyHidden"></span>
                    <svg
                      viewBox="0 0 20 20"
                      className="Polaris-Icon__Svg"
                      focusable="false"
                      aria-hidden="true"
                    >
                      <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zM9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"></path>
                    </svg>
                  </span>
                </div>
                <span>Page Description can't be blank</span>
              </div>
            ) : null}
          </Stack.Item>

          <FormLayout.Group>
            <TextField
              label="Button"
              value={onlineCourse.button_title}
              onChange={(value) => handleChange("button_title", value)}
              autoComplete="off"
              error={
                validation.button_title ? "Button Title can't be blank." : false
              }
            />

            <Stack.Item>
              <label
                className="Polaris-Label__Text label-font"
                style={{ marginBottom: "0.25rem" }}
              >
                Button Link
              </label>
              <Select
                placeholder="Select Link"
                onChange={(value) => handleChange("button_link", value)}
                value={onlineCourse.button_link}
                options={Selectoptions}
              ></Select>
              {validation.button_link ? (
                <div className="Polaris-InlineError">
                  <div className="Polaris-InlineError__Icon">
                    <span className="Polaris-Icon">
                      <span className="Polaris-VisuallyHidden"></span>
                      <svg
                        viewBox="0 0 20 20"
                        className="Polaris-Icon__Svg"
                        focusable="false"
                        aria-hidden="true"
                      >
                        <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zM9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"></path>
                      </svg>
                    </span>
                  </div>
                  <span>Select Link</span>
                </div>
              ) : null}
            </Stack.Item>
          </FormLayout.Group>
        </FormLayout>
      </Card>

      {/* toaster */}
      {toasttoggle && (
        <ToasterToggle
          toastmessage={toastMessage}
          toastactive={() => setToastToggle(false)}
          isErrorMessage={isError}
        />
      )}
    </Page>
  );
};

export default UpdateLiveClasses;
