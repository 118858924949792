import React, { useState, useEffect } from "react";
//shopify
import {
  Card,
  DataTable,
  Page,
  Stack,
  Select,
  Pagination,
  Button,
  Spinner,
  Icon,
  TextField,
} from "@shopify/polaris";

import { defaultPageOptions, showing } from "../../appconfig/Settings";
import { columnsToRows, setPagination } from "../../appconfig/AppHelper";
import ToasterToggle from "../../components/Toster";
import { DELETE_FREEPROGRAM, GET_FREEPROGRAM } from "../../config/ApiConstant";
import DeleteConfirmModal from "../../components/DeleteConfirmModel";
import DataTableEmptyState from "../../components/DataTable/DataTableEmptyState";
import SkeletonDataTable from "../../components/DataTable/SkeletonDataTable";
import {
  COLUMNS_KEY,
  AllFreeProgramsColumns,
} from "../../appconfig/dataTableSetting";
import { SearchMinor } from "@shopify/polaris-icons";

import "../../assets/scss/helicore_common.scss";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../config/axiosInstance";
import PDFViewModel from "../../components/PDFViewModel";

const AllFreeProgram = () => {
  document.title = "Free Program | Himalayan Stallion";
  const [pageLoad, setPageLoad] = useState(false);
  const [tableRows, setTableRows] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [paginationInner, setPageinationInner] = useState(setPagination());
  const [dataTableInnerLoading, setDataTableInnerLoading] = useState(false);
  const [perPage, setPerPage] = useState(showing);
  const [toastMessage, setToastMessage] = useState();
  const [toasttoggle, setToastToggle] = useState(false);
  const [isError] = useState(false);
  const [paginationActive, setPaginationActive] = useState(false);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [closeModel, setCloseModel] = useState(false);
  const [deleteBtnSpinner, setdeleteBtnSpinner] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [pdfID, setPdfID] = useState(null);
  const [search, setSearch] = useState("");

  const history = useHistory();

  useEffect(() => {
    getDataFromAPI();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  // Call when per page change
  useEffect(() => {
    getDataFromAPI();
  }, [perPage]);

  // Page Handle Change
  const handleChangePerpage = (value) => {
    paginationInner.page = 1;
    setPerPage(value);
  };

  // Call next page handler
  const handleChangePage = (action) => {
    action === "Next" ? paginationInner.page++ : paginationInner.page--;
    getDataFromAPI();
  };

  // Search Handler
  const handleTitleChange = (value) => {
    setSearch(value);
  };

  // Clear Search value
  const handleClearTitle = () => {
    setSearch("");
  };

  // search
  const handleSearchButton = () => {
    setSubmitLoading(true);
    paginationInner.page = 1;
    getDataFromAPI();
    setSubmitLoading(false);
  };

  // Set Video Pdf
  const videPdfData = (pdfID) => {
    setPdfID(pdfID);
    setCloseModel(true);
  };

  //PDF Modal close
  const closePDFModal = () => {
    setDeleteId(null);
    setCloseModel(false);
  };

  //api call
  const getDataFromAPI = async () => {
    if (pageLoad) setDataTableInnerLoading(true);
    const response = await axiosInstance(GET_FREEPROGRAM);

    if (response) {
      const freeProgramList = response.data.data;
      if (freeProgramList.length === 0) {
        setTableRows([]);
      } else {
        let tableData = [];
        for (let i = 0; i < freeProgramList.length; i++) {
          let tableRowsAppend = [];

          let vide0Id = freeProgramList[i].video_id;

          tableRowsAppend.push(
            <iframe
              width="350"
              height="180"
              src={`https://www.youtube.com/embed/${vide0Id}`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          );

          tableRowsAppend.push(
            <Stack.Item>
              <Button onClick={() => videPdfData(freeProgramList[i].id)}>
                View All PDF
              </Button>

              <Button primary onClick={() => editData(freeProgramList[i].id)}>
                Edit
              </Button>

              <Button
                destructive
                onClick={() => deleteData(freeProgramList[i].id)}
              >
                Delete
              </Button>
            </Stack.Item>
          );
          tableData.push(tableRowsAppend);
        }
        setTableRows(tableData);
        setPaginationActive(true);
      }
      setDataTableInnerLoading(false);
      setPageLoad(true);

      //Get Responce Data and Set respectively
      const { current_page, total, per_page, last_page } = response.data.data;
      const pagination = setPagination(
        current_page,
        per_page,
        last_page,
        total
      );
      setPageinationInner(pagination);
    }
  };

  //editData
  const editData = (programId) => {
    let path = `/updatefreeprogram`;
    history.push({ pathname: path, state: programId });
  };

  //DeleteData
  const deleteData = (deleteId) => {
    setDeleteId(deleteId);
    setDeleteConfirmModal(true);
  };

  //Delete Modal close
  const closeConfirmModal = () => {
    setDeleteId(null);
    setDeleteConfirmModal(false);
  };

  //Delete ConfirmSubmit
  const confirmSubmit = async () => {
    try {
      setdeleteBtnSpinner(true);
      const response = await axiosInstance.delete(
        DELETE_FREEPROGRAM + "/" + deleteId
      );
      if (response.data) {
        setDeleteConfirmModal(false);
        setdeleteBtnSpinner(false);
        getDataFromAPI();
        setToastToggle(true);
        setToastMessage(response.data.message);
      }
    } catch (error) {
      if (error.response) {
        setdeleteBtnSpinner(false);
        setToastToggle(true);
        setToastMessage(error.response.data.error);
      }
    }
  };

  //goto addhomebanner
  const goToAddFreeProgram = () => {
    history.push("/addfreeprogram");
  };

  const Paginate = () => {
    return (
      <>
        <Pagination
          previousTooltip="Previous"
          hasPrevious={paginationInner.hasPrevious}
          onPrevious={() => handleChangePage("Previous")}
          hasNext={paginationInner.hasNext}
          nextTooltip="Next"
          onNext={() => handleChangePage("Next")}
        />
      </>
    );
  };

  return (
    <Page
      title="Free Program"
      fullWidth
      primaryAction={{
        content: "Add Free Program",
        onAction: goToAddFreeProgram,
      }}
    >
      <Card>
        <Card.Section subdued>
          <Stack distribution="trailing">
            <Stack.Item fill>
              <TextField
                labelHidden
                autoComplete="off"
                placeholder="Enter Title"
                value={search || ""}
                onChange={(value) => handleTitleChange(value)}
                prefix={<Icon source={SearchMinor} color="inkLighter" />}
                clearButton
                onClearButtonClick={() => handleClearTitle()}
              />
            </Stack.Item>
            <Stack.Item>
              <Button
                loading={submitLoading}
                onClick={() => handleSearchButton()}
                primary
                fullWidth
              >
                SEARCH
              </Button>
            </Stack.Item>
            <Stack.Item>
              <Select
                label="Show"
                labelInline
                onChange={handleChangePerpage}
                value={perPage}
                options={defaultPageOptions}
              ></Select>
            </Stack.Item>
          </Stack>
        </Card.Section>
        <div className="data-wrapper">
          {pageLoad ? (
            <div>
              <DataTable
                verticalAlign="middle"
                hideScrollIndicator={true}
                columnContentTypes={columnsToRows(
                  AllFreeProgramsColumns,
                  COLUMNS_KEY.TYPE
                )}
                headings={columnsToRows(
                  AllFreeProgramsColumns,
                  COLUMNS_KEY.NAME
                )}
                rows={tableRows}
                footerContent={
                  tableRows.length > 0 ? (
                    <span>{paginationInner.showing}</span>
                  ) : null
                }
              />
              {tableRows.length > 0 ? (
                <div className="paginate">
                  <Stack distribution="center">
                    {paginationActive ? <Paginate /> : null}
                  </Stack>
                </div>
              ) : (
                <DataTableEmptyState />
              )}
            </div>
          ) : (
            <SkeletonDataTable
              defaultLength={2}
              columns={AllFreeProgramsColumns}
              isImage={true}
            />
          )}
          {dataTableInnerLoading ? (
            <div className="loading">
              <Spinner size="large" />
            </div>
          ) : null}
        </div>
      </Card>

      {/* toaster */}
      {toasttoggle && (
        <ToasterToggle
          toastmessage={toastMessage}
          toastactive={() => setToastToggle(false)}
          isErrorMessage={isError}
        />
      )}

      {deleteConfirmModal ? (
        <DeleteConfirmModal
          dismissClick={() => closeConfirmModal()}
          deleteBtnSpinner={deleteBtnSpinner}
          submitClick={() => confirmSubmit()}
        />
      ) : null}

      {closeModel ? (
        <PDFViewModel
          dismissClick={() => closePDFModal()}
          url={"abc"}
          text={"abc"}
          id={pdfID}
        />
      ) : null}
    </Page>
  );
};

export default AllFreeProgram;
