import React, { useState, useEffect } from "react";
//shopify
import {
  Card,
  DataTable,
  Page,
  Stack,
  Select,
  Pagination,
  Button,
  Spinner,
  Icon,
  TextField,
} from "@shopify/polaris";
import "../../assets/scss/helicore_common.scss";
import { defaultPageOptions, showing } from "../../appconfig/Settings";
import {
  columnsToRows,
  createMarkup,
  setPagination,
} from "../../appconfig/AppHelper";
import ToasterToggle from "../../components/Toster";
import {
  DELETE_EVENTDATA,
  GET_EVENTDATA,
  UPDATE_EVENTSTATUS,
} from "../../config/ApiConstant";
import axiosInstance from "../../config/axiosInstance";
import DeleteConfirmModal from "../../components/DeleteConfirmModel";
import DataTableEmptyState from "../../components/DataTable/DataTableEmptyState";
import SkeletonDataTable from "../../components/DataTable/SkeletonDataTable";
import { COLUMNS_KEY, EvenetsColumns } from "../../appconfig/dataTableSetting";
import { useHistory } from "react-router-dom";
import { SearchMinor } from "@shopify/polaris-icons";

const Events = () => {
  document.title = "Events | Himalayan Stallion";
  const [pageLoad, setPageLoad] = useState(false);
  const [tableRows, setTableRows] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [paginationInner, setPageinationInner] = useState(setPagination());
  const [dataTableInnerLoading, setDataTableInnerLoading] = useState(false);
  const [perPage, setPerPage] = useState(showing);
  const [toastMessage, setToastMessage] = useState();
  const [toasttoggle, setToastToggle] = useState(false);
  const [isError, setisError] = useState(false);
  const [paginationActive, setPaginationActive] = useState(false);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [deleteBtnSpinner, setdeleteBtnSpinner] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [search, setSearch] = useState("");
  const history = useHistory();

  useEffect(() => {
    getDataFromAPI();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  // Call when per page change
  useEffect(() => {
    getDataFromAPI();
  }, [perPage]);

  // Page Handle Change
  const handleChangePerpage = (value) => {
    paginationInner.page = 1;
    setPerPage(value);
  };

  // Call next page handler
  const handleChangePage = (action) => {
    action === "Next" ? paginationInner.page++ : paginationInner.page--;
    getDataFromAPI();
  };

  // Search Handler
  const handleTitleChange = (value) => {
    setSearch(value);
  };

  // Clear Search value
  const handleClearTitle = () => {
    setSearch("");
  };

  // search
  const handleSearchButton = () => {
    setSubmitLoading(true);
    paginationInner.page = 1;
    getDataFromAPI();
    setSubmitLoading(false);
  };

  //Status handleCheck
  const onHandleCheck = async (id, e) => {
    try {
      const status = e.target.checked;
      const block_type = "multiple";
      const requestData = { id: id, status, block_type };
      const response = await axiosInstance.post(
        UPDATE_EVENTSTATUS,
        requestData
      );
      if (response.data) {
        setSubmitLoading(false);
        setToastToggle(true);
        setToastMessage(response.data.message);
        setisError(false);
        getDataFromAPI();
      }
    } catch (error) {
      if (error.response) {
        setSubmitLoading(false);
        setToastToggle(true);
        setToastMessage(error.response.data.error);
        setisError(true);
      }
    }
  };

  //api call
  const getDataFromAPI = async () => {
    if (pageLoad) setDataTableInnerLoading(true);
    const { page } = paginationInner;
    const response = await axiosInstance(
      GET_EVENTDATA +
        "?page=" +
        page +
        "&per_page=" +
        perPage +
        "&search=" +
        search +
        "&content_type=event_eventsData"
    );
    if (response) {
      const eventsList = response.data.data.data;
      if (eventsList.length === 0) {
        setTableRows([]);
      } else {
        let tableData = [];
        for (let i = 0; i < eventsList.length; i++) {
          let tableRowsAppend = [];
          tableRowsAppend.push(
            <div className="img-list-wrapper">
              <img
                src={!window.cn(eventsList[i].image) ? eventsList[i].image : ""}
                alt="NO Display"
              ></img>
            </div>
          );
          tableRowsAppend.push(
            !window.cn(eventsList[i].title) ? eventsList[i].title : ""
          );
          tableRowsAppend.push(
            !window.cn(eventsList[i].sub_title) ? eventsList[i].sub_title : ""
          );
          tableRowsAppend.push(
            !window.cn(eventsList[i].description) ? (
              <div
                className="desc-wrap"
                dangerouslySetInnerHTML={createMarkup(
                  eventsList[i].description
                )}
              ></div>
            ) : (
              ""
            )
          );
          tableRowsAppend.push(
            !window.cn(eventsList[i].date) ? eventsList[i].date : ""
          );
          tableRowsAppend.push(
            !window.cn(eventsList[i].venue) ? (
              <div
                className="desc-wrap"
                dangerouslySetInnerHTML={createMarkup(eventsList[i].venue)}
              ></div>
            ) : (
              ""
            )
          );
          tableRowsAppend.push(
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id={i}
                onChange={(e) => onHandleCheck(eventsList[i].id, e)}
                checked={eventsList[i].status === 1 ? true : false}
              />
            </div>
          );
          tableRowsAppend.push(
            <Stack.Item>
              <Button primary onClick={() => editData(eventsList[i].id)}>
                Edit
              </Button>

              <Button destructive onClick={() => deleteData(eventsList[i].id)}>
                Delete
              </Button>
            </Stack.Item>
          );
          tableData.push(tableRowsAppend);
        }
        setTableRows(tableData);
        setPaginationActive(true);
      }
      setDataTableInnerLoading(false);
      setPageLoad(true);

      //Get Responce Data and Set respectively
      const { current_page, total, per_page, last_page } = response.data.data;
      const pagination = setPagination(
        current_page,
        per_page,
        last_page,
        total
      );
      setPageinationInner(pagination);
    }
  };

  //editData
  const editData = (eventsId) => {
    let path = `/updateevents`;
    history.push({ pathname: path, state: eventsId });
  };

  //DeleteData
  const deleteData = (deleteId) => {
    setDeleteId(deleteId);
    setDeleteConfirmModal(true);
  };

  //Delete Modal close
  const closeConfirmModal = () => {
    setDeleteId(null);
    setDeleteConfirmModal(false);
  };

  //Delete ConfirmSubmit
  const confirmSubmit = async () => {
    try {
      setdeleteBtnSpinner(true);
      const response = await axiosInstance.delete(
        DELETE_EVENTDATA + "/" + deleteId
      );
      if (response.data) {
        setDeleteConfirmModal(false);
        setdeleteBtnSpinner(false);
        getDataFromAPI();
        setToastToggle(true);
        setToastMessage(response.data.message);
      }
    } catch (error) {
      if (error.response) {
        setdeleteBtnSpinner(false);
        setToastToggle(true);
        setToastMessage(error.response.data.error);
      }
    }
  };

  //goto add events page
  const goToAddEvents = () => {
    history.push("/addevents");
  };

  const Paginate = () => {
    return (
      <>
        <Pagination
          previousTooltip="Previous"
          hasPrevious={paginationInner.hasPrevious}
          onPrevious={() => handleChangePage("Previous")}
          hasNext={paginationInner.hasNext}
          nextTooltip="Next"
          onNext={() => handleChangePage("Next")}
        />
      </>
    );
  };

  return (
    <Page
      title="Events"
      fullWidth
      primaryAction={{
        content: "Add Events Data",
        onAction: goToAddEvents,
      }}
    >
      <Card>
        <Card.Section subdued>
          <Stack distribution="trailing">
            <Stack.Item fill>
              <TextField
                labelHidden
                autoComplete="off"
                placeholder="Enter Title"
                value={search || ""}
                onChange={(value) => handleTitleChange(value)}
                prefix={<Icon source={SearchMinor} color="inkLighter" />}
                clearButton
                onClearButtonClick={() => handleClearTitle()}
              />
            </Stack.Item>
            <Stack.Item>
              <Button
                loading={submitLoading}
                onClick={() => handleSearchButton()}
                primary
                fullWidth
              >
                SEARCH
              </Button>
            </Stack.Item>
            <Stack.Item>
              <Select
                label="Show"
                labelInline
                onChange={handleChangePerpage}
                value={perPage}
                options={defaultPageOptions}
              ></Select>
            </Stack.Item>
          </Stack>
        </Card.Section>
        <div className="data-wrapper">
          {pageLoad ? (
            <div>
              <DataTable
                verticalAlign="middle"
                hideScrollIndicator={true}
                columnContentTypes={columnsToRows(
                  EvenetsColumns,
                  COLUMNS_KEY.TYPE
                )}
                headings={columnsToRows(EvenetsColumns, COLUMNS_KEY.NAME)}
                rows={tableRows}
                footerContent={
                  tableRows.length > 0 ? (
                    <span>{paginationInner.showing}</span>
                  ) : null
                }
              />
              {tableRows.length > 0 ? (
                <div className="paginate">
                  <Stack distribution="center">
                    {paginationActive ? <Paginate /> : null}
                  </Stack>
                </div>
              ) : (
                <DataTableEmptyState />
              )}
            </div>
          ) : (
            <SkeletonDataTable
              defaultLength={8}
              columns={EvenetsColumns}
              isImage={true}
            />
          )}
          {dataTableInnerLoading ? (
            <div className="loading">
              <Spinner size="large" />
            </div>
          ) : null}
        </div>
      </Card>

      {/* toaster */}
      {toasttoggle && (
        <ToasterToggle
          toastmessage={toastMessage}
          toastactive={() => setToastToggle(false)}
          isErrorMessage={isError}
        />
      )}

      {deleteConfirmModal ? (
        <DeleteConfirmModal
          dismissClick={() => closeConfirmModal()}
          deleteBtnSpinner={deleteBtnSpinner}
          submitClick={() => confirmSubmit()}
        />
      ) : null}
    </Page>
  );
};

export default Events;
