import React, { useState, useEffect } from "react";
//shopify
import {
  Card,
  TextField,
  TextStyle,
  Page,
  Stack,
  FormLayout,
} from "@shopify/polaris";
import "../../assets/scss/helicore_common.scss";
import CategoryImage from "../../components/CategoryImage";
import ToasterToggle from "../../components/Toster";
import { ADD_COMMONDATA } from "../../config/ApiConstant";
import axiosInstance from "../../config/axiosInstance";
import CKEditor from "react-ckeditor-component";
import { useHistory } from "react-router-dom";

const AddConnect = () => {
  const [connectData, setConnectData] = useState({});
  const [validation, setValidation] = useState({
    name: false,
    title: false,
    descriptionData: false,
    description: false,
    sub_title: false,
    image: false,
  });

  document.title = "Founder | Himalayan Stallion";
  const [submitLoading, setSubmitLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState();
  const [toasttoggle, setToastToggle] = useState(false);
  const [isError, setisError] = useState(false);
  const [editId] = useState(null);
  const [descriptionData, setDescriptionData] = useState();
  const [sub_titleData, setSub_titleData] = useState();
  const [description, setDescription] = useState();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  //images variables
  let getImagesRefFromAddImage = null;

  //gets media images
  const getImagesFromAddImage = (ref) => {
    getImagesRefFromAddImage = ref;
  };

  // handleChange
  const handleChange = (name, value) => {
    let validations = true;
    if (value !== "" && value !== undefined) {
      validations = false;
    }
    setValidation((prev) => ({ ...prev, [name]: validations }));
    setConnectData({ ...connectData, [name]: value });
  };

  //onChange description
  const onChangeDesc = (name, event) => {
    var newContent = event.editor.getData();

    if (name === "description") {
      setDescriptionData(newContent);
    }

    let validations = true;
    if ([name] !== "" && [name] !== undefined) {
      validations = false;
    }

    setValidation((prev) => ({ ...prev, descriptionData: validations }));
  };

  //onChange Name description
  const onChangeNameDesc = (name, event) => {
    var newContent = event.editor.getData();

    if (name === "name_description") {
      setDescription(newContent);
    }

    let validations = true;
    if ([name] !== "" && [name] !== undefined) {
      validations = false;
    }

    setValidation((prev) => ({ ...prev, description: validations }));
  };

  //onChange Name description
  const onChangeSubTitleDesc = (name, event) => {
    var newContent = event.editor.getData();

    if (name === "sub_title") {
      setSub_titleData(newContent);
    }

    let validations = true;
    if ([name] !== "" && [name] !== undefined) {
      validations = false;
    }

    setValidation((prev) => ({ ...prev, sub_titleData: validations }));
  };

  //checks validation
  const validate = () => {
    let validate = true;
    const image = getImagesRefFromAddImage?.getImages();

    //Validation
    if (window.cn(connectData.name)) {
      setValidation((prev) => ({ ...prev, name: true }));
      validate = false;
    }
    if (window.cn(connectData.title)) {
      setValidation((prev) => ({ ...prev, title: true }));
      validate = false;
    }
    if (window.cn(descriptionData)) {
      setValidation((prev) => ({ ...prev, descriptionData: true }));
      validate = false;
    }
    if (window.cn(sub_titleData)) {
      setValidation((prev) => ({ ...prev, sub_titleData: true }));
      validate = false;
    }
    if (window.cn(description)) {
      setValidation((prev) => ({ ...prev, description: true }));
      validate = false;
    }
    if (editId == null || editId === "" || editId === undefined) {
      if (image.length <= 0) {
        setValidation((prev) => ({ ...prev, image: true }));
        validate = false;
      }
    }
    return validate;
  };

  //Add data
  const onSubmit = async () => {
    try {
      const isValidate = validate();
      if (isValidate) {
        setSubmitLoading(true);

        const formData = new FormData();

        const images = getImagesRefFromAddImage?.getImages();

        const { title, name } = connectData;

        for (let i = 0; i < images.length; i++) {
          formData.append("images[]", images[i]);
        }
        //set formData
        formData.append("name", name);
        formData.append("title", title);
        formData.append("description", descriptionData);
        formData.append("name_description", description);
        formData.append("sub_title", sub_titleData);
        formData.append("content_type", "common_connectData");

        // Api call
        const response = await axiosInstance.post(ADD_COMMONDATA, formData);
        if (response.data) {
          setSubmitLoading(false);
          setConnectData({});
          setDescriptionData("");
          setSub_titleData("");
          setDescription("");
          setTimeout(() => {
            history.push("/connect");
          }, 2000);
          setToastToggle(true);
          setToastMessage(response.data.message);
          setisError(false);
          getImagesRefFromAddImage?.remove();
        }
      }
    } catch (error) {
      if (error.response) {
        setSubmitLoading(false);
        setToastToggle(true);
        setToastMessage(error.response.data.error);
        setisError(true);
      }
    }
  };

  //CllearData
  const clearAll = () => {
    setConnectData({});
    setDescriptionData("");
    setSub_titleData("");
    setDescription("");
    getImagesRefFromAddImage?.remove();
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <Page
      title="Connect"
      fullWidth
      breadcrumbs={[
        {
          content: "Reports",
          onAction: handleBack,
        },
      ]}
    >
      <Card
        sectioned
        secondaryFooterActions={[
          { content: "Cancel", onAction: () => clearAll() },
        ]}
        primaryFooterAction={{
          content: <>Add</>,
          loading: submitLoading,
          onAction: () => onSubmit(),
        }}
      >
        <FormLayout>
          <FormLayout.Group>
            <TextField
              label="Name"
              value={connectData.name}
              onChange={(value) => handleChange("name", value)}
              autoComplete="off"
              error={validation.name ? "Name can't be blank." : false}
            />

            <TextField
              label="Title"
              value={connectData.title}
              onChange={(value) => handleChange("title", value)}
              autoComplete="off"
              error={validation.title ? "Title can't be blank." : false}
            />
          </FormLayout.Group>

          <Stack.Item>
            <label className="Polaris-Label__Text Polaris-Label__RequiredIndicator label-font">
              Profile Picture
            </label>
            <CategoryImage getImagesFromAddImage={getImagesFromAddImage} />
            {validation.image ? (
              <div className="Polaris-InlineError">
                <div className="Polaris-InlineError__Icon">
                  <span className="Polaris-Icon">
                    <span className="Polaris-VisuallyHidden"></span>
                    <svg
                      viewBox="0 0 20 20"
                      className="Polaris-Icon__Svg"
                      focusable="false"
                      aria-hidden="true"
                    >
                      <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zM9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"></path>
                    </svg>
                  </span>
                </div>
                <span>image is required</span>
              </div>
            ) : null}
            <TextStyle variation="warning">
              Note:Image size (1440px*760px).
            </TextStyle>
          </Stack.Item>

          <Stack.Item>
            <label className="Polaris-Label__Text label-font">
              Description 1
            </label>
            <CKEditor
              activeClass="p10"
              content={descriptionData || ""}
              events={{
                change: (content) => onChangeDesc("description", content),
              }}
            />

            {validation.descriptionData ? (
              <div className="Polaris-InlineError">
                <div className="Polaris-InlineError__Icon">
                  <span className="Polaris-Icon">
                    <span className="Polaris-VisuallyHidden"></span>
                    <svg
                      viewBox="0 0 20 20"
                      className="Polaris-Icon__Svg"
                      focusable="false"
                      aria-hidden="true"
                    >
                      <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zM9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"></path>
                    </svg>
                  </span>
                </div>
                <span>Description can't be blank</span>
              </div>
            ) : null}
          </Stack.Item>

          <Stack.Item>
            <label className="Polaris-Label__Text label-font">
              Description 2
            </label>
            <CKEditor
              activeClass="p10"
              content={description || ""}
              events={{
                change: (content) =>
                  onChangeNameDesc("name_description", content),
              }}
            />

            {validation.description ? (
              <div className="Polaris-InlineError">
                <div className="Polaris-InlineError__Icon">
                  <span className="Polaris-Icon">
                    <span className="Polaris-VisuallyHidden"></span>
                    <svg
                      viewBox="0 0 20 20"
                      className="Polaris-Icon__Svg"
                      focusable="false"
                      aria-hidden="true"
                    >
                      <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zM9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"></path>
                    </svg>
                  </span>
                </div>
                <span>Description can't be blank</span>
              </div>
            ) : null}
          </Stack.Item>

          <Stack.Item>
            <label className="Polaris-Label__Text label-font">
              Description 3
            </label>
            <CKEditor
              activeClass="p10"
              content={sub_titleData || ""}
              events={{
                change: (content) => onChangeSubTitleDesc("sub_title", content),
              }}
            />

            {validation.sub_titleData ? (
              <div className="Polaris-InlineError">
                <div className="Polaris-InlineError__Icon">
                  <span className="Polaris-Icon">
                    <span className="Polaris-VisuallyHidden"></span>
                    <svg
                      viewBox="0 0 20 20"
                      className="Polaris-Icon__Svg"
                      focusable="false"
                      aria-hidden="true"
                    >
                      <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zM9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"></path>
                    </svg>
                  </span>
                </div>
                <span>Description can't be blank</span>
              </div>
            ) : null}
          </Stack.Item>
        </FormLayout>
      </Card>

      {/* toaster */}
      {toasttoggle && (
        <ToasterToggle
          toastmessage={toastMessage}
          toastactive={() => setToastToggle(false)}
          isErrorMessage={isError}
        />
      )}
    </Page>
  );
};

export default AddConnect;
