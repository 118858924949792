import moment from "moment";
import { _pagination } from "./Settings";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export const DEFAULT_TIMEZONE = "America/New_York";

// Convert Utc to local
export const convertUTCtoTimeZone = function (date, timeZone = getTimeZone()) {
  return date
    ? moment.utc(date).tz(timeZone).format("DD MMM YYYY, hh:mm A")
    : null;
};

// Format date and time
export const formatDateTime = function (date) {
  return date ? moment.utc(date).format("DD MMM YYYY, hh:mm A") : null;
};

// Format date
export const formatDate = function (date) {
  return date ? moment.utc(date).format("DD MMM YYYY") : null;
};

// get timezone
export const getTimeZone = function () {
  let timezone;
  var pageUrl = window.location.href;
  if (pageUrl.indexOf("/admin/") > 0) {
    timezone = DEFAULT_TIMEZONE;
  } else {
    if (
      localStorage.getItem("timezone") &&
      localStorage.getItem("timezone") !== null
    ) {
      timezone = localStorage.getItem("timezone");
    }
  }
  return timezone;
};

// Set pagination
export const setPagination = function (
  page = _pagination.page,
  perpage = _pagination.perpage,
  totalpages = 0,
  total = 0
) {
  let pagination = _pagination;
  const from = page * perpage - perpage + 1;
  const to = totalpages === page ? total : perpage * page;
  const hasPrevious = page > 1;
  const hasNext = totalpages > page;
  const showing =
    total > 0 ? `Showing ${from} to ${to} of ${total} entries` : null;
  pagination = {
    ...pagination,
    hasNext: hasNext,
    hasPrevious: hasPrevious,
    page: page,
    perpage: perpage,
    showing: showing,
  };
  return pagination;
};

//Get Columns To Row
export function columnsToRows(objColumns, columnName) {
  return objColumns.map(function (column) {
    return column[columnName];
  });
}

//Date Convert To YYYY/MM/DD
export function convertDateFrom(date) {
  date = new Date(date); //Date pass
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  return (
    year +
    "-" +
    (month <= 9 ? "0" + month : month) +
    "-" +
    (day <= 9 ? "0" + day : day)
  );
}

export const createMarkup = (converdata) => {
  return { __html: converdata };
};

// Ser Counter status in live course
export const counterStatus = (status) => {
  let statusData;

  if (status === 1) {
    statusData = "Activate";
  } else {
    statusData = "Deactivate";
  }
  return statusData;
};

// Set File type
const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

// Set export file extension
const fileExtension = ".xlsx";

// Export file report
export const exportToCSV = (apiData, fileName) => {
  const ws = XLSX.utils.json_to_sheet(apiData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

// convert local time to UTC time
export const convertLocalToUTC = (date) => {
  return moment(date, "YYYY-MM-DD hh:mm:ss")
    .utc()
    .format("YYYY-MM-DD HH:mm:ss");
};
