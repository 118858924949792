import React, { useState, useEffect } from "react";
//shopify
import {
  Card,
  DataTable,
  Page,
  Stack,
  Select,
  Pagination,
  Button,
  Spinner,
  Icon,
  TextField,
  Modal,
  FormLayout,
} from "@shopify/polaris";
import "../../../assets/scss/helicore_common.scss";
import { defaultPageOptions, showing } from "../../../appconfig/Settings";
import {
  columnsToRows,
  createMarkup,
  setPagination,
} from "../../../appconfig/AppHelper";
import ToasterToggle from "../../../components/Toster";
import {
  ADD_HOMEDATA,
  DELETE_HOMEDATA,
  GET_HOMEDATA,
  UPDATE_STATUS,
} from "../../../config/ApiConstant";
import axiosInstance from "../../../config/axiosInstance";
import DeleteConfirmModal from "../../../components/DeleteConfirmModel";
import DataTableEmptyState from "../../../components/DataTable/DataTableEmptyState";
import SkeletonDataTable from "../../../components/DataTable/SkeletonDataTable";
import {
  COLUMNS_KEY,
  ProgramsColumns,
} from "../../../appconfig/dataTableSetting";
import { useHistory } from "react-router-dom";
import { SearchMinor } from "@shopify/polaris-icons";
import CKEditor from "react-ckeditor-component";

const Program = () => {
  document.title = "Program | Himalayan Stallion";
  const [programData, setProgramData] = useState({});
  const [pageLoad, setPageLoad] = useState(false);
  const [tableRows, setTableRows] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [paginationInner, setPageinationInner] = useState(setPagination());
  const [dataTableInnerLoading, setDataTableInnerLoading] = useState(false);
  const [perPage, setPerPage] = useState(showing);
  const [toastMessage, setToastMessage] = useState();
  const [toasttoggle, setToastToggle] = useState(false);
  const [isError, setisError] = useState(false);
  const [paginationActive, setPaginationActive] = useState(false);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [deleteBtnSpinner, setdeleteBtnSpinner] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [search, setSearch] = useState("");
  const [modal, setModal] = useState(false);
  const [editId, setEditId] = useState(null);
  const [descriptionData, setDescriptionData] = useState();
  const [validation, setValidation] = useState({
    title: false,
    button_title: false,
    descriptionData: false,
  });

  useEffect(() => {
    getDataFromAPI();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  // Call when per page change
  useEffect(() => {
    getDataFromAPI();
  }, [perPage]);

  // Page Handle Change
  const handleChangePerpage = (value) => {
    paginationInner.page = 1;
    setPerPage(value);
  };

  // Call next page handler
  const handleChangePage = (action) => {
    action === "Next" ? paginationInner.page++ : paginationInner.page--;
    getDataFromAPI();
  };

  // Search Handler
  const handleTitleChange = (value) => {
    setSearch(value);
  };

  // Clear Search value
  const handleClearTitle = () => {
    setSearch("");
  };

  // search
  const handleSearchButton = () => {
    setSubmitLoading(true);
    paginationInner.page = 1;
    getDataFromAPI();
    setSubmitLoading(false);
  };

  // handleChange
  const handleChange = (name, value) => {
    let validations = true;
    if (value !== "" && value !== undefined) {
      validations = false;
    }
    setValidation((prev) => ({ ...prev, [name]: validations }));
    setProgramData({ ...programData, [name]: value });
  };

  //onChange description
  const onChangeDesc = (name, event) => {
    var newContent = event.editor.getData();

    if (name === "description") {
      setDescriptionData(newContent);
    }

    let validations = true;
    if ([name] !== "" && [name] !== undefined) {
      validations = false;
    }

    setValidation((prev) => ({ ...prev, descriptionData: validations }));
  };

  //checks validation
  const validate = () => {
    let validate = true;

    //Validation
    if (window.cn(programData.title)) {
      setValidation((prev) => ({ ...prev, title: true }));
      validate = false;
    }
    if (window.cn(programData.button_title)) {
      setValidation((prev) => ({ ...prev, button_title: true }));
      validate = false;
    }
    if (window.cn(descriptionData)) {
      setValidation((prev) => ({ ...prev, descriptionData: true }));
      validate = false;
    }
    return validate;
  };

  //Status handleCheck
  const onHandleCheck = async (id, e) => {
    try {
      const status = e.target.checked;
      const block_type = "single";
      const content_type = "home_programData";
      const requestData = { id: id, status, block_type, content_type };
      const response = await axiosInstance.post(UPDATE_STATUS, requestData);
      if (response.data) {
        setSubmitLoading(false);
        setToastToggle(true);
        setToastMessage(response.data.message);
        setisError(false);
        getDataFromAPI();
      }
    } catch (error) {
      if (error.response) {
        setSubmitLoading(false);
        setToastToggle(true);
        setToastMessage(error.response.data.error);
        setisError(true);
      }
    }
  };

  //api call
  const getDataFromAPI = async () => {
    if (pageLoad) setDataTableInnerLoading(true);
    const { page } = paginationInner;
    const response = await axiosInstance(
      GET_HOMEDATA +
        "?page=" +
        page +
        "&per_page=" +
        perPage +
        "&search=" +
        search +
        "&content_type=home_programData"
    );
    if (response) {
      const programList = response.data.data.data;
      if (programList.length === 0) {
        setTableRows([]);
      } else {
        let tableData = [];
        for (let i = 0; i < programList.length; i++) {
          let tableRowsAppend = [];
          tableRowsAppend.push(
            !window.cn(programList[i].title) ? programList[i].title : ""
          );
          tableRowsAppend.push(
            !window.cn(programList[i].description) ? (
              <div
                className="desc-wrap"
                dangerouslySetInnerHTML={createMarkup(
                  programList[i].description
                )}
              ></div>
            ) : (
              ""
            )
          );
          tableRowsAppend.push(
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id={i}
                onChange={(e) => onHandleCheck(programList[i].id, e)}
                checked={programList[i].status === 1 ? true : false}
              />
            </div>
          );
          tableRowsAppend.push(
            <Stack.Item>
              <Button primary onClick={() => editData(programList[i])}>
                Edit
              </Button>

              <Button destructive onClick={() => deleteData(programList[i].id)}>
                Delete
              </Button>
            </Stack.Item>
          );
          tableData.push(tableRowsAppend);
        }
        setTableRows(tableData);
        setPaginationActive(true);
      }
      setDataTableInnerLoading(false);
      setPageLoad(true);

      //Get Responce Data and Set respectively
      const { current_page, total, per_page, last_page } = response.data.data;
      const pagination = setPagination(
        current_page,
        per_page,
        last_page,
        total
      );
      setPageinationInner(pagination);
    }
  };

  //Add data
  const onSubmit = async () => {
    try {
      const isValidate = validate();
      if (isValidate) {
        setSubmitLoading(true);

        const formData = new FormData();

        const { title, button_title } = programData;

        //set formData
        formData.append("title", title);
        formData.append("button_title", button_title);
        formData.append("description", descriptionData);
        formData.append("content_type", "home_programData");
        if (editId) {
          formData.append("id", editId);
        }
        // Api call
        const response = await axiosInstance.post(ADD_HOMEDATA, formData);
        if (response.data) {
          setSubmitLoading(false);
          getDataFromAPI();
          setProgramData({});
          setDescriptionData("");
          setModal(false);
          setToastToggle(true);
          setToastMessage(response.data.message);
          setisError(false);
        }
      }
    } catch (error) {
      if (error.response) {
        setSubmitLoading(false);
        setToastToggle(true);
        setToastMessage(error.response.data.error);
        setisError(true);
      }
    }
  };

  //editData
  const editData = (DataList) => {
    setModal(true);
    const { id, title, button_title, description } = DataList;
    setEditId(id);
    setProgramData({
      ...programData,
      title: title,
      button_title: button_title,
    });
    setDescriptionData(description);
  };

  //DeleteData
  const deleteData = (deleteId) => {
    setDeleteId(deleteId);
    setDeleteConfirmModal(true);
  };

  //Delete Modal close
  const closeConfirmModal = () => {
    setDeleteId(null);
    setDeleteConfirmModal(false);
  };

  //Delete ConfirmSubmit
  const confirmSubmit = async () => {
    try {
      setdeleteBtnSpinner(true);
      const response = await axiosInstance.delete(
        DELETE_HOMEDATA + "/" + deleteId
      );
      if (response.data) {
        setDeleteConfirmModal(false);
        setdeleteBtnSpinner(false);
        getDataFromAPI();
        setToastToggle(true);
        setToastMessage(response.data.message);
      }
    } catch (error) {
      if (error.response) {
        setdeleteBtnSpinner(false);
        setToastToggle(true);
        setToastMessage(error.response.data.error);
      }
    }
  };

  //modal open
  const goToAddPrograms = () => {
    setModal(true);
  };

  // Model Close
  const closeModel = () => {
    setModal(false);
    setEditId(null);
    setProgramData({});
    setDescriptionData("");
  };

  const Paginate = () => {
    return (
      <>
        <Pagination
          previousTooltip="Previous"
          hasPrevious={paginationInner.hasPrevious}
          onPrevious={() => handleChangePage("Previous")}
          hasNext={paginationInner.hasNext}
          nextTooltip="Next"
          onNext={() => handleChangePage("Next")}
        />
      </>
    );
  };

  //CllearData
  const clearAll = () => {
    setProgramData({});
    setDescriptionData("");
  };

  return (
    <Page
      title="Programs"
      fullWidth
      primaryAction={{
        content: "Add Programs",
        onAction: goToAddPrograms,
      }}
    >
      <Card>
        <Card.Section subdued>
          <Stack distribution="trailing">
            <Stack.Item fill>
              <TextField
                labelHidden
                autoComplete="off"
                placeholder="Enter Title"
                value={search || ""}
                onChange={(value) => handleTitleChange(value)}
                prefix={<Icon source={SearchMinor} color="inkLighter" />}
                clearButton
                onClearButtonClick={() => handleClearTitle()}
              />
            </Stack.Item>
            <Stack.Item>
              <Button
                // loading={submitLoading}
                onClick={() => handleSearchButton()}
                primary
                fullWidth
              >
                SEARCH
              </Button>
            </Stack.Item>
            <Stack.Item>
              <Select
                label="Show"
                labelInline
                onChange={handleChangePerpage}
                value={perPage}
                options={defaultPageOptions}
              ></Select>
            </Stack.Item>
          </Stack>
        </Card.Section>
        <div className="data-wrapper">
          {pageLoad ? (
            <div>
              <DataTable
                verticalAlign="middle"
                hideScrollIndicator={true}
                columnContentTypes={columnsToRows(
                  ProgramsColumns,
                  COLUMNS_KEY.TYPE
                )}
                headings={columnsToRows(ProgramsColumns, COLUMNS_KEY.NAME)}
                rows={tableRows}
                footerContent={
                  tableRows.length > 0 ? (
                    <span>{paginationInner.showing}</span>
                  ) : null
                }
              />
              {tableRows.length > 0 ? (
                <div className="paginate">
                  <Stack distribution="center">
                    {paginationActive ? <Paginate /> : null}
                  </Stack>
                </div>
              ) : (
                <DataTableEmptyState />
              )}
            </div>
          ) : (
            <SkeletonDataTable
              defaultLength={4}
              columns={ProgramsColumns}
              isImage={false}
            />
          )}
          {dataTableInnerLoading ? (
            <div className="loading">
              <Spinner size="large" />
            </div>
          ) : null}
        </div>
      </Card>

      <Modal
        open={modal}
        onClose={() => closeModel()}
        title={!window.cn(editId) ? "Edit Program" : "Add New Program"}
      >
        <Modal.Section>
          <FormLayout>
            <TextField
              label="Title"
              value={programData.title}
              onChange={(value) => handleChange("title", value)}
              autoComplete="off"
              error={validation.title ? "Title can't be blank." : false}
            />

            <Stack.Item>
              <label className="Polaris-Label__Text label-font">
                Description
              </label>
              <CKEditor
                activeClass="p10"
                content={descriptionData || ""}
                events={{
                  change: (content) => onChangeDesc("description", content),
                }}
              />

              {validation.descriptionData ? (
                <div className="Polaris-InlineError">
                  <div className="Polaris-InlineError__Icon">
                    <span className="Polaris-Icon">
                      <span className="Polaris-VisuallyHidden"></span>
                      <svg
                        viewBox="0 0 20 20"
                        className="Polaris-Icon__Svg"
                        focusable="false"
                        aria-hidden="true"
                      >
                        <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zM9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"></path>
                      </svg>
                    </span>
                  </div>
                  <span>Description can't be blank</span>
                </div>
              ) : null}
            </Stack.Item>

            <TextField
              label="Button Title"
              value={programData.button_title}
              onChange={(value) => handleChange("button_title", value)}
              autoComplete="off"
              error={
                validation.button_title ? "Button Title can't be blank." : false
              }
            />
            <Stack distribution="trailing">
              <Button onClick={() => clearAll()}>Clear</Button>
              {!window.cn(editId) ? (
                <Button
                  primary
                  loading={submitLoading}
                  onClick={() => onSubmit()}
                >
                  Edit
                </Button>
              ) : (
                <Button
                  primary
                  loading={submitLoading}
                  onClick={() => onSubmit()}
                >
                  Add
                </Button>
              )}
            </Stack>
          </FormLayout>
        </Modal.Section>
      </Modal>

      {/* toaster */}
      {toasttoggle && (
        <ToasterToggle
          toastmessage={toastMessage}
          toastactive={() => setToastToggle(false)}
          isErrorMessage={isError}
        />
      )}

      {deleteConfirmModal ? (
        <DeleteConfirmModal
          dismissClick={() => closeConfirmModal()}
          deleteBtnSpinner={deleteBtnSpinner}
          submitClick={() => confirmSubmit()}
        />
      ) : null}
    </Page>
  );
};

export default Program;
