import React from "react";
import {Route, Switch} from "react-router-dom";

import Login from "../pages/login/Login";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    return(
        <Switch>
            <Route
                exact
                component={Login}
                path="/"
            />     
        </Switch>
    )
}
