import React, { useState, useEffect } from "react";
//shopify
import {
  Card,
  TextField,
  TextStyle,
  Page,
  Stack,
  Thumbnail,
  FormLayout,
  Select,
} from "@shopify/polaris";
import "../../../assets/scss/helicore_common.scss";
import CategoryImage from "../../../components/CategoryImage";
import ToasterToggle from "../../../components/Toster";
import {
  ADD_COMMONDATA,
  GET_COMMONDATA_BYID,
} from "../../../config/ApiConstant";
import axiosInstance from "../../../config/axiosInstance";
import CKEditor from "react-ckeditor-component";
import { useHistory } from "react-router-dom";
import { Selectoptions } from "../../../appconfig/Settings";
import UpdateImages from "../../../components/UpdateImages";

const UpdateAbout = (props) => {
  const [aboutData, setAboutData] = useState({});
  const [validation, setValidation] = useState({
    name: false,
    title: false,
    descriptionData: false,
    image: false,
    bg_img: false,
  });

  document.title = "Founder | Himalayan Stallion";
  const [submitLoading, setSubmitLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState();
  const [toasttoggle, setToastToggle] = useState(false);
  const [isError, setisError] = useState(false);
  const [editId, setEditId] = useState(null);
  const [descriptionData, setDescriptionData] = useState();
  const [updatedImage, setUpdatedImage] = useState();
  const [aboutImages, setAboutImages] = useState([]);
  const history = useHistory();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getAboutData();
  }, []);

  //images variables
  let getImagesRefFromAddImage = null;

  //gets media images
  const getImagesFromAddImage = (ref) => {
    getImagesRefFromAddImage = ref;
  };

  //images variables
  let getImagesRefFromUpdateImage = null;

  //gets media images
  const getImagesFromUpdateImage = (ref) => {
    getImagesRefFromUpdateImage = ref;
  };

  // handleChange
  const handleChange = (name, value) => {
    let validations = true;
    if (value !== "" && value !== undefined) {
      validations = false;
    }
    setValidation((prev) => ({ ...prev, [name]: validations }));
    setAboutData({ ...aboutData, [name]: value });
  };

  //onChange description
  const onChangeDesc = (name, event) => {
    var newContent = event.editor.getData();

    if (name === "description") {
      setDescriptionData(newContent);
    }

    let validations = true;
    if ([name] !== "" && [name] !== undefined) {
      validations = false;
    }

    setValidation((prev) => ({ ...prev, descriptionData: validations }));
  };

  //checks validation
  const validate = () => {
    let validate = true;
    const bg_image = getImagesRefFromAddImage?.getImages();
    const image = getImagesRefFromUpdateImage?.getImages();

    //Validation
    if (window.cn(aboutData.name)) {
      setValidation((prev) => ({ ...prev, name: true }));
      validate = false;
    }
    if (window.cn(aboutData.title)) {
      setValidation((prev) => ({ ...prev, title: true }));
      validate = false;
    }
    if (window.cn(aboutData.button_title)) {
      setValidation((prev) => ({ ...prev, button_title: true }));
      validate = false;
    }
    if (window.cn(aboutData.button_link)) {
      setValidation((prev) => ({ ...prev, button_link: true }));
      validate = false;
    }
    if (window.cn(descriptionData)) {
      setValidation((prev) => ({ ...prev, descriptionData: true }));
      validate = false;
    }

    if (editId == null || editId === "" || editId === undefined) {
      if (bg_image.length <= 0) {
        setValidation((prev) => ({ ...prev, bg_image: true }));
        validate = false;
      }
    }
    if (editId == null || editId === "" || editId === undefined) {
      if (image.length <= 0) {
        setValidation((prev) => ({ ...prev, image: true }));
        validate = false;
      }
    }
    return validate;
  };

  //get events data by id
  const getAboutData = async () => {
    const aboutId = props.location.state;
    setEditId(aboutId);

    // api call by id
    const response = await axiosInstance(GET_COMMONDATA_BYID + "/" + aboutId);
    if (response) {
      const DataList = response.data.data;
      const {
        name,
        title,
        button_link,
        images,
        button_title,
        description,
        bg_img,
      } = DataList;

      setAboutData({
        ...aboutData,
        name: name,
        title: title,
        button_title: button_title,
        button_link: button_link,
      });
      setUpdatedImage(bg_img);
      setAboutImages(JSON.parse(images));
      setDescriptionData(description);
    }
  };

  //Add data
  const onSubmit = async () => {
    try {
      const isValidate = validate();
      // check validation
      if (isValidate) {
        setSubmitLoading(true);

        const formData = new FormData();

        const bg_image = getImagesRefFromAddImage?.getImages();
        const images = getImagesRefFromUpdateImage?.getImages();

        const { title, name, button_title, button_link } = aboutData;

        for (let i = 0; i < images.length; i++) {
          formData.append("images[]", images[i]);
        }

        //set formData
        formData.append("name", name);
        if (bg_image.length > 0) formData.append("bg_img", bg_image[0]);
        formData.append("title", title);
        formData.append("button_title", button_title);
        formData.append("button_link", button_link);
        formData.append("description", descriptionData);
        formData.append("id", editId);
        formData.append("content_type", "common_aboutData");

        // Api call
        const response = await axiosInstance.post(ADD_COMMONDATA, formData);
        if (response.data) {
          setSubmitLoading(false);
          setAboutData({});
          setDescriptionData("");
          setTimeout(() => {
            history.push("/home/about");
          }, 2000);
          setToastToggle(true);
          setToastMessage(response.data.message);
          setisError(false);
          getImagesRefFromAddImage?.remove();
        }
      }
    } catch (error) {
      if (error.response) {
        setSubmitLoading(false);
        setToastToggle(true);
        setToastMessage(error.response.data.error);
        setisError(true);
      }
    }
  };

  //CllearData
  const clearAll = () => {
    setAboutData({});
    setDescriptionData("");
    getImagesRefFromAddImage?.remove();
  };

  // Go back page handler
  const handleBack = () => {
    history.goBack();
  };

  return (
    <Page
      title="About - Home"
      fullWidth
      breadcrumbs={[
        {
          content: "Reports",
          onAction: handleBack,
        },
      ]}
    >
      <Card
        sectioned
        secondaryFooterActions={[
          { content: "Cancel", onAction: () => clearAll() },
        ]}
        primaryFooterAction={{
          content: <>Update</>,
          loading: submitLoading,
          onAction: () => onSubmit(),
        }}
      >
        <FormLayout>
          <FormLayout.Group>
            <TextField
              label="Name"
              value={aboutData.name}
              onChange={(value) => handleChange("name", value)}
              autoComplete="off"
              error={validation.name ? "Name can't be blank." : false}
            />

            <TextField
              label="Title"
              value={aboutData.title}
              onChange={(value) => handleChange("title", value)}
              autoComplete="off"
              error={validation.title ? "Title can't be blank." : false}
            />
          </FormLayout.Group>

          {updatedImage ? (
            <>
              <label className="Polaris-Label__Text label-font">
                Old Image
              </label>
              <a href={updatedImage} target="_blank" rel="noreferrer">
                <Thumbnail source={updatedImage} size="large" alt="NO Image" />
              </a>
            </>
          ) : (
            ""
          )}
          <Stack.Item>
            <label className="Polaris-Label__Text Polaris-Label__RequiredIndicator label-font">
              Background Image
            </label>
            <CategoryImage getImagesFromAddImage={getImagesFromAddImage} />
            {validation.image ? (
              <div className="Polaris-InlineError">
                <div className="Polaris-InlineError__Icon">
                  <span className="Polaris-Icon">
                    <span className="Polaris-VisuallyHidden"></span>
                    <svg
                      viewBox="0 0 20 20"
                      className="Polaris-Icon__Svg"
                      focusable="false"
                      aria-hidden="true"
                    >
                      <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zM9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"></path>
                    </svg>
                  </span>
                </div>
                <span>image is required</span>
              </div>
            ) : null}
            <TextStyle variation="warning">
              Note:Image size (1440px*760px).
            </TextStyle>
          </Stack.Item>

          <Stack.Item>
            <label className="Polaris-Label__Text Polaris-Label__RequiredIndicator label-font">
              Images
            </label>
            <UpdateImages
              getImagesFromUpdateImage={getImagesFromUpdateImage}
              getImageArray={aboutImages}
              getUpdatedId={editId}
              getContentType={"Common"}
            />

            {validation.image ? (
              <div className="Polaris-InlineError">
                <div className="Polaris-InlineError__Icon">
                  <span className="Polaris-Icon">
                    <span className="Polaris-VisuallyHidden"></span>
                    <svg
                      viewBox="0 0 20 20"
                      className="Polaris-Icon__Svg"
                      focusable="false"
                      aria-hidden="true"
                    >
                      <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zM9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"></path>
                    </svg>
                  </span>
                </div>
                <span>image is required</span>
              </div>
            ) : null}
            <TextStyle variation="warning">
              Note:Image size (1440px*760px).
            </TextStyle>
          </Stack.Item>

          <Stack.Item>
            <label className="Polaris-Label__Text label-font">
              Description
            </label>
            <CKEditor
              activeClass="p10"
              content={descriptionData || ""}
              events={{
                change: (content) => onChangeDesc("description", content),
              }}
            />

            {validation.descriptionData ? (
              <div className="Polaris-InlineError">
                <div className="Polaris-InlineError__Icon">
                  <span className="Polaris-Icon">
                    <span className="Polaris-VisuallyHidden"></span>
                    <svg
                      viewBox="0 0 20 20"
                      className="Polaris-Icon__Svg"
                      focusable="false"
                      aria-hidden="true"
                    >
                      <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zM9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"></path>
                    </svg>
                  </span>
                </div>
                <span>Description can't be blank</span>
              </div>
            ) : null}
          </Stack.Item>

          <FormLayout.Group>
            <TextField
              label="Button"
              value={aboutData.button_title}
              onChange={(value) => handleChange("button_title", value)}
              autoComplete="off"
              error={
                validation.button_title ? "Button Title can't be blank." : false
              }
            />

            <Stack.Item>
              <label
                className="Polaris-Label__Text label-font"
                style={{ marginBottom: "0.25rem" }}
              >
                Button Link
              </label>
              <Select
                placeholder="Select Link"
                onChange={(value) => handleChange("button_link", value)}
                value={aboutData.button_link}
                options={Selectoptions}
              ></Select>
              {validation.button_link ? (
                <div className="Polaris-InlineError">
                  <div className="Polaris-InlineError__Icon">
                    <span className="Polaris-Icon">
                      <span className="Polaris-VisuallyHidden"></span>
                      <svg
                        viewBox="0 0 20 20"
                        className="Polaris-Icon__Svg"
                        focusable="false"
                        aria-hidden="true"
                      >
                        <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zM9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"></path>
                      </svg>
                    </span>
                  </div>
                  <span>Select Link</span>
                </div>
              ) : null}
            </Stack.Item>
          </FormLayout.Group>
        </FormLayout>
      </Card>

      {/* toaster */}
      {toasttoggle && (
        <ToasterToggle
          toastmessage={toastMessage}
          toastactive={() => setToastToggle(false)}
          isErrorMessage={isError}
        />
      )}
    </Page>
  );
};

export default UpdateAbout;
