import React, { useState, useEffect } from "react";
//shopify
import {
  Card,
  DataTable,
  Page,
  Stack,
  Select,
  Pagination,
  Spinner,
} from "@shopify/polaris";
import "../../assets/scss/helicore_common.scss";
import { defaultPageOptions, showing } from "../../appconfig/Settings";
import {
  columnsToRows,
  formatDate,
  setPagination,
} from "../../appconfig/AppHelper";
import ToasterToggle from "../../components/Toster";
import { GET_CONTACT_INQUIRY } from "../../config/ApiConstant";
import axiosInstance from "../../config/axiosInstance";
import DataTableEmptyState from "../../components/DataTable/DataTableEmptyState";
import SkeletonDataTable from "../../components/DataTable/SkeletonDataTable";
import { COLUMNS_KEY, inquiryColumns } from "../../appconfig/dataTableSetting";

const Inquiry = () => {
  document.title = "Inquiry | Himalayan Stallion";
  const [pageLoad, setPageLoad] = useState(false);
  const [tableRows, setTableRows] = useState([]);
  const [paginationInner, setPageinationInner] = useState(setPagination());
  const [dataTableInnerLoading, setDataTableInnerLoading] = useState(false);
  const [perPage, setPerPage] = useState(showing);

  const [toastMessage] = useState();
  const [toasttoggle, setToastToggle] = useState(false);
  const [isError, setisError] = useState(false);
  const [paginationActive, setPaginationActive] = useState(false);

  useEffect(() => {
    getDataFromAPI();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    getDataFromAPI();
  }, [perPage]);

  const handleChangePerpage = (value) => {
    paginationInner.page = 1;
    setPerPage(value);
  };

  const handleChangePage = (action) => {
    action === "Next" ? paginationInner.page++ : paginationInner.page--;
    getDataFromAPI();
  };

  //api call
  const getDataFromAPI = async () => {
    if (pageLoad) setDataTableInnerLoading(true);
    const { page } = paginationInner;

    //API Axios Call
    const response = await axiosInstance(
      GET_CONTACT_INQUIRY +
        "?page=" +
        page +
        "&per_page=" +
        perPage +
        "&start_date="
    );
    if (response) {
      const userList = response.data.data.data;

      if (userList.length === 0) {
        setTableRows([]);
        setisError(true);
      } else {
        let tableData = [];
        for (let i = 0; i < userList.length; i++) {
          let tableRowsAppend = [];

          tableRowsAppend.push(i + 1);

          tableRowsAppend.push(
            !window.cn(userList[i].first_name) ? userList[i].first_name : ""
          );
          tableRowsAppend.push(
            !window.cn(userList[i].last_name) ? userList[i].last_name : ""
          );
          tableRowsAppend.push(
            !window.cn(userList[i].email) ? userList[i].email : ""
          );
          tableRowsAppend.push(
            !window.cn(userList[i].mobile) ? userList[i].mobile : ""
          );

          tableRowsAppend.push(
            !window.cn(userList[i].created_at)
              ? formatDate(userList[i].created_at)
              : ""
          );
          tableRowsAppend.push(
            !window.cn(userList[i].message) ? (
              <div style={{ whiteSpace: "break-spaces" }}>
                {userList[i].message}
              </div>
            ) : (
              ""
            )
          );

          tableData.push(tableRowsAppend);
        }
        setTableRows(tableData);
        setPaginationActive(true);
      }
      setDataTableInnerLoading(false);
      setPageLoad(true);

      //Get Responce Data and Set respectively
      const { current_page, total, per_page, last_page } = response.data.data;
      const pagination = setPagination(
        current_page,
        per_page,
        last_page,
        total
      );
      setPageinationInner(pagination);
    }
  };

  const Paginate = () => {
    return (
      <>
        <Pagination
          previousTooltip="Previous"
          hasPrevious={paginationInner.hasPrevious}
          onPrevious={() => handleChangePage("Previous")}
          hasNext={paginationInner.hasNext}
          nextTooltip="Next"
          onNext={() => handleChangePage("Next")}
        />
      </>
    );
  };

  return (
    <Page title="Contact inquiries" fullWidth>
      <Card>
        <Card.Section subdued>
          <Stack distribution="trailing">
            <Stack.Item>
              <Select
                label="Show"
                labelInline
                onChange={handleChangePerpage}
                value={perPage}
                options={defaultPageOptions}
              ></Select>
            </Stack.Item>
          </Stack>
        </Card.Section>
        <div className="data-wrapper">
          {pageLoad ? (
            <div>
              <DataTable
                verticalAlign="middle"
                hideScrollIndicator={true}
                columnContentTypes={columnsToRows(
                  inquiryColumns,
                  COLUMNS_KEY.TYPE
                )}
                headings={columnsToRows(inquiryColumns, COLUMNS_KEY.NAME)}
                rows={tableRows}
                footerContent={
                  tableRows.length > 0 ? (
                    <span>{paginationInner.showing}</span>
                  ) : null
                }
              />
              {tableRows.length > 0 ? (
                <div className="paginate">
                  <Stack distribution="center">
                    {paginationActive ? <Paginate /> : null}
                  </Stack>
                </div>
              ) : (
                <DataTableEmptyState />
              )}
            </div>
          ) : (
            <SkeletonDataTable defaultLength={7} columns={inquiryColumns} />
          )}
          {dataTableInnerLoading ? (
            <div className="loading">
              <Spinner size="large" />
            </div>
          ) : null}
        </div>
      </Card>

      {/* toaster */}
      {toasttoggle && (
        <ToasterToggle
          toastmessage={toastMessage}
          toastactive={() => setToastToggle(false)}
          isErrorMessage={isError}
        />
      )}
    </Page>
  );
};

export default Inquiry;
