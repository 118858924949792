export const APP_BASE_URL = "https://api.himalayanstallion.in";
// export const APP_BASE_URL = "http://127.0.0.1:8000";
// export const ASSET_PATH = "http://127.0.0.1:8000";
export const ASSET_PATH = "https://api.himalayanstallion.in/public";
export const IMAGE_BASEURL = APP_BASE_URL + "/front/search_image.png";

//default page options
export const defaultPageOptions = [
  { label: "10 entries", value: "10" },
  { label: "25 entries", value: "25" },
  { label: "50 entries", value: "50" },
  { label: "100 entries", value: "100" },
];

//date picker option
export const datePickerOptions = [
  { label: "Custom", value: "custom" },
  { label: "Today", value: "today" },
  { label: "Yesterday", value: "yesterday" },
  { label: "Last 7 days", value: "last7day" },
  { label: "Last 30 days", value: "last30days" },
  { label: "Last 90 days", value: "last90days" },
  { label: "Last month", value: "lastmonth" },
  { label: "Last year", value: "lastyear" },
  { label: "Week to date", value: "weektodate" },
  { label: "Month to date", value: "monthtodate" },
  { label: "Year to date", value: "yeartodate" },
];

//Pagination Object
export const _pagination = {
  hasNext: false,
  hasPrevious: false,
  page: 1,
  perpage: 10,
  showing: null,
};

//default pagination showing data
export const showing = "100";

//email pattern
export const emailPattern = new RegExp(
  /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
);

//options
export const Selectoptions = [
  { label: "Home", value: "home" },
  { label: "About", value: "about" },
  { label: "ContactUs", value: "contact-us" },
  { label: "Events", value: "events" },
  { label: "Live Classes", value: "live-classes-certification" },
  { label: "Online Class", value: "online-course" },
  { label: "Free Program", value: "free-programs" },
  { label: "Checkout", value: "checkout" },
];

//LiveCourse Status
export const liveCourseStatusOptions = [
  { label: "Available", value: "1" },
  { label: "Unavailable", value: "0" },
];

//LiveCourse counter Status
export const liveCourseConterStatusOptions = [
  { label: "Activate", value: "1" },
  { label: "Deactivate", value: "0" },
];

//Course class type
export const liveCourseClassTypeOptions = [
  { label: "Online", value: "online" },
  { label: "Offline", value: "offline" },
  { label: "Online & Offline", value: "online & offline" },
];

//User purchase filter
export const UserPurchaseFilter = [
  { label: "All", value: "All" },
  { label: "Purchased", value: "Purchased" },
  { label: "Unpurchased", value: "Unpurchased" },
];

//User purchase filter
export const AffiliatorFilter = [
  { label: "All", value: "All" },
  { label: "Affiliator", value: 1 },
  { label: "Non-Affiliator", value: 0 },
];
