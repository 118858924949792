import { Page } from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import DashBoardCard from "../../components/DashBoardCard";
import { GETDASHBOARD_DETAILS } from "../../config/ApiConstant";
import axiosInstance from "../../config/axiosInstance";

const Home = () => {
  const [isOnlineData, setOnlineData] = useState({});

  // Fetch Course data on every redering
  useEffect(() => {
    fetchCourseData();
  }, []);

  // Call Api function
  const fetchCourseData = async () => {
    // api call
    const response = await axiosInstance(GETDASHBOARD_DETAILS);

    if (response) {
      const courseList = response.data.data;
      // set data into object
      setOnlineData({ ...courseList });
    } else {
      setOnlineData(0);
    }
  };

  return (
    <>
      <Page>
        <div className="report-dashboard">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="card-box top">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <div className="inner-card">
                        <div className="icon-cover">
                          <i className="bi bi-shop"></i>
                        </div>
                        <h3 className="card-h">Total Sales</h3>
                        <h2 className="number-detail">
                          {isOnlineData.total_course_purchase_count}
                        </h2>
                      </div>
                    </div>

                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <div className="inner-card">
                        <div className="icon-cover">
                          <i className="bi bi-cash-stack"></i>
                        </div>
                        <h3 className="card-h">Total Revenue</h3>
                        <h2 className="number-detail">
                          <i className="bi bi-currency-rupee"></i>
                          {isOnlineData.total_amount}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <DashBoardCard
                title="Sales"
                sub_title="Today Sales"
                data={isOnlineData.total_course_purchase_count_per_day}
              />
              <DashBoardCard
                title="Revenue"
                sub_title="Today Revenue"
                data={isOnlineData.total_amount_per_day}
              />
            </div>
          </div>
        </div>
      </Page>
    </>
  );
};

export default Home;
