import React, { useState, useEffect } from "react";
//shopify
import {
  Card,
  DataTable,
  Page,
  Stack,
  Select,
  Pagination,
  Button,
  Spinner,
} from "@shopify/polaris";
import "../../../assets/scss/helicore_common.scss";
import { defaultPageOptions, showing } from "../../../appconfig/Settings";
import {
  columnsToRows,
  convertLocalToUTC,
  exportToCSV,
  formatDate,
  setPagination,
} from "../../../appconfig/AppHelper";
import ToasterToggle from "../../../components/Toster";
import {
  GET_COURSE_TYPES_REPORT,
  GET_USERCOURES_REPORT,
} from "../../../config/ApiConstant";
import axiosInstance from "../../../config/axiosInstance";
import DataTableEmptyState from "../../../components/DataTable/DataTableEmptyState";
import SkeletonDataTable from "../../../components/DataTable/SkeletonDataTable";
import {
  COLUMNS_KEY,
  liveClassReportColumns,
} from "../../../appconfig/dataTableSetting";
import moment from "moment/moment";
import CustomDatePicker from "../../../components/Date-Picker/CustomDatePicker";

const OnlineCourseReport = () => {
  document.title = "Online Course Report | Himalayan Stallion";
  const [pageLoad, setPageLoad] = useState(false);
  const [tableRows, setTableRows] = useState([]);
  const [submitLoading] = useState(false);
  const [paginationInner, setPageinationInner] = useState(setPagination());
  const [dataTableInnerLoading, setDataTableInnerLoading] = useState(false);
  const [perPage, setPerPage] = useState(showing);
  const [toastMessage, setToastMessage] = useState();
  const [toasttoggle, setToastToggle] = useState(false);
  const [isError] = useState(false);
  const [courseType, setCourseType] = useState("");
  const [paginationActive, setPaginationActive] = useState(false);
  const [exportLoader, setExportLoader] = useState(false);

  const [courseOption, setCourseOption] = useState([]);
  const [isDate, setIsdate] = useState({
    startDate: moment().subtract(1, "years").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });

  useEffect(() => {
    getCourseTypeAPI();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    getDataFromAPI();
  }, [perPage]);

  const handleChangePerpage = (value) => {
    paginationInner.page = 1;
    setPerPage(value);
  };

  const handleChangePage = (action) => {
    action === "Next" ? paginationInner.page++ : paginationInner.page--;
    getDataFromAPI();
  };

  //Handle Date Change
  const handleDateChangeEvent = (start, end) => {
    setIsdate({
      startDate: start,
      endDate: end,
    });
  };

  //Export CSV
  const exportCSV = async () => {
    setExportLoader(true);
    const response = await axiosInstance(
      GET_USERCOURES_REPORT + "?plan_type=Online"
    );

    if (response) {
      setExportLoader(false);
      const List = response.data.data;
      exportToCSV(List, "Online_Course_Data");
    } else {
      setToastToggle(true);
      setToastMessage("Something went wrong while exporting data");
      setExportLoader(false);
    }
  };

  const getCourseTypeAPI = async () => {
    const response = await axiosInstance(
      GET_COURSE_TYPES_REPORT + "?plan_type=Online"
    );
    let CourseList = response.data.data;

    if (response.data) {
      let courseOption = [];
      CourseList.map((option) => {
        const row = {
          label: option.title,
          value: option.id,
        };
        courseOption.push(row);
      });

      setCourseOption(courseOption);
    }
  };

  //api call
  const getDataFromAPI = async () => {
    if (pageLoad) setDataTableInnerLoading(true);
    const { page } = paginationInner;

    // Date TimeStamp
    const startStamp = convertLocalToUTC(isDate.startDate + " 00:00:00");
    const endStamp = convertLocalToUTC(isDate.endDate + " 23:59:59");

    const response = await axiosInstance(
      GET_USERCOURES_REPORT +
        "?page=" +
        page +
        "&per_page=" +
        perPage +
        "&plan_id=" +
        courseType +
        "&plan_type=Online" +
        "&start_date=" +
        startStamp +
        "&end_date=" +
        endStamp
    );
    if (response) {
      const courseList = response.data.data.data;
      if (courseList.length === 0) {
        setTableRows([]);
      } else {
        let tableData = [];
        for (let i = 0; i < courseList.length; i++) {
          let tableRowsAppend = [];

          tableRowsAppend.push(i + 1);

          tableRowsAppend.push(
            !window.cn(courseList[i].course_name)
              ? courseList[i].course_name
              : ""
          );
          tableRowsAppend.push(
            !window.cn(courseList[i].first_name) ? courseList[i].first_name : ""
          );
          tableRowsAppend.push(
            !window.cn(courseList[i].last_name) ? courseList[i].last_name : ""
          );
          tableRowsAppend.push(
            !window.cn(courseList[i].email) ? courseList[i].email : ""
          );
          tableRowsAppend.push(
            !window.cn(courseList[i].mobile) ? courseList[i].mobile : ""
          );
          tableRowsAppend.push(
            !window.cn(courseList[i].state) ? courseList[i].state : ""
          );
          tableRowsAppend.push(
            !window.cn(courseList[i].city) ? courseList[i].city : ""
          );
          tableRowsAppend.push(
            !window.cn(courseList[i].purchase_date)
              ? formatDate(courseList[i].purchase_date)
              : ""
          );
          tableRowsAppend.push(
            !window.cn(courseList[i].expiryDate)
              ? formatDate(courseList[i].expiryDate)
              : ""
          );
          tableRowsAppend.push(
            !window.cn(courseList[i].transaction_id)
              ? courseList[i].transaction_id
              : ""
          );
          tableRowsAppend.push(
            !window.cn(courseList[i].amount) ? courseList[i].amount : ""
          );

          tableRowsAppend.push(
            !window.cn(courseList[i].amount)
              ? (courseList[i].amount * 18) / 100
              : ""
          );

          tableRowsAppend.push(
            !window.cn(courseList[i].amount)
              ? (courseList[i].amount * 18) / 100 +
                  parseInt(courseList[i].amount)
              : ""
          );

          tableData.push(tableRowsAppend);
        }
        setTableRows(tableData);
        setPaginationActive(true);
      }
      setDataTableInnerLoading(false);
      setPageLoad(true);

      //Get Responce Data and Set respectively
      const { current_page, total, per_page, last_page } = response.data.data;
      const pagination = setPagination(
        current_page,
        per_page,
        last_page,
        total
      );
      setPageinationInner(pagination);
    }
  };

  const Paginate = () => {
    return (
      <>
        <Pagination
          previousTooltip="Previous"
          hasPrevious={paginationInner.hasPrevious}
          onPrevious={() => handleChangePage("Previous")}
          hasNext={paginationInner.hasNext}
          nextTooltip="Next"
          onNext={() => handleChangePage("Next")}
        />
      </>
    );
  };

  return (
    <Page
      title="Online Course"
      fullWidth
      secondaryActions={[
        { content: "Export", onAction: exportCSV, loading: exportLoader },
      ]}
    >
      <Card>
        <Card.Section subdued>
          <Stack distribution="trailing">
            <Stack.Item fill>
              <Select
                placeholder="Select Course"
                onChange={(value) => setCourseType(value)}
                value={Number(courseType)}
                options={courseOption}
              ></Select>
            </Stack.Item>

            <Stack.Item>
              <CustomDatePicker
                startDate={isDate.startDate}
                endDate={isDate.endDate}
                onChange={handleDateChangeEvent}
              ></CustomDatePicker>
            </Stack.Item>

            <Stack.Item>
              <Button
                primary
                fullWidth
                loading={submitLoading}
                onClick={getDataFromAPI}
              >
                Show
              </Button>
            </Stack.Item>

            <Stack.Item>
              <Select
                label="Show"
                labelInline
                onChange={handleChangePerpage}
                value={perPage}
                options={defaultPageOptions}
              ></Select>
            </Stack.Item>
          </Stack>
        </Card.Section>
        <div className="data-wrapper">
          {pageLoad ? (
            <div>
              <DataTable
                verticalAlign="middle"
                hideScrollIndicator={true}
                columnContentTypes={columnsToRows(
                  liveClassReportColumns,
                  COLUMNS_KEY.TYPE
                )}
                headings={columnsToRows(
                  liveClassReportColumns,
                  COLUMNS_KEY.NAME
                )}
                rows={tableRows}
                footerContent={
                  tableRows.length > 0 ? (
                    <span>{paginationInner.showing}</span>
                  ) : null
                }
              />
              {tableRows.length > 0 ? (
                <div className="paginate">
                  <Stack distribution="center">
                    {paginationActive ? <Paginate /> : null}
                  </Stack>
                </div>
              ) : (
                <DataTableEmptyState />
              )}
            </div>
          ) : (
            <SkeletonDataTable
              defaultLength={10}
              columns={liveClassReportColumns}
            />
          )}
          {dataTableInnerLoading ? (
            <div className="loading">
              <Spinner size="large" />
            </div>
          ) : null}
        </div>
      </Card>

      {/* toaster */}
      {toasttoggle && (
        <ToasterToggle
          toastmessage={toastMessage}
          toastactive={() => setToastToggle(false)}
          isErrorMessage={isError}
        />
      )}
    </Page>
  );
};

export default OnlineCourseReport;
