import React, { useState, useEffect } from "react";
//shopify
import {
  Card,
  DataTable,
  Page,
  Stack,
  FormLayout,
  TextContainer,
  Layout,
  Link,
  Spinner,
  Modal,
  TextField,
  Pagination,
} from "@shopify/polaris";
import "../../../assets/scss/helicore_common.scss";
import { GET_AFFILIATE_USER_DETIALS } from "../../../config/ApiConstant";
import axiosInstance from "../../../config/axiosInstance";
import ToasterToggle from "../../../components/Toster";
import { columnsToRows, formatDate } from "../../../appconfig/AppHelper";
import {
  COLUMNS_KEY,
  affiliateuserDetailsColumns,
} from "../../../appconfig/dataTableSetting";
import SkeletonDataTable from "../../../components/DataTable/SkeletonDataTable";
import DataTableEmptyState from "../../../components/DataTable/DataTableEmptyState";

const ProfileDetails = ({ match }) => {
  document.title = "Profile Details | Himalayan Stallion";
  const [tableRows, setTableRows] = useState([]);
  const [userData, setUserData] = useState();
  const [toastMessage, setToastMessage] = useState();
  const [toasttoggle, setToastToggle] = useState(false);
  const [isError, setisError] = useState(false);
  const [pageLoad, setPageLoad] = useState(false);
  const [dataTableInnerLoading, setDataTableInnerLoading] = useState(false);

  useEffect(() => {
    getDataFromAPI();
  }, []);

  const getDataFromAPI = async () => {
    const user_id = match?.params?.id;
    const response = await axiosInstance(
      GET_AFFILIATE_USER_DETIALS + "?id=" + user_id
    );
    const userList = response.data?.data?.affiliate_details;

    if (response) {
      setUserData(response?.data?.data);
    }

    if (userList.length > 0) {
      let tableData = [];
      for (let i = 0; i < userList?.length; i++) {
        let tableRowsAppend = [];
        tableRowsAppend.push(i + 1);

        tableRowsAppend.push(
          !window.cn(userList[i].purchase_type) ? userList[i].purchase_type : ""
        );

        tableRowsAppend.push(
          !window.cn(userList[i].purchase_qnty) ? userList[i].purchase_qnty : ""
        );

        tableRowsAppend.push(
          !window.cn(userList[i].ref_amt) ? userList[i].ref_amt : "-"
        );

        tableRowsAppend.push(
          !window.cn(userList[i].transaction_id)
            ? userList[i].transaction_id
            : "-"
        );
        tableRowsAppend.push(
          !window.cn(userList[i].order_amt) ? userList[i].order_amt : "-"
        );
        tableRowsAppend.push(
          !window.cn(userList[i].incl_gst_amount)
            ? userList[i].incl_gst_amount
            : "-"
        );

        tableRowsAppend.push(
          !window.cn(userList[i].created_at)
            ? formatDate(userList[i].created_at)
            : "-"
        );

        tableData.push(tableRowsAppend);
      }
      setTableRows(tableData);
    }
    setDataTableInnerLoading(false);
    setPageLoad(true);
  };

  return (
    <Page fullWidth>
      <Layout>
        <Layout.Section>
          <Card sectioned title="Affiliate Details">
            <Stack distribution="fill" wrap={false}>
              <Stack.Item>
                <TextContainer spacing="tight">
                  <div className="title_wrpper">
                    <span className="title_heading col-sm-8">
                      First Name:{" "}
                      <b>{!window.cn(userData) ? userData.first_name : ""}</b>
                    </span>
                    <span className="title_subheading col-sm-8"></span>
                  </div>
                  <div className="title_wrpper">
                    <span className="title_heading col-sm-8">
                      Last Name:{" "}
                      <b>{!window.cn(userData) ? userData.last_name : ""}</b>
                    </span>
                    <span className="title_subheading col-sm-8"></span>
                  </div>
                  <div className="title_wrpper">
                    <span className="title_heading col-sm-8">
                      Email ID:{" "}
                      <b>{!window.cn(userData) ? userData.email : ""}</b>
                    </span>
                    <span className="title_subheading col-sm-8"></span>
                  </div>
                  <div className="title_wrpper">
                    <span className="title_heading col-sm-8">
                      Mobile Number:{" "}
                      <b>{!window.cn(userData) ? userData.mobile : ""}</b>
                    </span>
                    <span className="title_subheading col-sm-8"></span>
                  </div>

                  <div className="title_wrpper">
                    <span className="title_heading col-sm-8">
                      Affiliate Code:{" "}
                      <b>
                        {!window.cn(userData) ? userData.referral_code : ""}
                      </b>
                    </span>
                    <span className="title_subheading col-sm-8"></span>
                  </div>
                </TextContainer>
              </Stack.Item>
              <Stack.Item>
                <TextContainer spacing="tight">
                  <div className="title_wrpper">
                    <span className="title_heading col-sm-8">
                      Total Referral Amount:{" "}
                      <b>
                        {!window.cn(userData)
                          ? userData.total_referral_amt
                          : ""}
                      </b>
                    </span>
                    <span className="title_subheading col-sm-8"></span>
                  </div>

                  <div className="title_wrpper">
                    <span className="title_heading col-sm-8">
                      Wallet:{" "}
                      <b>{!window.cn(userData) ? userData.wallet : ""}</b>
                    </span>
                    <span className="title_subheading col-sm-8"></span>
                  </div>

                  <div className="title_wrpper">
                    <span className="title_heading col-sm-8">
                      Withdrawal Amount:{" "}
                      <b>
                        {!window.cn(userData) ? userData.withdrawal_amt : ""}
                      </b>
                    </span>
                    <span className="title_subheading col-sm-8"></span>
                  </div>
                </TextContainer>
              </Stack.Item>
            </Stack>
          </Card>
        </Layout.Section>
      </Layout>
      <br />
      <Layout>
        <Layout.Section oneThird>
          <Card title="Id Proofs" sectioned>
            <Stack.Item>
              <TextContainer spacing="tight">
                <div className="title_wrpper">
                  <span className="title_heading col-sm-8">
                    Aadhar Number:{" "}
                    <b>{!window.cn(userData) ? userData.aadhar_number : ""}</b>
                  </span>
                  <span className="title_subheading col-sm-8"></span>
                </div>
                <div className="title_wrpper">
                  <span className="title_heading col-sm-8">
                    Aadhar Card: &nbsp;&nbsp;
                    <Link
                      url={!window.cn(userData) ? userData.aadhar_file : ""}
                      external
                    >
                      view
                    </Link>
                  </span>
                  <span className="title_subheading col-sm-8"></span>
                </div>
                <div className="title_wrpper">
                  <span className="title_heading col-sm-8">
                    Pan Number:{" "}
                    <b>{!window.cn(userData) ? userData.pan_number : ""}</b>
                  </span>
                  <span className="title_subheading col-sm-8"></span>
                </div>
                <div className="title_wrpper">
                  <span className="title_heading col-sm-8">
                    Pan Card: &nbsp;&nbsp;
                    <Link
                      url={!window.cn(userData) ? userData.pan_file : ""}
                      external
                    >
                      view
                    </Link>
                  </span>
                  <span className="title_subheading col-sm-8"></span>
                </div>
              </TextContainer>
            </Stack.Item>
          </Card>
        </Layout.Section>

        <Layout.Section oneThird>
          <Card title="Bank Details" sectioned>
            <Stack.Item>
              <TextContainer spacing="tight">
                <div className="title_wrpper">
                  <span className="title_heading col-sm-8">
                    Account Holder Name:{" "}
                    <b>
                      {!window.cn(userData)
                        ? userData.account_holder_name
                        : "-"}
                    </b>
                  </span>
                  <span className="title_subheading col-sm-8"></span>
                </div>

                <div className="title_wrpper">
                  <span className="title_heading col-sm-8">
                    Account No:{" "}
                    <b>
                      {!window.cn(userData) ? userData.account_number : "-"}
                    </b>
                  </span>
                  <span className="title_subheading col-sm-8"></span>
                </div>

                <div className="title_wrpper">
                  <span className="title_heading col-sm-8">
                    Bank Name:{" "}
                    <b>{!window.cn(userData) ? userData.bank_name : ""}</b>
                  </span>
                  <span className="title_subheading col-sm-8"></span>
                </div>
                <div className="title_wrpper">
                  <span className="title_heading col-sm-8">
                    IFSC Code:{" "}
                    <b>{!window.cn(userData) ? userData.ifsc_code : ""}</b>
                  </span>
                  <span className="title_subheading col-sm-8"></span>
                </div>
              </TextContainer>
            </Stack.Item>
          </Card>
        </Layout.Section>
      </Layout>
      <br />
      <Layout>
        <Layout.Section>
          <Card title="Affiliate Details" sectioned>
            <div className="data-wrapper" style={{ marginTop: "20px" }}>
              {pageLoad ? (
                <div>
                  <DataTable
                    verticalAlign="middle"
                    hideScrollIndicator={true}
                    columnContentTypes={columnsToRows(
                      affiliateuserDetailsColumns,
                      COLUMNS_KEY.TYPE
                    )}
                    headings={columnsToRows(
                      affiliateuserDetailsColumns,
                      COLUMNS_KEY.NAME
                    )}
                    rows={tableRows}
                  />
                  {tableRows.length < 0 && <DataTableEmptyState />}
                </div>
              ) : (
                <SkeletonDataTable
                  defaultLength={7}
                  columns={affiliateuserDetailsColumns}
                />
              )}
              {dataTableInnerLoading ? (
                <div className="loading">
                  <Spinner size="large" />
                </div>
              ) : null}
            </div>
          </Card>
        </Layout.Section>
      </Layout>

      {/* toaster */}
      {toasttoggle && (
        <ToasterToggle
          toastmessage={toastMessage}
          toastactive={() => setToastToggle(false)}
          isErrorMessage={isError}
        />
      )}
    </Page>
  );
};

export default ProfileDetails;
