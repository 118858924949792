import React, { useState, useEffect, useCallback } from "react";
//shopifyy
import {
  Card,
  DataTable,
  Page,
  Stack,
  Select,
  Pagination,
  Button,
  Icon,
  TextField,
  Link,
  Spinner,
  FormLayout,
  Modal,
  Layout,
} from "@shopify/polaris";
import "../../../assets/scss/helicore_common.scss";
import { defaultPageOptions, showing } from "../../../appconfig/Settings";
import {
  columnsToRows,
  convertLocalToUTC,
  exportToCSV,
  formatDate,
  setPagination,
} from "../../../appconfig/AppHelper";
import ToasterToggle from "../../../components/Toster";
import {
  AFFILIATE_PAYOUT,
  GET_AFFILIATE_REPORT,
} from "../../../config/ApiConstant";
import axiosInstance from "../../../config/axiosInstance";
import DataTableEmptyState from "../../../components/DataTable/DataTableEmptyState";
import SkeletonDataTable from "../../../components/DataTable/SkeletonDataTable";
import {
  COLUMNS_KEY,
  affiliateReportColumns,
} from "../../../appconfig/dataTableSetting";
import { SearchMinor } from "@shopify/polaris-icons";
import moment from "moment/moment";
import CustomDatePicker from "../../../components/Date-Picker/CustomDatePicker";

const AffiliateReport = () => {
  document.title = "Affiliate Report | Himalayan Stallion";
  const [pageLoad, setPageLoad] = useState(false);
  const [tableRows, setTableRows] = useState([]);
  const [paginationInner, setPageinationInner] = useState(setPagination());
  const [dataTableInnerLoading, setDataTableInnerLoading] = useState(false);
  const [perPage, setPerPage] = useState(showing);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isPaymentDone, setPaymentDone] = useState(false);
  const [isFiledName, setFiledName] = useState({
    filedName: "account_holder_name",
  });
  const [filedType, setFieldType] = useState({
    sort_type: "desc",
  });
  const [toastMessage, setToastMessage] = useState();
  const [toasttoggle, setToastToggle] = useState(false);
  const [isError, setisError] = useState(false);
  const [paginationActive, setPaginationActive] = useState(false);
  const [exportLoader, setExportLoader] = useState(false);
  const [payModal, setPayModal] = useState(false);
  const [isAmount, setAmount] = useState(0);
  const [isUserId, setUserID] = useState(null);
  const [isDate, setIsdate] = useState({
    startDate: moment().subtract(1, "years").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const [search, setSearch] = useState("");

  const handleUpdateModal = (id) => {
    setPayModal(true);
    setUserID(id);
  };

  const closePayModal = () => {
    setPayModal(false);
  };

  useEffect(() => {
    getDataFromAPI();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    getDataFromAPI();
  }, [perPage, filedType, isPaymentDone]);

  const handleChangePerpage = (value) => {
    paginationInner.page = 1;
    setPerPage(value);
  };

  //Sorting First Name

  const handleSort = useCallback((index, direction) => {
    if (direction === "ascending") {
      setFiledName({ filedName: affiliateReportColumns[index].value });
      setFieldType({ sort_type: "asc" });
    } else if (direction === "descending") {
      setFiledName({ filedName: affiliateReportColumns[index].value });
      setFieldType({ sort_type: "desc" });
    } else {
      setFiledName({ filedName: affiliateReportColumns[index].value });
      setFieldType({ sort_type: "asc" });
    }
  }, []);

  const handleChangePage = (action) => {
    action === "Next" ? paginationInner.page++ : paginationInner.page--;
    getDataFromAPI();
  };

  const handleTitleChange = (value) => {
    setSearch(value);
  };

  const handleClearTitle = () => {
    setSearch("");
  };

  //Handle Date Change
  const handleDateChangeEvent = (start, end) => {
    setIsdate({
      startDate: start,
      endDate: end,
    });
  };

  //Export CSV
  const exportCSV = async () => {
    setExportLoader(true);
    const response = await axiosInstance(
      GET_AFFILIATE_REPORT +
        "?sort_type=" +
        filedType.sort_type +
        "&sort_by=" +
        isFiledName.filedName
    );
    if (response) {
      setExportLoader(false);
      const List = response.data.data;
      exportToCSV(List, "Affiliate_Report_Data");
    } else {
      setToastToggle(true);
      setToastMessage("Something went wrong while exporting data");
      setExportLoader(false);
    }
  };

  //api call
  const getDataFromAPI = async () => {
    if (pageLoad) setDataTableInnerLoading(true);
    const { page } = paginationInner;

    // Date TimeStamp
    let startStamp = convertLocalToUTC(isDate.startDate + " 00:00:00");
    let endStamp = convertLocalToUTC(isDate.endDate + " 23:59:59");

    //Check Search Parameter base Set Object
    if (search !== "") {
      startStamp = "";
      endStamp = "";
    }

    //API Axios Call
    const response = await axiosInstance(
      GET_AFFILIATE_REPORT +
        "?page=" +
        page +
        "&per_page=" +
        perPage +
        "&start_date=" +
        startStamp +
        "&end_date=" +
        endStamp +
        "&sort_type=" +
        filedType.sort_type +
        "&sort_by=" +
        isFiledName.filedName +
        "&search=" +
        search
    );
    if (response) {
      const userList = response.data.data.data;

      if (userList.length === 0) {
        setTableRows([]);
        setisError(true);
      } else {
        let tableData = [];
        for (let i = 0; i < userList.length; i++) {
          let tableRowsAppend = [];

          tableRowsAppend.push(i + 1);

          tableRowsAppend.push(
            !window.cn(userList[i].account_holder_name)
              ? userList[i].account_holder_name
              : ""
          );
          tableRowsAppend.push(
            !window.cn(userList[i].referral_code)
              ? userList[i].referral_code
              : ""
          );
          tableRowsAppend.push(
            !window.cn(userList[i].created_at)
              ? formatDate(userList[i].created_at)
              : ""
          );
          tableRowsAppend.push(
            !window.cn(userList[i].mobile) ? userList[i].mobile : ""
          );

          tableRowsAppend.push(
            !window.cn(userList[i].email) ? userList[i].email : ""
          );
          tableRowsAppend.push(
            !window.cn(userList[i].who_are_you) ? userList[i].who_are_you : ""
          );

          tableRowsAppend.push(
            !window.cn(userList[i].monthly_sales)
              ? userList[i].monthly_sales
              : ""
          );
          tableRowsAppend.push(
            !window.cn(userList[i].nutrition_course)
              ? userList[i].nutrition_course
              : ""
          );
          tableRowsAppend.push(
            !window.cn(userList[i].training_course)
              ? userList[i].training_course
              : ""
          );
          tableRowsAppend.push(
            !window.cn(userList[i].ticket) ? userList[i].ticket : ""
          );
          tableRowsAppend.push(
            !window.cn(userList[i].membership) ? userList[i].membership : ""
          );
          tableRowsAppend.push(
            !window.cn(userList[i].bodybuilding) ? userList[i].bodybuilding : ""
          );
          tableRowsAppend.push(
            !window.cn(userList[i].powerlifting) ? userList[i].powerlifting : ""
          );
          tableRowsAppend.push(
            !window.cn(userList[i]?.wallet)
              ? parseFloat(userList[i]?.wallet).toFixed(2)
              : "0.00"
          );

          tableRowsAppend.push(
            !window.cn(userList[i].last_month_revenue_hs)
              ? parseFloat(userList[i].last_month_revenue_hs).toFixed(2)
              : "0.00"
          );
          tableRowsAppend.push(
            !window.cn(userList[i].last_month_revenue)
              ? parseFloat(userList[i].last_month_revenue).toFixed(2)
              : "0.00"
          );

          tableRowsAppend.push(
            <Button onClick={() => handleUpdateModal(userList[i].id)}>
              Pay
            </Button>
          );

          tableRowsAppend.push(
            !window.cn(userList[i].id) ? (
              <Link url={`profiledetails/${userList[i].id}`} external>
                View
              </Link>
            ) : (
              ""
            )
          );

          tableData.push(tableRowsAppend);
        }
        setTableRows(tableData);
        setPaginationActive(true);
      }
      setDataTableInnerLoading(false);
      setPageLoad(true);

      //Get Responce Data and Set respectively
      const { current_page, total, per_page, last_page } = response.data.data;
      const pagination = setPagination(
        current_page,
        per_page,
        last_page,
        total
      );
      setPageinationInner(pagination);
    }
  };

  const onPayHandler = async () => {
    try {
      setSubmitLoading(true);
      const formData = new FormData();

      formData.append("id", isUserId);
      formData.append("amount", isAmount);

      // Api call
      const response = await axiosInstance.post(AFFILIATE_PAYOUT, formData);
      if (response) {
        setToastToggle(true);
        setToastMessage(response.data.message);
        setPayModal(false);
        setSubmitLoading(false);
        setPaymentDone(true);
      }
    } catch (error) {
      if (error.response) {
        setSubmitLoading(false);
        setToastToggle(true);
        setPayModal(false);
        setToastMessage(error.response.data.error);
        setPaymentDone(false);
      }
    }
  };

  const Paginate = () => {
    return (
      <>
        <Pagination
          previousTooltip="Previous"
          hasPrevious={paginationInner.hasPrevious}
          onPrevious={() => handleChangePage("Previous")}
          hasNext={paginationInner.hasNext}
          nextTooltip="Next"
          onNext={() => handleChangePage("Next")}
        />
      </>
    );
  };

  return (
    <Page
      title="Affiliate Report"
      fullWidth
      secondaryActions={[
        { content: "Export", onAction: exportCSV, loading: exportLoader },
      ]}
    >
      <Card>
        <Card.Section subdued>
          <Stack distribution="trailing" wrap={false}>
            <Stack.Item fill>
              <TextField
                labelHidden
                autoComplete="off"
                placeholder="Enter Title"
                value={search || ""}
                onChange={(value) => handleTitleChange(value)}
                prefix={<Icon source={SearchMinor} color="inkLighter" />}
                clearButton
                onClearButtonClick={() => handleClearTitle()}
              />
            </Stack.Item>

            <Stack.Item>
              <CustomDatePicker
                startDate={isDate.startDate}
                endDate={isDate.endDate}
                onChange={handleDateChangeEvent}
              ></CustomDatePicker>
            </Stack.Item>

            <Stack.Item>
              <Button
                primary
                fullWidth
                loading={submitLoading}
                onClick={getDataFromAPI}
              >
                Show
              </Button>
            </Stack.Item>

            <Stack.Item>
              <Select
                label="Show"
                labelInline
                onChange={handleChangePerpage}
                value={perPage}
                options={defaultPageOptions}
              ></Select>
            </Stack.Item>
          </Stack>
        </Card.Section>

        <div className="data-wrapper">
          {pageLoad ? (
            <div>
              <DataTable
                verticalAlign="middle"
                columnContentTypes={columnsToRows(
                  affiliateReportColumns,
                  COLUMNS_KEY.TYPE
                )}
                defaultSortDirection="descending"
                initialSortColumnIndex={2}
                hideScrollIndicator={true}
                sortable={[
                  false,
                  true,
                  false,
                  true,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                ]}
                onSort={handleSort}
                headings={columnsToRows(
                  affiliateReportColumns,
                  COLUMNS_KEY.NAME
                )}
                rows={tableRows}
                footerContent={
                  tableRows.length > 0 ? (
                    <span>{paginationInner.showing}</span>
                  ) : null
                }
              />
              {tableRows.length > 0 ? (
                <div className="paginate">
                  <Stack distribution="center">
                    {paginationActive ? <Paginate /> : null}
                  </Stack>
                </div>
              ) : (
                <DataTableEmptyState />
              )}
            </div>
          ) : (
            <SkeletonDataTable
              defaultLength={19}
              columns={affiliateReportColumns}
            />
          )}
          {dataTableInnerLoading ? (
            <div className="loading">
              <Spinner size="large" />
            </div>
          ) : null}
        </div>
      </Card>

      <Modal
        open={payModal}
        onClose={() => closePayModal()}
        title="Payment"
        primaryAction={{
          content: "Pay",
          loading: submitLoading,
          onAction: () => onPayHandler(),
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: () => closePayModal(),
          },
        ]}
      >
        <Modal.Section>
          <FormLayout>
            <FormLayout.Group>
              <TextField
                type="number"
                label="Amount"
                value={isAmount}
                onChange={(value) => setAmount(value)}
                autoComplete="off"
              />
            </FormLayout.Group>
          </FormLayout>
        </Modal.Section>
      </Modal>

      {/* toaster */}
      {toasttoggle && (
        <ToasterToggle
          toastmessage={toastMessage}
          toastactive={() => setToastToggle(false)}
          isErrorMessage={isError}
        />
      )}
    </Page>
  );
};

export default AffiliateReport;
