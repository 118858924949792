import React, { useState, useEffect } from "react";
//shopify
import {
  Card,
  TextField,
  TextStyle,
  Page,
  Stack,
  FormLayout,
  Select,
  Button,
} from "@shopify/polaris";
import "../../../assets/scss/helicore_common.scss";
import CategoryImage from "../../../components/CategoryImage";
import ToasterToggle from "../../../components/Toster";
import { ADD_ONLINECOURSE } from "../../../config/ApiConstant";
import axiosInstance from "../../../config/axiosInstance";
import CKEditor from "react-ckeditor-component";
import { useHistory } from "react-router-dom";
import { Selectoptions } from "../../../appconfig/Settings";
import VideoUpload from "../../../components/VideoUpload";

const AddOnlineCourses = () => {
  const [onlineCourse, setOnllineCourse] = useState({});
  const [formValues, setFormValues] = useState([
    { video_title: "", video_description: "" },
  ]);
  const [validation, setValidation] = useState({
    title: false,
    descriptionData: false,
    button_title: false,
    button_link: false,
    price: false,
    course_length_days: false,
    image: false,
  });

  document.title = "Online Course | Himalayan Stallion";
  const [submitLoading, setSubmitLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState();
  const [toasttoggle, setToastToggle] = useState(false);
  const [isError, setisError] = useState(false);
  const [editId, setEditId] = useState(null);
  const [descriptionData, setDescriptionData] = useState();
  const [videoArr] = useState([]);
  const history = useHistory();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  let addFormFields = () => {
    setFormValues([...formValues, { video_title: "", video_description: "" }]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    videoArr.splice(i, 1);
    setFormValues(newFormValues);
  };

  //BG_images variables
  let getBGImagesRefFromUpdateImage = null;

  //gets media images
  const getBGImagesFromUpdateImage = (ref) => {
    getBGImagesRefFromUpdateImage = ref;
  };

  //images variables
  let getImagesRefFromAddImage = null;

  //gets media images
  const getImagesFromAddImage = (ref) => {
    getImagesRefFromAddImage = ref;
  };

  let getVideoRefFromUploadVideo = null;

  const getVideoFromUploadVideo = (ref) => {
    getVideoRefFromUploadVideo = ref;
    const getVideo = getVideoRefFromUploadVideo?.getVideos();

    const getIndex = getVideoRefFromUploadVideo?.getIndexNumber();
    if (getVideo.length > 0) {
      videoArr[getIndex] = getVideo[0];
    }
  };

  let handleChangeData = (name, value, i) => {
    let newFormValues = [...formValues];
    newFormValues[i][name] = value;
    setFormValues(newFormValues);

    let validations = true;
    if (value !== "" && value !== undefined) {
      validations = false;

      setValidation((prev) => ({
        ...prev,
        video_title: validations,
        video_description: validations,
      }));
    }
  };

  // handleChange
  const handleChange = (name, value, i) => {
    let validations = true;
    if (value !== "" && value !== undefined) {
      validations = false;
    }
    setValidation((prev) => ({ ...prev, [name]: validations }));
    setOnllineCourse({ ...onlineCourse, [name]: value });
  };

  //onChange description
  const onChangeDesc = (name, event) => {
    var newContent = event.editor.getData();

    if (name === "description") {
      setDescriptionData(newContent);
    }

    let validations = true;
    if ([name] !== "" && [name] !== undefined) {
      validations = false;
    }

    setValidation((prev) => ({
      ...prev,
      descriptionData: validations,
    }));
  };

  //checks validation
  const validate = () => {
    let validate = true;
    const image = getImagesRefFromAddImage?.getImages();
    const bg_img = getBGImagesRefFromUpdateImage?.getImages();
    const video = getVideoRefFromUploadVideo?.getVideos();

    //Validation
    if (window.cn(onlineCourse.title)) {
      setValidation((prev) => ({ ...prev, title: true }));
      validate = false;
    }
    if (window.cn(onlineCourse.button_title)) {
      setValidation((prev) => ({ ...prev, button_title: true }));
      validate = false;
    }
    if (window.cn(onlineCourse.price)) {
      setValidation((prev) => ({ ...prev, price: true }));
      validate = false;
    }
    if (window.cn(onlineCourse.button_link)) {
      setValidation((prev) => ({ ...prev, button_link: true }));
      validate = false;
    }
    if (window.cn(descriptionData)) {
      setValidation((prev) => ({ ...prev, descriptionData: true }));
      validate = false;
    }
    if (window.cn(onlineCourse.course_length_days)) {
      setValidation((prev) => ({ ...prev, course_length_days: true }));
      validate = false;
    }

    if (editId == null || editId === "" || editId === undefined) {
      if (image.length <= 0) {
        setValidation((prev) => ({ ...prev, image: true }));
        validate = false;
      }

      if (video.length <= 0) {
        setValidation((prev) => ({ ...prev, video: true }));
        validate = false;
      }

      if (bg_img.length <= 0) {
        setValidation((prev) => ({ ...prev, bg_img: true }));
        validate = false;
      }
    }
    return validate;
  };

  //Add data
  const onSubmit = async () => {
    try {
      const isValidate = validate();
      if (isValidate) {
        setSubmitLoading(true);

        const formData = new FormData();

        const images = getImagesRefFromAddImage?.getImages();

        const bg_img = getBGImagesRefFromUpdateImage?.getImages();

        const { title, sub_title, button_title, button_link, price, course_length_days } =
          onlineCourse;

        //set formData
        if (images.length > 0) formData.append("image", images[0]);

        formData.append("title", title);
        formData.append("sub_title", sub_title);
        formData.append("button_title", button_title);
        formData.append("button_link", button_link);
        formData.append("description", descriptionData);
        formData.append("price", price);
        formData.append("bg_img", bg_img[0]);
        formData.append("course_length_days", course_length_days);
        formData.append("content_type", "onlinecoursesData");
        formData.append("plan_type", "Online");

        for (let i = 0; i < videoArr.length; i++) {
          formData.append("video_file[]", videoArr[i]);
        }
        formData.append("video_data", JSON.stringify(formValues));

        // Api call
        const response = await axiosInstance.post(ADD_ONLINECOURSE, formData);
        if (response.data) {
          setSubmitLoading(false);
          setOnllineCourse({});
          setDescriptionData("");
          setEditId(null);
          setTimeout(() => {
            history.push("/onlinecourses");
          }, 2000);
          setToastToggle(true);
          setToastMessage(response.data.message);
          setisError(false);
          getImagesRefFromAddImage?.remove();
          getVideoRefFromUploadVideo?.remove();
        }
      }
    } catch (error) {
      if (error.response) {
        setSubmitLoading(false);
        setToastToggle(true);
        setToastMessage(error.response.data.error);
        setisError(true);
      }
    }
  };

  //CllearData
  const clearAll = () => {
    setOnllineCourse({});
    setDescriptionData("");
    setEditId(null);
    getImagesRefFromAddImage?.remove();
    getVideoRefFromUploadVideo?.remove();
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <Page
      title="Online Course"
      fullWidth
      breadcrumbs={[
        {
          content: "Reports",
          onAction: handleBack,
        },
      ]}
    >
      <Card
        sectioned
        secondaryFooterActions={[
          { content: "Cancel", onAction: () => clearAll() },
        ]}
        primaryFooterAction={{
          content: <>Add</>,
          loading: submitLoading,
          onAction: () => onSubmit(),
        }}
      >
        <FormLayout>
          <TextField
            label="Title"
            value={onlineCourse.title}
            onChange={(value) => handleChange("title", value)}
            autoComplete="off"
            error={validation.title ? "Title can't be blank." : false}
          />

          <TextField
            label="Sub Title"
            value={onlineCourse.sub_title}
            onChange={(value) => handleChange("sub_title", value)}
            autoComplete="off"
          />

          <Stack.Item>
            <label className="Polaris-Label__Text Polaris-Label__RequiredIndicator label-font">
              Background Image
            </label>
            <CategoryImage getImagesFromAddImage={getBGImagesFromUpdateImage} />
            {validation.bg_img ? (
              <div className="Polaris-InlineError">
                <div className="Polaris-InlineError__Icon">
                  <span className="Polaris-Icon">
                    <span className="Polaris-VisuallyHidden"></span>
                    <svg
                      viewBox="0 0 20 20"
                      className="Polaris-Icon__Svg"
                      focusable="false"
                      aria-hidden="true"
                    >
                      <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zM9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"></path>
                    </svg>
                  </span>
                </div>
                <span>Background image is required</span>
              </div>
            ) : null}
          </Stack.Item>

          <TextField
            label="Price"
            value={onlineCourse.price}
            onChange={(value) => handleChange("price", value)}
            autoComplete="off"
            error={validation.price ? "Price can't be blank." : false}
          />
          <TextField
            label="Course Days"
            value={onlineCourse.course_length_days}
            onChange={(value) => handleChange("course_length_days", value)}
            autoComplete="off"
            error={
              validation.course_length_days
                ? "Course Days can't be blank."
                : false
            }
          />
          <Stack.Item>
            <label className="Polaris-Label__Text Polaris-Label__RequiredIndicator label-font">
              Image
            </label>
            <CategoryImage getImagesFromAddImage={getImagesFromAddImage} />
            {validation.image ? (
              <div className="Polaris-InlineError">
                <div className="Polaris-InlineError__Icon">
                  <span className="Polaris-Icon">
                    <span className="Polaris-VisuallyHidden"></span>
                    <svg
                      viewBox="0 0 20 20"
                      className="Polaris-Icon__Svg"
                      focusable="false"
                      aria-hidden="true"
                    >
                      <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zM9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"></path>
                    </svg>
                  </span>
                </div>
                <span>image is required</span>
              </div>
            ) : null}
            <TextStyle variation="warning">
              Note:Image size (1440px*760px).
            </TextStyle>
          </Stack.Item>
          <Stack.Item>
            <label className="Polaris-Label__Text label-font">
              Description
            </label>
            <CKEditor
              activeClass="p10"
              content={descriptionData || ""}
              events={{
                change: (content) => onChangeDesc("description", content),
              }}
            />

            {validation.descriptionData ? (
              <div className="Polaris-InlineError">
                <div className="Polaris-InlineError__Icon">
                  <span className="Polaris-Icon">
                    <span className="Polaris-VisuallyHidden"></span>
                    <svg
                      viewBox="0 0 20 20"
                      className="Polaris-Icon__Svg"
                      focusable="false"
                      aria-hidden="true"
                    >
                      <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zM9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"></path>
                    </svg>
                  </span>
                </div>
                <span>Description can't be blank</span>
              </div>
            ) : null}
          </Stack.Item>
          <FormLayout.Group>
            <TextField
              label="Button"
              value={onlineCourse.button_title}
              onChange={(value) => handleChange("button_title", value)}
              autoComplete="off"
              error={
                validation.button_title ? "Button Title can't be blank." : false
              }
            />

            <Stack.Item>
              <label
                className="Polaris-Label__Text label-font"
                style={{ marginBottom: "0.25rem" }}
              >
                Button Link
              </label>
              <Select
                placeholder="Select Link"
                onChange={(value) => handleChange("button_link", value)}
                value={onlineCourse.button_link}
                options={Selectoptions}
              ></Select>
              {validation.button_link ? (
                <div className="Polaris-InlineError">
                  <div className="Polaris-InlineError__Icon">
                    <span className="Polaris-Icon">
                      <span className="Polaris-VisuallyHidden"></span>
                      <svg
                        viewBox="0 0 20 20"
                        className="Polaris-Icon__Svg"
                        focusable="false"
                        aria-hidden="true"
                      >
                        <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zM9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"></path>
                      </svg>
                    </span>
                  </div>
                  <span>Select Link</span>
                </div>
              ) : null}
            </Stack.Item>
          </FormLayout.Group>
          <hr />
          <Stack.Item>
            <h5>Online-Course video and it's details</h5>
          </Stack.Item>
          {formValues.map((element, index) => (
            <>
              <Stack.Item>
                <TextField
                  label="Video Title"
                  value={formValues[index].video_title}
                  onChange={(value) =>
                    handleChangeData("video_title", value, index)
                  }
                  autoComplete="off"
                  error={
                    validation.video_title
                      ? "Video Title can't be blank."
                      : false
                  }
                />
              </Stack.Item>

              <Stack.Item>
                <label className="Polaris-Label__Text label-font">
                  Video Description
                </label>
                <CKEditor
                  activeClass="p10"
                  content={formValues[index].video_description || ""}
                  events={{
                    change: (e) =>
                      handleChangeData(
                        "video_description",
                        e.editor.getData(),
                        index
                      ),
                  }}
                />

                {validation.video_description ? (
                  <div className="Polaris-InlineError">
                    <div className="Polaris-InlineError__Icon">
                      <span className="Polaris-Icon">
                        <span className="Polaris-VisuallyHidden"></span>
                        <svg
                          viewBox="0 0 20 20"
                          className="Polaris-Icon__Svg"
                          focusable="false"
                          aria-hidden="true"
                        >
                          <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zM9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"></path>
                        </svg>
                      </span>
                    </div>
                    <span>Video Description can't be blank</span>
                  </div>
                ) : null}
              </Stack.Item>

              <Stack.Item>
                <label className="Polaris-Label__Text Polaris-Label__RequiredIndicator label-font">
                  Video
                </label>
                <VideoUpload
                  getVideos={getVideoFromUploadVideo}
                  indexNumber={index}
                />

                {validation.video ? (
                  <div className="Polaris-InlineError">
                    <div className="Polaris-InlineError__Icon">
                      <span className="Polaris-Icon">
                        <span className="Polaris-VisuallyHidden"></span>
                        <svg
                          viewBox="0 0 20 20"
                          className="Polaris-Icon__Svg"
                          focusable="false"
                          aria-hidden="true"
                        >
                          <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zM9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"></path>
                        </svg>
                      </span>
                    </div>
                    <span>Video is required</span>
                  </div>
                ) : null}
              </Stack.Item>

              <Stack distribution="leading">
                <Stack.Item>
                  {index ? (
                    <div style={{ color: "#bf0711", marginTop: "10px" }}>
                      <Button
                        monochrome
                        outline
                        onClick={() => removeFormFields(index)}
                      >
                        Remove Video
                      </Button>
                    </div>
                  ) : null}
                </Stack.Item>

                <Stack.Item>
                  <div style={{ marginTop: "10px" }}>
                    <Button primary onClick={() => addFormFields()}>
                      Add More Videos
                    </Button>
                  </div>
                </Stack.Item>
              </Stack>

              <hr />
            </>
          ))}
        </FormLayout>
      </Card>

      {/* toaster */}
      {toasttoggle && (
        <ToasterToggle
          toastmessage={toastMessage}
          toastactive={() => setToastToggle(false)}
          isErrorMessage={isError}
        />
      )}
    </Page>
  );
};

export default AddOnlineCourses;
