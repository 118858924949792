import React from "react";

function AffiliateDashCard({ title, data, isUser }) {
  return (
    <div class="card shadow">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <span class="h6 font-semibold text-muted text-sm d-block mb-2">
              {title}
            </span>
            <span class="h3 font-bold mb-0">{data}</span>
          </div>
          <div class="col-auto">
            <div class="icon icon-shape bg-tertiary text-white text-lg rounded-circle">
              <i class="bi bi-credit-card"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AffiliateDashCard;
