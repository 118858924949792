import React, { useCallback, useEffect, useState } from "react";
import {
  Banner,
  List,
  DropZone,
  Stack,
  VideoThumbnail,
} from "@shopify/polaris";
import { PlayMajor } from "@shopify/polaris-icons";

export default function VideoUpload({ getVideos, indexNumber }) {
  useEffect(() => {
    getVideos({
      getVideos: sendVideoToParent,
      remove: dropzoneremove,
      getIndexNumber: sendIndexNumber,
    });
  });

  const sendVideoToParent = () => {
    return files;
  };

  const validVideoTypes = ["video/mp4"];
  const [files, setFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);

  const handleDropZone = useCallback(
    (_dropFiles, _acceptedFiles, _rejectedFiles, index) => {
      if (_rejectedFiles.length > 0) {
        setRejectedFiles((files) => [...files, ..._rejectedFiles]);
      }
      if (_acceptedFiles.length > 0) {
        setRejectedFiles([]);
        setFiles((files) => [...files, ..._acceptedFiles]);
      }
    }
  );

  const dropzoneremove = useCallback(() => {
    setFiles([]);
    uploadedFiles();
  }, [files]);

  const sendIndexNumber = () => {
    return indexNumber;
  };

  const uploadedFiles = files.length > 0 && (
    <div style={{ margin: 10 }}>
      <Stack alignment={"center"} distribution={"center"} spacing={"loose"}>
        {files.map((file, index) => (
          <Stack alignment="center" spacing={"tight"} key={index}>
            <div className="show-image">
              <VideoThumbnail
                thumbnailUrl={
                  validVideoTypes.includes(file.type) ? PlayMajor : PlayMajor
                }
              />
            </div>
          </Stack>
        ))}
      </Stack>
    </div>
  );

  const hasError = rejectedFiles.length > 0;

  const errorMessage = hasError && (
    <Banner
      title="The following Video couldn\u2019t be uploaded:"
      status="critical"
    >
      <List type="bullet">
        {rejectedFiles.map((file, index) => (
          <List.Item key={index}>
            {`"${file[index]?.name}" is not supported. File type must be .mp4`}
          </List.Item>
        ))}
      </List>
    </Banner>
  );

  return (
    <>
      {errorMessage}

      <div className="image_add_box">
        <DropZone
          accept="video/*"
          type="video"
          onDrop={handleDropZone}
          allowMultiple={false}
        >
          <DropZone.FileUpload />
        </DropZone>
      </div>

      {uploadedFiles}
    </>
  );
}
