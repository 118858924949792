//Columns Key Global
export const COLUMNS_KEY = {
  NAME: "name",
  FIELDS: "field",
  SORTABLE: "sortable",
  TYPE: "type",
  LABEL: "label",
  PROGRESS: "progress",
  COLOR: "color",
  SORT: "sort",
};

//Live Course Certifications Home Page Columns
export const LiveCourseCertificationColumns = [
  { name: "Image", type: "text" },
  { name: "Title", type: "text" },
  { name: "Course Name", type: "text" },
  { name: "Course Start Date", type: "text" },
  { name: "Course End Date", type: "text" },
  { name: "Exam Date", type: "text" },
  { name: "Status", type: "text" },
  { name: "Action", type: "text" },
];

//Mission columns
export const HomeBannerColumns = [
  { name: "Image", type: "text" },
  { name: "Title", type: "text" },
  { name: "Description", type: "text" },
  { name: "Status", type: "text" },
  { name: "Action", type: "text" },
];

//Mission columns
export const MissionColumns = [
  { name: "Image", type: "text" },
  { name: "Title", type: "text" },
  { name: "Description", type: "text" },
  { name: "Status", type: "text" },
  { name: "Action", type: "text" },
];

//Programd columns
export const ProgramsColumns = [
  { name: "Title", type: "text" },
  { name: "Description", type: "text" },
  { name: "Status", type: "text" },
  { name: "Action", type: "text" },
];

//CaseStudies columns
export const CaseStudiesColumns = [
  { name: "Image", type: "text" },
  { name: "Title", type: "text" },
  { name: "Description", type: "text" },
  { name: "Status", type: "text" },
  { name: "Action", type: "text" },
];

//Founder columns
export const FounderColumns = [
  { name: "Image", type: "text" },
  { name: "Name", type: "text" },
  { name: "Title", type: "text" },
  { name: "Description", type: "text" },
  { name: "Status", type: "text" },
  { name: "Action", type: "text" },
];

//OurClients columns
export const OurClientsColumns = [
  { name: "Image", type: "text" },
  { name: "Client Name", type: "text" },
  { name: "Client Description", type: "text" },
  { name: "Status", type: "text" },
  { name: "Action", type: "text" },
];

//About columns
export const AboutColumns = [
  { name: "Image", type: "text" },
  { name: "Name", type: "text" },
  { name: "Founder Title", type: "text" },
  { name: "Founder Description", type: "text" },
  { name: "Status", type: "text" },
  { name: "Action", type: "text" },
];

//Vision columns
export const VisionColumns = [
  { name: "Image", type: "text" },
  { name: "Page Title", type: "text" },
  { name: "Page Description", type: "text" },
  { name: "Status", type: "text" },
  { name: "Action", type: "text" },
];

//Evenets columns
export const EvenetsColumns = [
  { name: "Image", type: "text" },
  { name: "Title", type: "text" },
  { name: "Sub Title", type: "text" },
  { name: "Description", type: "text" },
  { name: "Date", type: "text" },
  { name: "Venue", type: "text" },
  { name: "Status", type: "text" },
  { name: "Action", type: "text" },
];

//Connect columns
export const ConnectColumns = [
  { name: "Image", type: "text" },
  { name: "Page Title", type: "text" },
  { name: "Page Description", type: "text" },
  { name: "Name", type: "text" },
  { name: "Founder Sub Title", type: "text" },
  { name: "Founder Description", type: "text" },
  { name: "Status", type: "text" },
  { name: "Action", type: "text" },
];

//Academy columns
export const AcademyColumns = [
  { name: "Image", type: "text" },
  { name: "Page Title", type: "text" },
  { name: "Page Sub Title", type: "text" },
  { name: "Page Description", type: "text" },
  { name: "Status", type: "text" },
  { name: "Action", type: "text" },
];

//OnlineCourse columns
export const OnlineCourseColumns = [
  { name: "Image", type: "text" },
  { name: "Page Title", type: "text" },
  { name: "Page Description", type: "text" },
  { name: "Status", type: "text" },
  { name: "Action", type: "text" },
];

//LiveClasses columns
export const LiveClassesColumns = [
  { name: "Image", type: "text" },
  { name: "Page Title", type: "text" },
  { name: "Page Description", type: "text" },
  { name: "Status", type: "text" },
  { name: "Action", type: "text" },
];

//Online course Page Columns

// Academy online course columns
export const AllOnlineCourseColumns = [
  { name: "Image", type: "text" },
  { name: "Page Title", type: "text" },
  { name: "Page Description", type: "text" },
  { name: "Status", type: "text" },
  { name: "Action", type: "text" },
];

// online courses columns
export const AllOnlineCoursesColumns = [
  { name: "Image", type: "text" },
  { name: "Title", type: "text" },
  { name: "Price", type: "text" },
  { name: "Description", type: "text" },
  { name: "Status", type: "text" },
  { name: "Action", type: "text" },
];

// online courses columns
export const AllFreeProgramsColumns = [
  { name: "Video/Title", type: "text" },
  { name: "Action", type: "text" },
];

// User Report columns
export const userReportColumns = [
  { name: "No", type: "text", value: "id" },
  { name: "First Name", type: "text", value: "first_name" },
  { name: "Last Name", type: "text", value: "last_name" },
  { name: "Email", type: "text", value: "email" },
  { name: "Mobile", type: "text", value: "mobile" },
  { name: "Date Of Birth", type: "text", value: "dob" },
  { name: "Country", type: "text", value: "country" },
  { name: "State", type: "text", value: "state" },
  { name: "City", type: "text", value: "city" },
  { name: "Course Status", type: "text", value: "coursestatus" },
  { name: "Affiliate Status", type: "text", value: "affiliatestatus" },
  { name: "Join Date", type: "text", value: "created_at" },
];

// User Report columns
export const liveClassReportColumns = [
  { name: "No", type: "text" },
  { name: "Course Name", type: "text" },
  { name: "First Name", type: "text" },
  { name: "Last Name", type: "text" },
  { name: "Email", type: "text" },
  { name: "Mobile", type: "text" },
  { name: "State", type: "text" },
  { name: "City", type: "text" },
  { name: "Purchase Date", type: "text" },
  { name: "Expiry Date", type: "text" },
  { name: "Transaction Id", type: "text" },
  { name: "Amount", type: "text" },
  { name: "GST(18%)", type: "text" },
  { name: "Net Amount", type: "text" },
];

// Contact inquiry columns
export const inquiryColumns = [
  { name: "No", type: "text" },
  { name: "First Name", type: "text" },
  { name: "Last Name", type: "text" },
  { name: "Email", type: "text" },
  { name: "Mobile", type: "text" },
  { name: "Date", type: "text" },
  { name: "Message", type: "text" },
];

// Affiliate Report columns

export const affiliateReportColumns = [
  { name: "Sr No", type: "text" },
  { name: "Affiliate Name", type: "text", value: "account_holder_name" },
  { name: "Affiliate Code", type: "text" },
  { name: "Joining Date", type: "text", value: "created_at" },
  { name: "Phone", type: "text" },
  { name: "Email", type: "text" },
  { name: "who are you", type: "text" },
  { name: "Monthly Sales", type: "text" },
  { name: "Nutrition", type: "text" },
  { name: "Training", type: "text" },
  { name: "Ticket", type: "text" },
  { name: "Membership", type: "text" },
  { name: "Bodybuilding", type: "text" },
  { name: "Powerlifting", type: "text" },
  { name: "Unsettled Amount", type: "text" },
  { name: "Last Month Earnings to HS", type: "text" },
  { name: "Last Month Revenue", type: "text" },
  { name: "Paying Amount", type: "text" },
  { name: "Profile", type: "text" },
];

export const affiliateuserDetailsColumns = [
  { name: "Sr No", type: "text" },
  { name: "Purchase Type", type: "text" },
  { name: "Purchase Quantity", type: "text" },
  { name: "Referral Amount", type: "text" },
  { name: "Transaction Id", type: "text" },
  { name: "Order Amount", type: "text" },
  { name: "Order Amount (GST)", type: "text" },
  { name: "Purchase Date", type: "text" },
];
