import React, { useEffect, useState } from "react";

// Import Datatable Settings
import { COLUMNS_KEY } from "../../appconfig/dataTableSetting";

// Import App helper
import { columnsToRows } from "../../appconfig/AppHelper";

import { DataTable, SkeletonBodyText } from "@shopify/polaris";
import { ASSET_PATH } from "../../appconfig/Settings";

const SkeletonDataTable = ({ columns, defaultLength, isImage }) => {
  const [tablerow, settablerow] = useState([]);
  const [defaultlength] = useState(defaultLength);

  useEffect(() => {
    skeletonData();
  }, []);

  const skeletonData = () => {
    // Props Variable

    let tableRows = [];
    let ImageAdd = [];

    if (isImage) {
      ImageAdd.push(
        <img src={ASSET_PATH + "/front/no-image.jpg"} alt="No-Display"></img>
      );
    }
    //loop iterate
    for (let j = 0; j < 5; j++) {
      const tableRowsAppend = [];
      for (let i = 0; i < defaultlength; i++) {
        tableRowsAppend.push(<SkeletonBodyText lines={isImage ? 3 : 1} />);
        //Table Row append in array
      }
      tableRows.push(tableRowsAppend);
      if (isImage) {
        tableRows[j][0] = ImageAdd;
      }
    }
    settablerow(tableRows);
  };

  return (
    <>
      <DataTable
        verticalAlign="middle"
        columnContentTypes={columnsToRows(columns, COLUMNS_KEY.TYPE)}
        headings={columnsToRows(columns, COLUMNS_KEY.NAME)}
        rows={tablerow}
      />
    </>
  );
};

export default SkeletonDataTable;
