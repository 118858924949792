import React from "react";

import { BrowserRouter } from "react-router-dom";
import Admin from "./Admin";
import AuthPages from "./Authpages";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <BrowserRouter>
      {window.location.pathname !== "/" ? <Admin /> : null}
      {window.location.pathname === "/" ? <AuthPages /> : null}
    </BrowserRouter>
  );
};
