/********************  START APP API SLUG ************* */

// Prefixed App URL
const PREFIX = "/admin/";

// Authentication
export const LOGIN = "/login";

//Meta Data
export const ADD_METADATA = PREFIX + "add-meta-details";
export const GET_METADATA = PREFIX + "get-meta-details";

//Common Component
export const ADD_COMMONDATA = PREFIX + "add-update-common-details";
export const GET_COMMONDATA = PREFIX + "get-common-details";
export const UPDATE_COMMONSTATUS = PREFIX + "change-common-status";
export const DELETE_COMMONDATA = PREFIX + "delete-common-details";
export const GET_COMMONDATA_BYID = PREFIX + "get-common-detail-by-id";

//Delete Image
export const DELETE_IMAGE = PREFIX + "delete-image";

// Home Page
export const ADD_HOMEDATA = PREFIX + "add-update-homepage-details";
export const GET_HOMEDATA = PREFIX + "get-homepage-details";
export const UPDATE_STATUS = PREFIX + "change-homepage-status";
export const DELETE_HOMEDATA = PREFIX + "delete-homepage-details";
export const GET_DATA_BYID = PREFIX + "get-homepage-detail-by-id";

//AboutUS page
export const ADD_ABOUTUSDATA = PREFIX + "add-update-about-details";
export const GET_ABOUTUSDATA = PREFIX + "get-about-details";
export const UPDATE_ABOUTUSSTATUS = PREFIX + "change-about-status";
export const DELETE_ABOUTUSDATA = PREFIX + "delete-about-details";
export const GET_ABOUTDATA_BYID = PREFIX + "get-about-detail-by-id";

//Event Page
export const ADD_EVENTDATA = PREFIX + "add-update-event-details";
export const GET_EVENTDATA = PREFIX + "get-event-details";
export const UPDATE_EVENTSTATUS = PREFIX + "change-event-status";
export const DELETE_EVENTDATA = PREFIX + "delete-event-details";
export const GET_EVENTDATA_BYID = PREFIX + "get-event-detail-by-id";

//Academy Page
export const ADD_ACADEMYDATA = PREFIX + "add-update-academy-details";
export const GET_ACADEMYDATA = PREFIX + "get-academy-details";
export const UPDATE_ACADEMY_STATUS = PREFIX + "change-academy-status";
export const DELETE_ACADEMYDATA = PREFIX + "delete-academy-details";
export const GET_ACADEMYDATA_BYID = PREFIX + "get-academy-detail-by-id";
export const DELETE_ACADEMY_IMAGE = PREFIX + "delete-academy-image";

//Online Course Page
export const ADD_ONLINECOURSE = PREFIX + "add-update-subscription-details";
export const GET_ONLINECOURSE = PREFIX + "get-subscription-details";
export const GET_ONLINECOURSE_BYID = PREFIX + "get-subscription-detail-by-id";
export const UPDATE_ONLINECOURSE_STATUS = PREFIX + "change-subscription-status";
export const DELETE_ONLINECOURSE = PREFIX + "delete-subscription-details";

//Online Course Page
export const ADD_FREEPROGRAM = PREFIX + "add-free-program";
export const GET_FREEPROGRAM = PREFIX + "get-all-programs";
export const DELETE_FREEPROGRAM = PREFIX + "delete-free-program";
export const UPDATE_FREEPROGRAM = PREFIX + "update-free-program";

//Contact us
export const GET_CONTACTDATA = PREFIX + "get-inquiry";

//All Report
export const GET_USER_REPORT = PREFIX + "registered-users";
export const GET_AFFILIATE_REPORT = PREFIX + "affiliate-users";
export const GET_USERCOURES_REPORT = PREFIX + "course-purchased";
export const GET_COURSE_TYPES_REPORT = PREFIX + "get-subscription-by-type";

//DashBoard Data
export const GETDASHBOARD_DETAILS = PREFIX + "admin-dashboard";

//Contat inquiry

export const GET_CONTACT_INQUIRY = PREFIX + "get-inquiry-data";

export const GET_AFFILIATE_USER_DETIALS = PREFIX + "affiliate-user-data";

export const GET_AFFILIATE_DASH_DETAILS = PREFIX + "affiliate-dashboard";

export const AFFILIATE_PAYOUT = PREFIX + "affiliate-payout";
