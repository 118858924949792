import React, { useCallback, useState } from "react";
import { Frame, TopBar, Icon, FooterHelp } from "@shopify/polaris";
import { useHistory } from "react-router-dom";
import "../assets/scss/mainpage.css";
import {
  LogOutMinor,
  HomeMajor,
  InventoryMajor,
  GlobeMajor,
  BlogMajor,
  EnvelopeMajor,
  ReportsMajor,
  DesktopMajor,
  CustomersMajor,
  ChatMajor,
  DataVisualizationMajor,
  AffiliateMajor,
} from "@shopify/polaris-icons";
import "../assets/scss/submenu.scss";

//End Count Function
const FrameExample = ({ children }) => {
  const history = useHistory();

  // logout
  const toggleClick = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    window.location.href = "/";
  };

  const [userMenuActive, setUserMenuActive] = useState(false);

  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);

  const toggleUserMenuActive = useCallback(
    () => setUserMenuActive((userMenuActive) => !userMenuActive),
    []
  );
  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive
      ),
    []
  );

  const userMenuActions = [
    {
      items: [{ content: "Logout", icon: LogOutMinor, onAction: toggleClick }],
    },
  ];

  const userMenuMarkup = (
    <>
      <TopBar.UserMenu
        actions={userMenuActions}
        open={userMenuActive}
        onToggle={toggleUserMenuActive}
      />
      <span style={{ marginRight: "20px" }}>
        {localStorage.getItem("name")}
      </span>
    </>
  );

  const topBarMarkup = (
    <TopBar
      showNavigationToggle
      userMenu={userMenuMarkup}
      onNavigationToggle={toggleMobileNavigationActive}
    />
  );

  const nav_arr = [
    {
      title: "Home Page",
      link: "/home",
      icon: HomeMajor,
      active: true,
      subLink: [
        {
          title: "Home Banner",
          link: "/homebanner",
          active: false,
        },
        {
          title: "Mission",
          link: "/mission",
          active: false,
        },
        {
          title: "About",
          link: "/home/about",
          active: false,
        },

        {
          title: "Live Course and Certification",
          link: "/home/livecourse",
          active: false,
        },
      ],
    },
    {
      title: "Affiliate Dashboard",
      link: "/affiliatedashboard",
      icon: AffiliateMajor,
      active: false,
      subLink: [
        {
          title: "Affiliate Report",
          link: "/affiliate-report",
          active: false,
        },
      ],
    },
    {
      title: "General",
      icon: GlobeMajor,
      active: false,
      subLink: [
        {
          title: "Clients",
          link: "/clients",
          active: false,
        },
      ],
    },
    {
      title: "About Us",
      icon: CustomersMajor,
      active: false,
      subLink: [
        {
          title: "About",
          link: "/about",
          active: false,
        },
        {
          title: "Vision",
          link: "/vision",
          active: false,
        },
      ],
    },
    {
      title: "Academy",
      icon: InventoryMajor,
      active: false,
      subLink: [
        {
          title: "Academy",
          link: "/academy",
          active: false,
        },
        {
          title: "Online Course",
          link: "/academy/onlinecourse",
          active: false,
        },
        {
          title: "Live Classes",
          link: "/liveclasses",
          active: false,
        },
      ],
    },
    {
      title: "Online Course",
      icon: EnvelopeMajor,
      active: false,
      subLink: [
        {
          title: "Online Course",
          link: "/onlinecourses",
          active: false,
        },
        {
          title: "Online Course Banner",
          link: "/onlinecoursebanner",
          active: false,
        },
      ],
    },
    {
      title: "Free Program",
      link: "/freeprogram",
      icon: DesktopMajor,
      active: false,
    },
    {
      title: "Events",
      link: "/events",
      icon: DataVisualizationMajor,
      active: false,
    },
    {
      title: "Connect",
      link: "/connect",
      icon: BlogMajor,
      active: false,
    },

    {
      title: "Inquiry",
      link: "/inquiry",
      icon: ChatMajor,
      active: false,
    },

    {
      title: "Reports",
      icon: ReportsMajor,
      active: false,
      subLink: [
        {
          title: "Users",
          link: "/user-report",
          active: false,
        },
        {
          title: "Online Course",
          link: "/onlinecourse-report",
          active: false,
        },
        {
          title: "Live Class",
          link: "/liveclass-report",
          active: false,
        },
      ],
    },
  ];

  const [Nav, setNav] = useState(nav_arr);

  const selectmenu = (label) => {
    let Blanck_Arr = [];
    nav_arr.map((item, index) => {
      if (item.title === label) {
        item.active = true;
      } else {
        item.active = false;
      }
      Blanck_Arr.push(item);
    });
    setNav(Blanck_Arr);
  };

  const selectsubmenu = (label, sublabel) => {
    let Blanck_Arr = [];
    nav_arr.map((item, index) => {
      if (item.title === label) {
        item.active = true;
        item.subLink &&
          item.subLink.length > 0 &&
          item.subLink.map((subitem, index) => {
            if (subitem.title === sublabel) {
              subitem.active = true;
            } else {
              subitem.active = false;
            }
          });
      } else {
        item.active = false;
      }
      Blanck_Arr.push(item);
    });
    setNav(Blanck_Arr);
  };

  const custome = () => {
    return (
      <ul className="navbar_ul_main">
        {Nav.length > 0 &&
          Nav.map((item, index) => {
            return (
              <>
                <li
                  className={
                    item.active
                      ? "navbar_li active_child active_main"
                      : "navbar_li "
                  }
                  onClick={() => selectmenu(item.title)}
                  key={index}
                >
                  <div
                    className="navbar_div"
                    onClick={() => redirectPage(item.link)}
                  >
                    <div className="navbar_inner flex_view_xs">
                      <Icon source={item.icon} />
                      <span className="navbar_text">{item.title}</span>
                    </div>
                  </div>
                  <div className="sub_div">
                    <ul className="sub_ul">
                      {item.subLink &&
                        item.subLink.length > 0 &&
                        item.subLink.map((subitem, index) => {
                          return (
                            <li
                              key={"cs" + index}
                              className={
                                subitem.active
                                  ? "navbar_li active_sub_child"
                                  : "navbar_li"
                              }
                            >
                              <div
                                className="sub_inner_div"
                                onClick={() =>
                                  selectsubmenu(item.title, subitem.title)
                                }
                                url-id={subitem.link}
                              >
                                <span
                                  onClick={() => redirectPage(subitem.link)}
                                  className="sub_btn"
                                >
                                  <span className="sub_title">
                                    {subitem.title}
                                  </span>
                                </span>
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </li>
              </>
            );
          })}
      </ul>
    );
  };

  const redirectPage = (url) => {
    history.push(url);
  };

  return (
    <div>
      <Frame
        topBar={topBarMarkup}
        navigation={custome()}
        showMobileNavigation={mobileNavigationActive}
        onNavigationDismiss={toggleMobileNavigationActive}
      >
        <React.Fragment>{children}</React.Fragment>
        <FooterHelp>
          <div>
            <span className="black-txt">Designed &amp; Developed By&nbsp;</span>
            <a
              href="https://helicoreinfo.com"
              style={{ textDecoration: "none", color: "black" }}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://gujaratpharmacycouncil.co.in/front/favicon-helicore.png"
                width="32"
                height="32"
                alt="NO Display"
              />
              <span className="black-txt">&nbsp;Helicore Info Pvt. Ltd.</span>
            </a>
          </div>
        </FooterHelp>
      </Frame>
    </div>
  );
};
export default FrameExample;
