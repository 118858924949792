import React, { useCallback, useEffect, useState } from 'react';
import { Banner, List, DropZone, Stack, Thumbnail, Button, Icon, Modal, TextField } from '@shopify/polaris';
import { DeleteMinor, NoteMinor } from '@shopify/polaris-icons';

export default function AddImage({ getImagesFromAddImage }) {
    const validImageTypes = ['image/jpeg', 'image/png'];
    const [files, setFiles] = useState([]);
    const [rejectedFiles, setRejectedFiles] = useState([]);
    const [selIndex, setSelIndex] = useState('');
    const [altTextValue, setAltTextValue] = useState([]);

    useEffect(() => {
        getImagesFromAddImage({
            getImages: sendImagesToParent,
            remove: dropzoneremove,
        })
    })

    const sendImagesToParent = () => {
        return files
    }


    const handleDropZone = useCallback(
        (_dropFiles, _acceptedFiles, _rejectedFiles) => {
            //Set Alttext array
            if (_rejectedFiles.length > 0) {
                setRejectedFiles((files) => [...files, _rejectedFiles]);
            }
            if (_acceptedFiles.length > 0) {
                setRejectedFiles([]);
                setFiles((files) => [...files, ..._acceptedFiles])
            }
        }
    );

    const removeClick = (index) => {
        const allFiles = files;
        const tempArr = altTextValue;
        tempArr.splice(index, 1);
        setAltTextValue(tempArr);
        allFiles.splice(index, 1);
        setFiles([...allFiles]);
    };

    const dropzoneremove = useCallback(() => {
        setFiles([])
        uploadedFiles();
    }, [files])

    const uploadedFiles = files.length > 0 && (
        <div style={{ marginn: 10 }}>
            <Stack alignmet={"center"} distribution={"center"} spacing={"loose"}>
                {files.map((file, index) => (
                    <Stack alignment="center" spacing={"tight"} key={index}>
                        <div className="show-image">
                            <div className="show-image variant-img">
                                <div className="variant-img-select">
                                    <Thumbnail
                                        size="large"
                                        alt={file.name}
                                        source={
                                            validImageTypes.includes(file.type)
                                                ? window.URL.createObjectURL(file)
                                                : NoteMinor
                                        }
                                    />
                                </div>
                            </div>
                            {/* <div className="deleteBtn">
                            <div className="delete">
                                <Button size={"slim"} onClick={() => removeClick(index)}><Icon source={DeleteMinor} /></Button>
                            </div>
                            </div> */}
                        </div>
                    </Stack>
                ))}
            </Stack>
        </div>
    );

    const hasError = rejectedFiles.length > 0;

    const errorMessage = hasError && <Banner title="The following images couldn\u2019t be uploaded:" status="critical">
        <List type="bullet">
            {rejectedFiles.map((file, index) => <List.Item key={index}>
                {`"${file[index].name}" is not supported. File type must be .gif, .jpg, .png or .svg.`}
            </List.Item>)}
        </List>
    </Banner>;

    return (
        <>
            {errorMessage}
            <br />
            <div className="image_add_box">
                <DropZone accept="image/*" type="image" onDrop={handleDropZone} style={{ zIndex: 1 }}>
                    {/*{!files.length && <DropZone.FileUpload />}*/}
                    <DropZone.FileUpload />
                </DropZone>
            </div>

            {uploadedFiles}
        </>
    )
}


