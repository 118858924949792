import React from "react";

import enTranslations from "@shopify/polaris/locales/en.json";
import { Modal, TextContainer, AppProvider } from "@shopify/polaris";

const DeleteConfirmModel = ({
  dismissClick,
  deleteBtnSpinner,
  submitClick,
}) => {
  return (
    <>
      <AppProvider i18n={enTranslations}>
        <Modal
          open={true}
          onClose={() => dismissClick()}
          title="Delete"
          primaryAction={{
            content: "Yes",
            onAction: () => submitClick(),
            loading: deleteBtnSpinner,
          }}
          secondaryActions={[
            {
              content: "No",
              onAction: () => dismissClick(),
            },
          ]}
        >
          <Modal.Section>
            <TextContainer>
              <p>Are you sure you want to delete?</p>
            </TextContainer>
          </Modal.Section>
        </Modal>
      </AppProvider>
    </>
  );
};

export default DeleteConfirmModel;
