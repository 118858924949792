import React from "react";
import { useHistory } from "react-router-dom";

function DashBoardCard({ title, sub_title, data }) {
  const history = useHistory();

  const goToReportPage = () => {
    history.push("/onlinecourse-report");
  };

  return (
    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <div className="card-box btm-one btm-card">
        <div className="inner-card">
          <h3 className="card-h">{title}</h3>
          <div className="card-count">
            <span className="report-sm-txt">{sub_title}</span>
          </div>
          <h2 className="number-detail">{data}</h2>
          <i
            className="bi bi-box-arrow-right up-arrow"
            onClick={goToReportPage}
            style={{ cursor: "pointer" }}
          ></i>
        </div>
      </div>
    </div>
  );
}

export default DashBoardCard;
