import { Page, Link, Button } from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { GET_AFFILIATE_DASH_DETAILS } from "../../config/ApiConstant";
import axiosInstance from "../../config/axiosInstance";
import AffiliateDashCard from "../../components/AffiliateDashCard";
import { convertLocalToUTC } from "../../appconfig/AppHelper";
import moment from "moment/moment";
import CustomDatePicker from "../../components/Date-Picker/CustomDatePicker";
import { useHistory } from "react-router-dom";

const AffiliateDashboard = () => {
  const [isAffiliateData, setAffiliateData] = useState([]);
  const history = useHistory();
  const [isDate, setIsdate] = useState({
    startDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });

  //Handle Date Change
  const handleDateChangeEvent = (start, end) => {
    setIsdate({
      startDate: start,
      endDate: end,
    });
  };

  // Fetch Course data on every redering
  useEffect(() => {
    fetchCourseData();
  }, [isDate]);

  // Call Api function
  const fetchCourseData = async () => {
    // Date TimeStamp
    let startStamp = convertLocalToUTC(isDate.startDate + " 00:00:00");
    let endStamp = convertLocalToUTC(isDate.endDate + " 23:59:59");

    // api call
    const response = await axiosInstance(
      GET_AFFILIATE_DASH_DETAILS +
        "?start_date=" +
        startStamp +
        "&end_date=" +
        endStamp
    );

    if (response) {
      const affiliateData = response.data.data;
      // set data into object
      setAffiliateData(affiliateData);
    } else {
      setAffiliateData([]);
    }
  };
  const goToAffiliateReportPage = () => {
    history.push("/affiliate-report");
  };

  return (
    <>
      <Page>
        <div className="report-dashboard">
          <div class="container">
            <div class="row mb-5">
              <div class="col-9">
                <CustomDatePicker
                  startDate={isDate.startDate}
                  endDate={isDate.endDate}
                  onChange={handleDateChangeEvent}
                ></CustomDatePicker>
              </div>
              <div class="col-3">
                <div>
                  <Button onClick={goToAffiliateReportPage}>
                    View Affiliate report
                  </Button>
                </div>
              </div>
            </div>
            <div class="row g-5">
              <div class="col-4">
                <AffiliateDashCard
                  title="Bodybuilding Sales"
                  data={isAffiliateData?.product?.bodybuilding_sales}
                />
              </div>

              <div class="col-4">
                <AffiliateDashCard
                  title="Nutrition Sales"
                  data={isAffiliateData?.product?.nutrition_sales}
                />
              </div>

              <div class="col-4">
                <AffiliateDashCard
                  title="Powerlifting Sales"
                  data={isAffiliateData?.product?.powerlifting_sales}
                />
              </div>

              <div class="col-4">
                <AffiliateDashCard
                  title="Training Sales"
                  data={isAffiliateData?.product?.training_sales}
                />
              </div>

              <div class="col-4">
                <AffiliateDashCard
                  title="Membership Sales"
                  data={isAffiliateData?.product?.membership_sales}
                />
              </div>

              <div class="col-4">
                <AffiliateDashCard
                  title="Ticket Sales"
                  data={isAffiliateData?.product?.ticket_sales}
                />
              </div>
            </div>

            <div class="row g-5 mt-2">
              <div class="col-6">
                <AffiliateDashCard
                  title="Total Affiliates"
                  data={isAffiliateData?.total_affiliate_user}
                />
              </div>

              <div class="col-6">
                <AffiliateDashCard
                  title="Affiliates Lifetime Sales"
                  data={isAffiliateData?.lifetime_sales}
                />
              </div>

              <div class="col-6">
                <AffiliateDashCard
                  title="Total Revenue"
                  data={parseFloat(isAffiliateData.total_revenue_hs).toFixed(2)}
                />
              </div>

              <div class="col-6">
                <AffiliateDashCard
                  title="Total Net Revenue"
                  data={parseFloat(isAffiliateData.total_net_revenue).toFixed(
                    2
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </Page>
    </>
  );
};

export default AffiliateDashboard;
