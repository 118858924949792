import React, { useEffect, useState } from "react";
import axiosInstance from "../config/axiosInstance";

import enTranslations from "@shopify/polaris/locales/en.json";
import {
  Modal,
  AppProvider,
  Thumbnail,
  FormLayout,
  Stack,
} from "@shopify/polaris";
import { NoteMajor } from "@shopify/polaris-icons";
import { GET_FREEPROGRAM } from "../config/ApiConstant";

const PDFViewModel = ({ dismissClick, id }) => {
  const [pdfData, setPdfData] = useState([]);

  useEffect(() => {
    getDataFromAPI();
  }, []);

  //api call
  const getDataFromAPI = async () => {
    const response = await axiosInstance(GET_FREEPROGRAM + "?id=" + id);

    if (response) {
      const freeProgramList = response.data.data;
      const { pdf } = freeProgramList;

      setPdfData(JSON.parse(pdf));
    }
  };

  return (
    <>
      <AppProvider i18n={enTranslations}>
        <Modal
          open={true}
          onClose={() => dismissClick()}
          title="All PDF"
          secondaryActions={[
            {
              content: "Close",
              onAction: () => dismissClick(),
            },
          ]}
        >
          <Modal.Section>
            {pdfData.map((data, i) => (
              <Stack distribution="leading">
                <Stack.Item>
                  <a href={data.url} target="_blank" rel="noreferrer">
                    <Thumbnail source={NoteMajor} size="small" alt="PDF" />
                  </a>
                </Stack.Item>
                <Stack.Item>
                  <h5 style={{ marginTop: "5px" }}>{data.title}</h5>
                  <br />
                </Stack.Item>
              </Stack>
            ))}
          </Modal.Section>
        </Modal>
      </AppProvider>
    </>
  );
};

export default PDFViewModel;
