import React, { useState, useEffect } from "react";
//shopify
import {
  Card,
  TextField,
  TextStyle,
  Page,
  Stack,
  FormLayout,
} from "@shopify/polaris";
import "../../../assets/scss/helicore_common.scss";
import ToasterToggle from "../../../components/Toster";
import {
  ADD_COMMONDATA,
  GET_COMMONDATA_BYID,
} from "../../../config/ApiConstant";
import axiosInstance from "../../../config/axiosInstance";
import CKEditor from "react-ckeditor-component";
import { useHistory } from "react-router-dom";
import UpdateImages from "../../../components/UpdateImages";

const UpdateClients = (props) => {
  const [clientsData, setClientsData] = useState({});
  const [validation, setValidation] = useState({
    name: false,
    descriptionData: false,
    image: false,
  });

  document.title = "Our Clients | Himalayan Stallion";
  const [submitLoading, setSubmitLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState();
  const [toasttoggle, setToastToggle] = useState(false);
  const [isError, setisError] = useState(false);
  const [descriptionData, setDescriptionData] = useState();
  const [editId, setEditId] = useState(null);
  const [updatedImage, setUpdatedImage] = useState([]);

  const history = useHistory();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getClientsData();
  }, []);

  //images variables
  let getImagesRefFromUpdateImage = null;

  //gets media images
  const getImagesFromUpdateImage = (ref) => {
    getImagesRefFromUpdateImage = ref;
    if (getImagesRefFromUpdateImage?.getImages()) {
      // getClientsData();
    }
  };

  // handleChange
  const handleChange = (name, value) => {
    let validations = true;
    if (value !== "" && value !== undefined) {
      validations = false;
    }
    setValidation((prev) => ({ ...prev, [name]: validations }));
    setClientsData({ ...clientsData, [name]: value });
  };

  //onChange description
  const onChangeDesc = (name, event) => {
    var newContent = event.editor.getData();

    if (name === "description") {
      setDescriptionData(newContent);
    }

    let validations = true;
    if ([name] !== "" && [name] !== undefined) {
      validations = false;
    }

    setValidation((prev) => ({ ...prev, descriptionData: validations }));
  };

  //checks validation
  const validate = () => {
    let validate = true;
    const image = getImagesRefFromUpdateImage?.getImages();
    //Validation
    if (window.cn(clientsData.name)) {
      setValidation((prev) => ({ ...prev, name: true }));
      validate = false;
    }
    if (window.cn(descriptionData)) {
      setValidation((prev) => ({ ...prev, descriptionData: true }));
      validate = false;
    }

    if (editId == null || editId === "" || editId === undefined) {
      if (image.length <= 0) {
        setValidation((prev) => ({ ...prev, image: true }));
        validate = false;
      }
    }
    return validate;
  };

  //gethome banner data by id
  const getClientsData = async () => {
    const clientsId = props.location.state;
    setEditId(clientsId);

    const response = await axiosInstance(GET_COMMONDATA_BYID + "/" + clientsId);
    if (response) {
      const DataList = response.data.data;
      const { name, description, images } = DataList;

      setClientsData({
        ...clientsData,
        name: name,
      });
      setUpdatedImage(JSON.parse(images));
      setDescriptionData(description);
    }
  };

  //Add data
  const onSubmit = async () => {
    try {
      const isValidate = validate();
      if (isValidate) {
        setSubmitLoading(true);

        const formData = new FormData();

        const images = getImagesRefFromUpdateImage?.getImages();

        const { name } = clientsData;

        //set formData
        formData.append("name", name);
        formData.append("description", descriptionData);
        for (let i = 0; i < images.length; i++) {
          formData.append("images[]", images[i]);
        }
        formData.append("id", editId);
        formData.append("content_type", "common_clientsData");

        // Api call
        const response = await axiosInstance.post(ADD_COMMONDATA, formData);
        if (response.data) {
          setSubmitLoading(false);
          setClientsData({});
          setDescriptionData("");
          setEditId(null);
          setUpdatedImage([]);
          setTimeout(() => {
            history.push("/clients");
          }, 2000);
          setToastToggle(true);
          setToastMessage(response.data.message);
          setisError(false);
          getImagesRefFromUpdateImage?.remove();
        }
      }
    } catch (error) {
      if (error.response) {
        setSubmitLoading(false);
        setToastToggle(true);
        setToastMessage(error.response.data.error);
        setisError(true);
      }
    }
  };

  //CllearData
  const clearAll = () => {
    setClientsData({});
    setDescriptionData("");
    setUpdatedImage([]);
    getImagesRefFromUpdateImage?.remove();
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <Page
      title="Our Clients"
      fullWidth
      breadcrumbs={[
        {
          content: "Reports",
          onAction: handleBack,
        },
      ]}
    >
      <Card
        sectioned
        secondaryFooterActions={[
          { content: "Cancel", onAction: () => clearAll() },
        ]}
        primaryFooterAction={{
          content: <>Update</>,
          loading: submitLoading,
          onAction: () => onSubmit(),
        }}
      >
        <FormLayout>
          <TextField
            label="Client Name"
            value={clientsData.name}
            onChange={(value) => handleChange("name", value)}
            autoComplete="off"
            error={validation.name ? "Client Name can't be blank." : false}
          />

          <Stack.Item>
            <label className="Polaris-Label__Text Polaris-Label__RequiredIndicator label-font">
              Image
            </label>
            <UpdateImages
              getImagesFromUpdateImage={getImagesFromUpdateImage}
              getImageArray={updatedImage}
              getUpdatedId={editId}
              getContentType={"Common"}
            />

            {validation.image ? (
              <div className="Polaris-InlineError">
                <div className="Polaris-InlineError__Icon">
                  <span className="Polaris-Icon">
                    <span className="Polaris-VisuallyHidden"></span>
                    <svg
                      viewBox="0 0 20 20"
                      className="Polaris-Icon__Svg"
                      focusable="false"
                      aria-hidden="true"
                    >
                      <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zM9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"></path>
                    </svg>
                  </span>
                </div>
                <span>Image is required</span>
              </div>
            ) : null}
            <TextStyle variation="warning">
              Note:Image size (1440px*760px).
            </TextStyle>
          </Stack.Item>

          <Stack.Item>
            <label className="Polaris-Label__Text label-font">
              Client Description
            </label>
            <CKEditor
              activeClass="p10"
              content={descriptionData || ""}
              events={{
                change: (content) => onChangeDesc("description", content),
              }}
            />

            {validation.descriptionData ? (
              <div className="Polaris-InlineError">
                <div className="Polaris-InlineError__Icon">
                  <span className="Polaris-Icon">
                    <span className="Polaris-VisuallyHidden"></span>
                    <svg
                      viewBox="0 0 20 20"
                      className="Polaris-Icon__Svg"
                      focusable="false"
                      aria-hidden="true"
                    >
                      <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zM9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"></path>
                    </svg>
                  </span>
                </div>
                <span>Client Description can't be blank</span>
              </div>
            ) : null}
          </Stack.Item>
        </FormLayout>
      </Card>

      {/* toaster */}
      {toasttoggle && (
        <ToasterToggle
          toastmessage={toastMessage}
          toastactive={() => setToastToggle(false)}
          isErrorMessage={isError}
        />
      )}
    </Page>
  );
};

export default UpdateClients;
