import React, { useState, useEffect } from "react";
//shopify
import {
  Card,
  TextField,
  TextStyle,
  Page,
  Stack,
  Thumbnail,
  FormLayout,
  Select,
  Button,
} from "@shopify/polaris";
import "../../../assets/scss/helicore_common.scss";
import CategoryImage from "../../../components/CategoryImage";
import ToasterToggle from "../../../components/Toster";
import { ADD_ONLINECOURSE } from "../../../config/ApiConstant";
import axiosInstance from "../../../config/axiosInstance";
import { useHistory } from "react-router-dom";
import {
  liveCourseStatusOptions,
  liveCourseClassTypeOptions,
  Selectoptions,
} from "../../../appconfig/Settings";
import DatePicker from "react-date-picker";
import { convertDateFrom } from "../../../appconfig/AppHelper";
import VideoUpload from "../../../components/VideoUpload";
import Ckeditor from "react-ckeditor-component/lib/ckeditor";

const AddLiveCourseCertification = () => {
  //Page Title
  document.title = "Live Course and Certification | Himalayan Stallion";

  const [liveClassesData, setLiveClassesData] = useState({});
  const [formValues, setFormValues] = useState([
    { video_title: "", video_description: "" },
  ]);

  //Set State
  const [validation, setValidation] = useState({
    title: false,
    course_name: false,
    class_time: false,
    liveClassesData: false,
    image: false,
    button_title: false,
    button_link: false,
    status: false,
    price: false,
    class_days: false,
    certification: false,
    total_hours: false,
    class_type: false,
    exam_type: false,
    minimum_requirment: false,
    syllabus: false,
    course_length_days: false,
    description: false,
    language: false,
  });

  const [submitLoading, setSubmitLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState();
  const [toasttoggle, setToastToggle] = useState(false);
  const [isError, setisError] = useState(false);
  const [editId, setEditId] = useState(null);
  const [updatedImage, setUpdatedImage] = useState();

  //Date State
  const [startDateValue, setStartDate] = useState(new Date());
  const [endDateValue, setEndDate] = useState(new Date());
  const [examDateValue, setExamDate] = useState(new Date());
  const [videoArr] = useState([]);

  //useHistory
  const history = useHistory();

  //useEffect
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  // add row form fields
  let addFormFields = () => {
    setFormValues([...formValues, { video_title: "", video_description: "" }]);
  };

  // Remove add more fields
  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    videoArr.splice(i, 1);
    setFormValues(newFormValues);
  };

  //images variables
  let getImagesRefFromAddImage = null;

  //gets media images
  const getImagesFromAddImage = (ref) => {
    getImagesRefFromAddImage = ref;
  };

  let getVideoRefFromUploadVideo = null;

  // Get Videos
  const getVideoFromUploadVideo = (ref) => {
    getVideoRefFromUploadVideo = ref;
    const getVideo = getVideoRefFromUploadVideo?.getVideos();

    const getIndex = getVideoRefFromUploadVideo?.getIndexNumber();
    if (getVideo.length > 0) {
      videoArr[getIndex] = getVideo[0];
    }
  };

  // Form handle change
  let handleChangeData = (name, value, i) => {
    let newFormValues = [...formValues];
    newFormValues[i][name] = value;
    setFormValues(newFormValues);

    let validations = true;
    if (value !== "" && value !== undefined) {
      validations = false;

      setValidation((prev) => ({
        ...prev,
        video_title: validations,
        video_description: validations,
      }));
    }
  };

  // handleChange
  const handleChange = (name, value) => {
    let validations = true;
    if (value !== "" && value !== undefined) {
      validations = false;
    }
    setValidation((prev) => ({ ...prev, [name]: validations }));
    setLiveClassesData({ ...liveClassesData, [name]: value });
  };

  //checks validation
  const validate = () => {
    let validate = true;
    const image = getImagesRefFromAddImage?.getImages();
    const video = getVideoRefFromUploadVideo?.getVideos();

    //Validation
    if (window.cn(liveClassesData.title)) {
      setValidation((prev) => ({ ...prev, title: true }));
      validate = false;
    }
    if (window.cn(liveClassesData.course_name)) {
      setValidation((prev) => ({ ...prev, course_name: true }));
      validate = false;
    }

    if (window.cn(liveClassesData.status)) {
      setValidation((prev) => ({ ...prev, status: true }));
      validate = false;
    }

    if (window.cn(liveClassesData.price)) {
      setValidation((prev) => ({ ...prev, price: true }));
      validate = false;
    }
    if (window.cn(liveClassesData.class_days)) {
      setValidation((prev) => ({ ...prev, class_days: true }));
      validate = false;
    }
    if (window.cn(liveClassesData.class_time)) {
      setValidation((prev) => ({ ...prev, class_time: true }));
      validate = false;
    }

    if (window.cn(liveClassesData.button_title)) {
      setValidation((prev) => ({ ...prev, button_title: true }));
      validate = false;
    }
    if (window.cn(liveClassesData.button_link)) {
      setValidation((prev) => ({ ...prev, button_link: true }));
      validate = false;
    }
    if (window.cn(liveClassesData.language)) {
      setValidation((prev) => ({ ...prev, language: true }));
      validate = false;
    }
    if (window.cn(liveClassesData.course_length_days)) {
      setValidation((prev) => ({ ...prev, course_length_days: true }));
      validate = false;
    }
    if (window.cn(liveClassesData.total_hours)) {
      setValidation((prev) => ({ ...prev, total_hours: true }));
      validate = false;
    }
    if (window.cn(liveClassesData.class_type)) {
      setValidation((prev) => ({ ...prev, class_type: true }));
      validate = false;
    }
    if (window.cn(liveClassesData.exam_type)) {
      setValidation((prev) => ({ ...prev, exam_type: true }));
      validate = false;
    }
    if (window.cn(liveClassesData.minimum_requirment)) {
      setValidation((prev) => ({ ...prev, minimum_requirment: true }));
      validate = false;
    }
    if (window.cn(liveClassesData.syllabus)) {
      setValidation((prev) => ({ ...prev, syllabus: true }));
      validate = false;
    }
    if (window.cn(liveClassesData.certification)) {
      setValidation((prev) => ({ ...prev, certification: true }));
      validate = false;
    }
    if (window.cn(liveClassesData.description)) {
      setValidation((prev) => ({ ...prev, description: true }));
      validate = false;
    }

    if (editId == null || editId === "" || editId === undefined) {
      if (image.length <= 0) {
        setValidation((prev) => ({ ...prev, image: true }));
        validate = false;
      }
      if (video.length <= 0) {
        setValidation((prev) => ({ ...prev, video: true }));
        validate = false;
      }
    }
    return validate;
  };

  //Add data
  const onSubmit = async () => {
    try {
      const isValidate = validate();
      if (isValidate) {
        setSubmitLoading(true);

        const formData = new FormData();

        const images = getImagesRefFromAddImage?.getImages();

        const {
          title,
          course_name,
          button_title,
          button_link,
          status,
          price,
          class_days,
          certification,
          class_time,
          total_hours,
          class_type,
          exam_type,
          minimum_requirment,
          syllabus,
          course_length_days,
          description,
          language,
        } = liveClassesData;

        //set formData
        formData.append("title", title);
        formData.append("sub_title", course_name);
        formData.append("price", price);
        formData.append("from_date", convertDateFrom(startDateValue));
        formData.append("to_date", convertDateFrom(endDateValue));
        formData.append("exam_date", convertDateFrom(examDateValue));
        formData.append("class_days", class_days);
        formData.append("class_time", class_time);
        formData.append("status", status);
        if (images.length > 0) formData.append("image", images[0]);
        formData.append("button_title", button_title);
        formData.append("button_link", button_link);
        formData.append("certification", certification);
        formData.append("total_hours", total_hours);
        formData.append("class_type", class_type);
        formData.append("exam_type", exam_type);
        formData.append("minimum_requirment", minimum_requirment);
        formData.append("syllabus", syllabus);
        formData.append("course_length_days", course_length_days);
        formData.append("description", description);
        formData.append("language", language);
        formData.append("content_type", "home_liveClassesData");
        formData.append("plan_type", "Certification");

        // check edit id
        if (editId && editId != null && editId !== "") {
          formData.append("id", editId);
        }

        for (let i = 0; i < videoArr.length; i++) {
          formData.append("video_file[]", videoArr[i]);
        }
        formData.append("video_data", JSON.stringify(formValues));

        // Api call
        const response = await axiosInstance.post(ADD_ONLINECOURSE, formData);
        if (response.data) {
          setSubmitLoading(false);
          setLiveClassesData({});
          setStartDate("");
          setEndDate("");
          setExamDate("");
          setUpdatedImage("");
          setEditId(null);
          setTimeout(() => {
            history.push("/home/livecourse");
          }, 2000);
          setToastToggle(true);
          setToastMessage(response.data.message);
          setisError(false);
          getImagesRefFromAddImage?.remove();
          getVideoRefFromUploadVideo?.remove();
        }
      }
    } catch (error) {
      if (error.response) {
        setSubmitLoading(false);
        setToastToggle(true);
        setToastMessage(error.response.data.error);
        setisError(true);
      }
    }
  };

  //CllearData
  const clearAll = () => {
    setLiveClassesData({});
    setUpdatedImage("");
    setEditId(null);
    getImagesRefFromAddImage?.remove();
    getVideoRefFromUploadVideo?.remove();
  };

  // Go back to page handler
  const handleBack = () => {
    history.goBack();
  };

  return (
    <Page
      title="Live Course and Certification"
      fullWidth
      breadcrumbs={[
        {
          content: "Reports",
          onAction: handleBack,
        },
      ]}
    >
      <Card
        sectioned
        secondaryFooterActions={[
          { content: "Cancel", onAction: () => clearAll() },
        ]}
        primaryFooterAction={{
          content: <>Add</>,
          loading: submitLoading,
          onAction: () => onSubmit(),
        }}
      >
        <FormLayout>
          <TextField
            label="Title"
            value={liveClassesData.title}
            onChange={(value) => handleChange("title", value)}
            autoComplete="off"
            error={validation.title ? "Title can't be blank." : false}
          />
          {updatedImage ? (
            <>
              <label className="Polaris-Label__Text label-font">
                Old Image
              </label>
              <a href={updatedImage} target="_blank" rel="noreferrer">
                <Thumbnail source={updatedImage} size="large" alt="NO Image" />
              </a>
            </>
          ) : (
            ""
          )}
          <Stack.Item>
            <label className="Polaris-Label__Text Polaris-Label__RequiredIndicator label-font">
              Image
            </label>
            <CategoryImage getImagesFromAddImage={getImagesFromAddImage} />
            {validation.image ? (
              <div className="Polaris-InlineError">
                <div className="Polaris-InlineError__Icon">
                  <span className="Polaris-Icon">
                    <span className="Polaris-VisuallyHidden"></span>
                    <svg
                      viewBox="0 0 20 20"
                      className="Polaris-Icon__Svg"
                      focusable="false"
                      aria-hidden="true"
                    >
                      <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zM9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"></path>
                    </svg>
                  </span>
                </div>
                <span>image is required</span>
              </div>
            ) : null}
            <TextStyle variation="warning">
              Note:Image size (1440px*760px).
            </TextStyle>
          </Stack.Item>
          <Stack.Item>
            <TextField
              label="Course Name"
              value={liveClassesData.course_name}
              onChange={(value) => handleChange("course_name", value)}
              autoComplete="off"
              error={
                validation.course_name ? "Course Name can't be blank." : false
              }
            />
          </Stack.Item>
          <FormLayout.Group>
            <Stack.Item>
              <label className="Polaris-Label__Text label-font">
                Start Date
              </label>

              <DatePicker
                onChange={(e) => setStartDate(e)}
                defaultValue={startDateValue}
                value={startDateValue}
                name="startDate"
              />
            </Stack.Item>

            <Stack.Item>
              <label className="Polaris-Label__Text label-font">End Date</label>

              <DatePicker
                onChange={(e) => setEndDate(e)}
                defaultValue={endDateValue}
                value={endDateValue}
                name="endDate"
              />
            </Stack.Item>
          </FormLayout.Group>
          <Stack.Item>
            <TextField
              label="Fees"
              value={liveClassesData.price}
              onChange={(value) => handleChange("price", value)}
              autoComplete="off"
              error={validation.title ? "Fees section can't be blank." : false}
            />
          </Stack.Item>
          <Stack.Item>
            <label className="Polaris-Label__Text label-font">Exam Date</label>

            <DatePicker
              onChange={(e) => setExamDate(e)}
              defaultValue={examDateValue}
              value={examDateValue}
              name="examDate"
            />
          </Stack.Item>
          <Stack.Item>
            <TextField
              label="Class Days/Week"
              value={liveClassesData.class_days}
              onChange={(value) => handleChange("class_days", value)}
              autoComplete="off"
              error={
                validation.title
                  ? "Class Days/Week section can't be blank."
                  : false
              }
            />
          </Stack.Item>
          <Stack.Item>
            <TextField
              label="Class Time"
              value={liveClassesData.class_time}
              onChange={(value) => handleChange("class_time", value)}
              multiline={4}
              autoComplete="off"
            />

            {validation.class_time ? (
              <div className="Polaris-InlineError">
                <div className="Polaris-InlineError__Icon">
                  <span className="Polaris-Icon">
                    <span className="Polaris-VisuallyHidden"></span>
                    <svg
                      viewBox="0 0 20 20"
                      className="Polaris-Icon__Svg"
                      focusable="false"
                      aria-hidden="true"
                    >
                      <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zM9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"></path>
                    </svg>
                  </span>
                </div>
                <span>Class Time can't be blank</span>
              </div>
            ) : null}
          </Stack.Item>
          <FormLayout.Group>
            <Stack.Item>
              <label
                className="Polaris-Label__Text label-font"
                style={{ marginBottom: "0.25rem" }}
              >
                Select Course Status
              </label>
              <Select
                placeholder="Select Course Status"
                onChange={(value) => handleChange("status", value)}
                value={liveClassesData.status}
                options={liveCourseStatusOptions}
              ></Select>
              {validation.button_link ? (
                <div className="Polaris-InlineError">
                  <div className="Polaris-InlineError__Icon">
                    <span className="Polaris-Icon">
                      <span className="Polaris-VisuallyHidden"></span>
                      <svg
                        viewBox="0 0 20 20"
                        className="Polaris-Icon__Svg"
                        focusable="false"
                        aria-hidden="true"
                      >
                        <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zM9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"></path>
                      </svg>
                    </span>
                  </div>
                  <span>Select Course Status</span>
                </div>
              ) : null}
            </Stack.Item>
          </FormLayout.Group>

          <FormLayout.Group>
            <TextField
              label="Button"
              value={liveClassesData.button_title}
              onChange={(value) => handleChange("button_title", value)}
              autoComplete="off"
              error={
                validation.button_title ? "Button Title can't be blank." : false
              }
            />

            <Stack.Item>
              <label
                className="Polaris-Label__Text label-font"
                style={{ marginBottom: "0.25rem" }}
              >
                Button Link
              </label>
              <Select
                placeholder="Select Link"
                onChange={(value) => handleChange("button_link", value)}
                value={liveClassesData.button_link}
                options={Selectoptions}
              ></Select>
              {validation.button_link ? (
                <div className="Polaris-InlineError">
                  <div className="Polaris-InlineError__Icon">
                    <span className="Polaris-Icon">
                      <span className="Polaris-VisuallyHidden"></span>
                      <svg
                        viewBox="0 0 20 20"
                        className="Polaris-Icon__Svg"
                        focusable="false"
                        aria-hidden="true"
                      >
                        <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zM9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"></path>
                      </svg>
                    </span>
                  </div>
                  <span>Select Link</span>
                </div>
              ) : null}
            </Stack.Item>
          </FormLayout.Group>
          <hr />
          <Stack.Item>
            <h5>Live Course and Certification Details</h5>
          </Stack.Item>
          <Stack.Item>
            <TextField
              label="Certification"
              value={liveClassesData.certification}
              onChange={(value) => handleChange("certification", value)}
              autoComplete="off"
              error={
                validation.certification
                  ? "Certification can't be blank."
                  : false
              }
            />
          </Stack.Item>
          <FormLayout.Group>
            <Stack.Item>
              <TextField
                label="Course Length"
                value={liveClassesData.course_length_days}
                onChange={(value) => handleChange("course_length_days", value)}
                autoComplete="off"
                error={
                  validation.course_length_days
                    ? "Course Length can't be blank."
                    : false
                }
              />
            </Stack.Item>
            <Stack.Item>
              <TextField
                label="Total Hours"
                value={liveClassesData.total_hours}
                onChange={(value) => handleChange("total_hours", value)}
                autoComplete="off"
                error={
                  validation.total_hours ? "Total Hours can't be blank." : false
                }
              />
            </Stack.Item>
          </FormLayout.Group>
          <FormLayout.Group>
            <Stack.Item>
              <label
                className="Polaris-Label__Text label-font"
                style={{ marginBottom: "0.25rem" }}
              >
                Class
              </label>
              <Select
                placeholder="Select Class Type"
                onChange={(value) => handleChange("class_type", value)}
                value={liveClassesData.class_type}
                options={liveCourseClassTypeOptions}
              ></Select>
              {validation.class_type ? (
                <div className="Polaris-InlineError">
                  <div className="Polaris-InlineError__Icon">
                    <span className="Polaris-Icon">
                      <span className="Polaris-VisuallyHidden"></span>
                      <svg
                        viewBox="0 0 20 20"
                        className="Polaris-Icon__Svg"
                        focusable="false"
                        aria-hidden="true"
                      >
                        <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zM9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"></path>
                      </svg>
                    </span>
                  </div>
                  <span>Select Class Type</span>
                </div>
              ) : null}
            </Stack.Item>

            <Stack.Item>
              <TextField
                label="Language"
                value={liveClassesData.language}
                onChange={(value) => handleChange("language", value)}
                autoComplete="off"
                error={validation.language ? "Language can't be blank." : false}
              />
            </Stack.Item>

            <Stack.Item>
              <TextField
                label="Examination"
                value={liveClassesData.exam_type}
                onChange={(value) => handleChange("exam_type", value)}
                autoComplete="off"
                error={
                  validation.exam_type
                    ? "Examination Type can't be blank."
                    : false
                }
              />
            </Stack.Item>
          </FormLayout.Group>
          <Stack.Item>
            <TextField
              label="Minimum Requirements"
              value={liveClassesData.minimum_requirment}
              onChange={(value) => handleChange("minimum_requirment", value)}
              autoComplete="off"
              multiline={2}
              error={
                validation.minimum_requirment
                  ? "Minimum Requirment  can't be blank."
                  : false
              }
            />
          </Stack.Item>
          <Stack.Item>
            <TextField
              label="Course Description"
              value={liveClassesData.description}
              onChange={(value) => handleChange("description", value)}
              autoComplete="off"
              multiline={2}
              error={
                validation.description
                  ? "Course Description can't be blank."
                  : false
              }
            />
          </Stack.Item>
          <Stack.Item>
            <TextField
              label="Syllabus"
              value={liveClassesData.syllabus}
              onChange={(value) => handleChange("syllabus", value)}
              multiline={5}
              autoComplete="off"
              error={validation.syllabus ? "Syllabus can't be blank." : false}
            />
          </Stack.Item>
          <hr />
          <Stack.Item>
            <h5>Online-Course video and it's details</h5>
          </Stack.Item>
          {formValues.map((element, index) => (
            <>
              <Stack.Item>
                <TextField
                  label="Video Title"
                  value={formValues[index].video_title}
                  onChange={(value) =>
                    handleChangeData("video_title", value, index)
                  }
                  autoComplete="off"
                  error={
                    validation.video_title
                      ? "Video Title can't be blank."
                      : false
                  }
                />
              </Stack.Item>

              <Stack.Item>
                <label className="Polaris-Label__Text label-font">
                  Video Description
                </label>
                <Ckeditor
                  activeClass="p10"
                  content={formValues[index].video_description || ""}
                  events={{
                    change: (e) =>
                      handleChangeData(
                        "video_description",
                        e.editor.getData(),
                        index
                      ),
                  }}
                />

                {validation.video_description ? (
                  <div className="Polaris-InlineError">
                    <div className="Polaris-InlineError__Icon">
                      <span className="Polaris-Icon">
                        <span className="Polaris-VisuallyHidden"></span>
                        <svg
                          viewBox="0 0 20 20"
                          className="Polaris-Icon__Svg"
                          focusable="false"
                          aria-hidden="true"
                        >
                          <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zM9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"></path>
                        </svg>
                      </span>
                    </div>
                    <span>Video Description can't be blank</span>
                  </div>
                ) : null}
              </Stack.Item>

              <Stack.Item>
                <label className="Polaris-Label__Text Polaris-Label__RequiredIndicator label-font">
                  Video
                </label>
                <VideoUpload
                  getVideos={getVideoFromUploadVideo}
                  indexNumber={index}
                />

                {validation.video ? (
                  <div className="Polaris-InlineError">
                    <div className="Polaris-InlineError__Icon">
                      <span className="Polaris-Icon">
                        <span className="Polaris-VisuallyHidden"></span>
                        <svg
                          viewBox="0 0 20 20"
                          className="Polaris-Icon__Svg"
                          focusable="false"
                          aria-hidden="true"
                        >
                          <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zM9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"></path>
                        </svg>
                      </span>
                    </div>
                    <span>Video is required</span>
                  </div>
                ) : null}
              </Stack.Item>

              <Stack distribution="leading">
                <Stack.Item>
                  {index ? (
                    <div style={{ color: "#bf0711", marginTop: "10px" }}>
                      <Button
                        monochrome
                        outline
                        onClick={() => removeFormFields(index)}
                      >
                        Remove Video
                      </Button>
                    </div>
                  ) : null}
                </Stack.Item>

                <Stack.Item>
                  <div style={{ marginTop: "10px" }}>
                    <Button primary onClick={() => addFormFields()}>
                      Add More Videos
                    </Button>
                  </div>
                </Stack.Item>
              </Stack>

              <hr />
            </>
          ))}
        </FormLayout>
      </Card>

      {/* toaster */}
      {toasttoggle && (
        <ToasterToggle
          toastmessage={toastMessage}
          toastactive={() => setToastToggle(false)}
          isErrorMessage={isError}
        />
      )}
    </Page>
  );
};

export default AddLiveCourseCertification;
