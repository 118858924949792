import React, { useState, useEffect, useCallback } from "react";
//shopifyy
import {
  Card,
  DataTable,
  Page,
  Stack,
  Select,
  Pagination,
  Button,
  Spinner,
  Icon,
  TextField,
} from "@shopify/polaris";
import "../../../assets/scss/helicore_common.scss";
import {
  AffiliatorFilter,
  defaultPageOptions,
  showing,
  UserPurchaseFilter,
} from "../../../appconfig/Settings";
import {
  columnsToRows,
  convertLocalToUTC,
  exportToCSV,
  formatDate,
  setPagination,
} from "../../../appconfig/AppHelper";
import ToasterToggle from "../../../components/Toster";
import { GET_USER_REPORT } from "../../../config/ApiConstant";
import axiosInstance from "../../../config/axiosInstance";
import DataTableEmptyState from "../../../components/DataTable/DataTableEmptyState";
import SkeletonDataTable from "../../../components/DataTable/SkeletonDataTable";
import {
  COLUMNS_KEY,
  userReportColumns,
} from "../../../appconfig/dataTableSetting";
import { SearchMinor } from "@shopify/polaris-icons";
import moment from "moment/moment";
import CustomDatePicker from "../../../components/Date-Picker/CustomDatePicker";

const UserReport = () => {
  document.title = "User Report | Himalayan Stallion";
  const [pageLoad, setPageLoad] = useState(false);
  const [tableRows, setTableRows] = useState([]);
  const [submitLoading] = useState(false);
  const [paginationInner, setPageinationInner] = useState(setPagination());
  const [dataTableInnerLoading, setDataTableInnerLoading] = useState(false);
  const [perPage, setPerPage] = useState(showing);
  const [isFiledName, setFiledName] = useState({ filedName: "created_at" });
  const [filedType, setFieldType] = useState({
    sort_type: "desc",
  });
  const [toastMessage, setToastMessage] = useState();
  const [toasttoggle, setToastToggle] = useState(false);
  const [isError, setisError] = useState(false);
  const [paginationActive, setPaginationActive] = useState(false);
  const [exportLoader, setExportLoader] = useState(false);
  const [isPurchased, setIsPurchased] = useState("All");
  const [isAffiliator, setIsAffiliator] = useState("All");

  const [isDate, setIsdate] = useState({
    startDate: moment().subtract(1, "years").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const [search, setSearch] = useState("");

  useEffect(() => {
    getDataFromAPI();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    getDataFromAPI();
  }, [perPage, filedType, isPurchased, isAffiliator]);

  const handleChangePerpage = (value) => {
    paginationInner.page = 1;
    setPerPage(value);
  };

  //User purchase handle change
  const handleChangePurchased = (value) => {
    paginationInner.page = 1;
    setIsPurchased(value);
  };

  //Affiliator handle change
  const handleChangeAffiliator = (value) => {
    paginationInner.page = 1;
    setIsAffiliator(value);
  };

  //Sorting First Name

  const handleSort = useCallback((index, direction) => {
    if (direction === "ascending") {
      setFiledName({ filedName: userReportColumns[index].value });
      setFieldType({ sort_type: "asc" });
    } else if (direction === "descending") {
      setFiledName({ filedName: userReportColumns[index].value });
      setFieldType({ sort_type: "desc" });
    } else {
      setFiledName({ filedName: userReportColumns[index].value });
      setFieldType({ sort_type: "asc" });
    }
  }, []);

  const handleChangePage = (action) => {
    action === "Next" ? paginationInner.page++ : paginationInner.page--;
    getDataFromAPI();
  };

  const handleTitleChange = (value) => {
    setSearch(value);
  };

  const handleClearTitle = () => {
    setSearch("");
  };

  //Handle Date Change
  const handleDateChangeEvent = (start, end) => {
    setIsdate({
      startDate: start,
      endDate: end,
    });
  };

  //Export CSV
  const exportCSV = async () => {
    setExportLoader(true);
    const response = await axiosInstance(
      GET_USER_REPORT + "?users=" + isPurchased  + "&affiliate=" +
      isAffiliator
    );

    if (response) {
      setExportLoader(false);
      const List = response.data.data;
      exportToCSV(List, "User_Reports_Data");
    } else {
      setToastToggle(true);
      setToastMessage("Something went wrong while exporting data");
      setExportLoader(false);
    }
  };

  //api call
  const getDataFromAPI = async () => {
    if (pageLoad) setDataTableInnerLoading(true);
    const { page } = paginationInner;

    // Date TimeStamp
    let startStamp = convertLocalToUTC(isDate.startDate + " 00:00:00");
    let endStamp = convertLocalToUTC(isDate.endDate + " 23:59:59");

    //Check Search Parameter base Set Object
    if (search !== "") {
      startStamp = "";
      endStamp = "";
    }

    //API Axios Call
    const response = await axiosInstance(
      GET_USER_REPORT +
        "?page=" +
        page +
        "&per_page=" +
        perPage +
        "&start_date=" +
        startStamp +
        "&end_date=" +
        endStamp +
        "&sort_type=" +
        filedType.sort_type +
        "&sort_by=" +
        isFiledName.filedName +
        "&search=" +
        search +
        "&users=" +
        isPurchased +
        "&affiliate=" +
        isAffiliator
    );
    if (response) {
      const userList = response.data.data.data;

      if (userList.length === 0) {
        setTableRows([]);
        setisError(true);
      } else {
        let tableData = [];
        for (let i = 0; i < userList.length; i++) {
          let tableRowsAppend = [];

          tableRowsAppend.push(i + 1);

          tableRowsAppend.push(
            !window.cn(userList[i].first_name) ? userList[i].first_name : ""
          );
          tableRowsAppend.push(
            !window.cn(userList[i].last_name) ? userList[i].last_name : ""
          );
          tableRowsAppend.push(
            !window.cn(userList[i].email) ? userList[i].email : ""
          );
          tableRowsAppend.push(
            !window.cn(userList[i].mobile) ? userList[i].mobile : ""
          );
          tableRowsAppend.push(
            !window.cn(userList[i].dob) ? formatDate(userList[i].dob) : ""
          );

          tableRowsAppend.push(
            !window.cn(userList[i].country_name)
              ? userList[i].country_name.name
              : ""
          );
          tableRowsAppend.push(
            !window.cn(userList[i].state_name)
              ? userList[i].state_name.name
              : ""
          );

          tableRowsAppend.push(
            !window.cn(userList[i].city_name) ? userList[i].city_name.name : ""
          );

          tableRowsAppend.push(
            !window.cn(userList[i].purchase_status)
              ? userList[i].purchase_status
              : ""
          );

          tableRowsAppend.push(
            !window.cn(userList[i].is_affiliate_user)
              ? userList[i].is_affiliate_user
                ? "Affiliator"
                : "Non-Affiliator"
              : ""
          );

          tableRowsAppend.push(
            !window.cn(userList[i].created_at)
              ? formatDate(userList[i].created_at)
              : ""
          );

          tableData.push(tableRowsAppend);
        }
        setTableRows(tableData);
        setPaginationActive(true);
      }
      setDataTableInnerLoading(false);
      setPageLoad(true);

      //Get Responce Data and Set respectively
      const { current_page, total, per_page, last_page } = response.data.data;
      const pagination = setPagination(
        current_page,
        per_page,
        last_page,
        total
      );
      setPageinationInner(pagination);
    }
  };

  const Paginate = () => {
    return (
      <>
        <Pagination
          previousTooltip="Previous"
          hasPrevious={paginationInner.hasPrevious}
          onPrevious={() => handleChangePage("Previous")}
          hasNext={paginationInner.hasNext}
          nextTooltip="Next"
          onNext={() => handleChangePage("Next")}
        />
      </>
    );
  };

  return (
    <Page
      title="Users"
      fullWidth
      secondaryActions={[
        { content: "Export", onAction: exportCSV, loading: exportLoader },
      ]}
    >
      <Card>
        <Card.Section subdued>
          <Stack distribution="trailing">
            <Stack.Item fill>
              <TextField
                labelHidden
                autoComplete="off"
                placeholder="Enter Title"
                value={search || ""}
                onChange={(value) => handleTitleChange(value)}
                prefix={<Icon source={SearchMinor} color="inkLighter" />}
                clearButton
                onClearButtonClick={() => handleClearTitle()}
              />
            </Stack.Item>
            {/* <Stack.Item>
              <Button
                loading={submitLoading}
                onClick={() => handleSearchButton()}
                primary
                fullWidth
              >
                SEARCH
              </Button>
            </Stack.Item> */}
            <Stack.Item>
              <CustomDatePicker
                startDate={isDate.startDate}
                endDate={isDate.endDate}
                onChange={handleDateChangeEvent}
              ></CustomDatePicker>
            </Stack.Item>

            <Stack.Item>
              <Button
                primary
                fullWidth
                loading={submitLoading}
                onClick={getDataFromAPI}
              >
                Show
              </Button>
            </Stack.Item>

            <Stack.Item>
              <Select
                label="Purchase"
                labelInline
                onChange={handleChangePurchased}
                value={isPurchased}
                options={UserPurchaseFilter}
              ></Select>
            </Stack.Item>

            <Stack.Item>
              <Select
                label="Affiliator"
                labelInline
                onChange={handleChangeAffiliator}
                value={isAffiliator}
                options={AffiliatorFilter}
              ></Select>
            </Stack.Item>

            <Stack.Item>
              <Select
                label="Show"
                labelInline
                onChange={handleChangePerpage}
                value={perPage}
                options={defaultPageOptions}
              ></Select>
            </Stack.Item>
          </Stack>
        </Card.Section>
        <div className="data-wrapper">
          {pageLoad ? (
            <div>
              <DataTable
                verticalAlign="middle"
                hideScrollIndicator={true}
                columnContentTypes={columnsToRows(
                  userReportColumns,
                  COLUMNS_KEY.TYPE
                )}
                sortable={[
                  false,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  false,
                  false,
                  true,
                ]}
                initialSortColumnIndex={1}
                onSort={handleSort}
                headings={columnsToRows(userReportColumns, COLUMNS_KEY.NAME)}
                rows={tableRows}
                footerContent={
                  tableRows.length > 0 ? (
                    <span>{paginationInner.showing}</span>
                  ) : null
                }
              />
              {tableRows.length > 0 ? (
                <div className="paginate">
                  <Stack distribution="center">
                    {paginationActive ? <Paginate /> : null}
                  </Stack>
                </div>
              ) : (
                <DataTableEmptyState />
              )}
            </div>
          ) : (
            <SkeletonDataTable defaultLength={11} columns={userReportColumns} />
          )}
          {dataTableInnerLoading ? (
            <div className="loading">
              <Spinner size="large" />
            </div>
          ) : null}
        </div>
      </Card>

      {/* toaster */}
      {toasttoggle && (
        <ToasterToggle
          toastmessage={toastMessage}
          toastactive={() => setToastToggle(false)}
          isErrorMessage={isError}
        />
      )}
    </Page>
  );
};

export default UserReport;
