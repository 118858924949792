import React, { useState, useEffect } from "react";
//shopify
import {
  Card,
  TextField,
  TextStyle,
  Page,
  Stack,
  FormLayout,
  Select,
  Thumbnail,
} from "@shopify/polaris";
import "../../assets/scss/helicore_common.scss";
import CategoryImage from "../../components/CategoryImage";
import ToasterToggle from "../../components/Toster";
import { ADD_EVENTDATA, GET_EVENTDATA_BYID } from "../../config/ApiConstant";
import axiosInstance from "../../config/axiosInstance";
import CKEditor from "react-ckeditor-component";
import { useHistory } from "react-router-dom";
import { Selectoptions } from "../../appconfig/Settings";
import DatePicker from "react-date-picker";
import { convertDateFrom } from "../../appconfig/AppHelper";

const UpdateEvents = (props) => {
  const [eventsData, setEventsData] = useState({});
  const [validation, setValidation] = useState({
    title: false,
    descriptionData: false,
    date: false,
    image: false,
    venue: false,
    sub_title: false,
    button_title: false,
    button_link: false,
  });

  document.title = "Events | Himalayan Stallion";
  const [submitLoading, setSubmitLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState();
  const [toasttoggle, setToastToggle] = useState(false);
  const [isError, setisError] = useState(false);
  const [editId, setEditId] = useState(null);
  const [descriptionData, setDescriptionData] = useState();
  const [updatedImage, setUpdatedImage] = useState();
  const [dateValue, setDate] = useState(new Date());

  const history = useHistory();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getEventsData();
  }, []);

  //images variables
  let getImagesRefFromAddImage = null;

  //gets media images
  const getImagesFromAddImage = (ref) => {
    getImagesRefFromAddImage = ref;
  };

  // handleChange
  const handleChange = (name, value) => {
    let validations = true;
    if (value !== "" && value !== undefined) {
      validations = false;
    }
    setValidation((prev) => ({ ...prev, [name]: validations }));
    setEventsData({ ...eventsData, [name]: value });
  };

  //handle date
  const handleDate = (e, name) => {
    let validations = true;
    if (e !== "" && e !== undefined) {
      validations = false;
    }
    setValidation((prev) => ({ ...prev, [name]: validations }));

    setDate({ ...dateValue, [name]: e });
  };

  //onChange description
  const onChangeDesc = (name, event) => {
    var newContent = event.editor.getData();

    if (name === "description") {
      setDescriptionData(newContent);
    }

    let validations = true;
    if ([name] !== "" && [name] !== undefined) {
      validations = false;
    }

    setValidation((prev) => ({ ...prev, descriptionData: validations }));
  };

  //checks validation
  const validate = () => {
    let validate = true;
    const image = getImagesRefFromAddImage?.getImages();

    //Validation
    if (window.cn(eventsData.title)) {
      setValidation((prev) => ({ ...prev, title: true }));
      validate = false;
    }
    if (window.cn(eventsData.sub_title)) {
      setValidation((prev) => ({ ...prev, sub_title: true }));
      validate = false;
    }
    if (window.cn(eventsData.button_title)) {
      setValidation((prev) => ({ ...prev, button_title: true }));
      validate = false;
    }
    if (window.cn(eventsData.venue)) {
      setValidation((prev) => ({ ...prev, venue: true }));
      validate = false;
    }
    if (window.cn(eventsData.button_link)) {
      setValidation((prev) => ({ ...prev, button_link: true }));
      validate = false;
    }
    if (window.cn(descriptionData)) {
      setValidation((prev) => ({ ...prev, descriptionData: true }));
      validate = false;
    }
    if (window.cn(dateValue.date)) {
      setValidation((prev) => ({ ...prev, date: true }));
      validate = false;
    }
    if (editId == null || editId === "" || editId === undefined) {
      if (image.length <= 0) {
        setValidation((prev) => ({ ...prev, image: true }));
        validate = false;
      }
    }
    return validate;
  };

  //get events data by id
  const getEventsData = async () => {
    const eventsId = props.location.state;
    setEditId(eventsId);

    const response = await axiosInstance(GET_EVENTDATA_BYID + "/" + eventsId);
    if (response) {
      const DataList = response.data.data;
      const {
        title,
        sub_title,
        date,
        venue,
        button_link,
        button_title,
        description,
        image,
      } = DataList;

      setEventsData({
        ...eventsData,
        title: title,
        button_link: button_link,
        button_title: button_title,
        sub_title: sub_title,
        venue: venue,
      });
      setUpdatedImage(image);
      setDescriptionData(description);
      let updatedDate = new Date(date);
      setDate({
        date: updatedDate,
      });
    }
  };

  //Add data
  const onSubmit = async () => {
    try {
      const isValidate = validate();
      if (isValidate) {
        setSubmitLoading(true);

        const formData = new FormData();

        const images = getImagesRefFromAddImage?.getImages();

        const { title, sub_title, button_title, button_link, venue } =
          eventsData;
        const { date } = dateValue;

        //set formData
        formData.append("title", title);
        formData.append("sub_title", sub_title);
        formData.append("venue", venue);
        formData.append("description", descriptionData);
        if (images.length > 0) formData.append("image", images[0]);
        formData.append("date", convertDateFrom(date));
        formData.append("button_title", button_title);
        formData.append("button_link", button_link);
        formData.append("id", editId);
        formData.append("content_type", "event_eventsData");

        // Api call
        const response = await axiosInstance.post(ADD_EVENTDATA, formData);
        if (response.data) {
          setSubmitLoading(false);
          setEventsData({});
          setDescriptionData("");
          setDate("");
          setTimeout(() => {
            history.push("/events");
          }, 2000);
          setToastToggle(true);
          setToastMessage(response.data.message);
          setisError(false);
          getImagesRefFromAddImage?.remove();
        }
      }
    } catch (error) {
      if (error.response) {
        setSubmitLoading(false);
        setToastToggle(true);
        setToastMessage(error.response.data.error);
        setisError(true);
      }
    }
  };

  //CllearData
  const clearAll = () => {
    setEventsData({});
    setDescriptionData("");
    setDate("");
    getImagesRefFromAddImage?.remove();
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <Page
      title="Events"
      fullWidth
      breadcrumbs={[
        {
          content: "Reports",
          onAction: handleBack,
        },
      ]}
    >
      <Card
        sectioned
        secondaryFooterActions={[
          { content: "Cancel", onAction: () => clearAll() },
        ]}
        primaryFooterAction={{
          content: <>Update</>,
          loading: submitLoading,
          onAction: () => onSubmit(),
        }}
      >
        <FormLayout>
          <FormLayout.Group>
            <TextField
              label="Title"
              value={eventsData.title}
              onChange={(value) => handleChange("title", value)}
              autoComplete="off"
              error={validation.title ? "Title can't be blank." : false}
            />

            <TextField
              label="Sub Title"
              value={eventsData.sub_title}
              onChange={(value) => handleChange("sub_title", value)}
              autoComplete="off"
              error={validation.title ? "Sub Title can't be blank." : false}
            />
          </FormLayout.Group>

          {updatedImage ? (
            <>
              <label className="Polaris-Label__Text label-font">
                Old Image
              </label>
              <a href={updatedImage} target="_blank" rel="noreferrer">
                <Thumbnail source={updatedImage} size="large" alt="NO Image" />
              </a>
            </>
          ) : (
            ""
          )}

          <Stack.Item>
            <label className="Polaris-Label__Text Polaris-Label__RequiredIndicator label-font">
              Image
            </label>
            <CategoryImage getImagesFromAddImage={getImagesFromAddImage} />
            {validation.image ? (
              <div className="Polaris-InlineError">
                <div className="Polaris-InlineError__Icon">
                  <span className="Polaris-Icon">
                    <span className="Polaris-VisuallyHidden"></span>
                    <svg
                      viewBox="0 0 20 20"
                      className="Polaris-Icon__Svg"
                      focusable="false"
                      aria-hidden="true"
                    >
                      <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zM9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"></path>
                    </svg>
                  </span>
                </div>
                <span>image is required</span>
              </div>
            ) : null}
            <TextStyle variation="warning">
              Note:Image size (1440px*760px).
            </TextStyle>
          </Stack.Item>

          <FormLayout.Group>
            <Stack.Item>
              <label className="Polaris-Label__Text label-font">Date</label>
              <DatePicker
                onChange={(e) => handleDate(e, "date")}
                value={dateValue.date}
                name="date"
              />

              {validation.date ? (
                <div className="Polaris-InlineError">
                  <div className="Polaris-InlineError__Icon">
                    <span className="Polaris-Icon">
                      <span className="Polaris-VisuallyHidden"></span>
                      <svg
                        viewBox="0 0 20 20"
                        className="Polaris-Icon__Svg"
                        focusable="false"
                        aria-hidden="true"
                      >
                        <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zM9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"></path>
                      </svg>
                    </span>
                  </div>
                  <span>Select Date</span>
                </div>
              ) : null}
            </Stack.Item>

            <TextField
              label="Venue"
              value={eventsData.venue}
              onChange={(value) => handleChange("venue", value)}
              autoComplete="off"
              error={validation.venue ? "Venue can't be blank." : false}
            />
          </FormLayout.Group>

          <Stack.Item>
            <label className="Polaris-Label__Text label-font">
              Description
            </label>
            <CKEditor
              activeClass="p10"
              content={descriptionData || ""}
              events={{
                change: (content) => onChangeDesc("description", content),
              }}
            />

            {validation.descriptionData ? (
              <div className="Polaris-InlineError">
                <div className="Polaris-InlineError__Icon">
                  <span className="Polaris-Icon">
                    <span className="Polaris-VisuallyHidden"></span>
                    <svg
                      viewBox="0 0 20 20"
                      className="Polaris-Icon__Svg"
                      focusable="false"
                      aria-hidden="true"
                    >
                      <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zM9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"></path>
                    </svg>
                  </span>
                </div>
                <span>Description can't be blank</span>
              </div>
            ) : null}
          </Stack.Item>

          <FormLayout.Group>
            <TextField
              label="Button"
              value={eventsData.button_title}
              onChange={(value) => handleChange("button_title", value)}
              autoComplete="off"
              error={
                validation.button_title ? "Button Title can't be blank." : false
              }
            />

            <Stack.Item>
              <label
                className="Polaris-Label__Text label-font"
                style={{ marginBottom: "0.25rem" }}
              >
                Button Link
              </label>
              <Select
                placeholder="Select Link"
                onChange={(value) => handleChange("button_link", value)}
                value={eventsData.button_link}
                options={Selectoptions}
              ></Select>
              {validation.button_link ? (
                <div className="Polaris-InlineError">
                  <div className="Polaris-InlineError__Icon">
                    <span className="Polaris-Icon">
                      <span className="Polaris-VisuallyHidden"></span>
                      <svg
                        viewBox="0 0 20 20"
                        className="Polaris-Icon__Svg"
                        focusable="false"
                        aria-hidden="true"
                      >
                        <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zM9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"></path>
                      </svg>
                    </span>
                  </div>
                  <span>Select Link</span>
                </div>
              ) : null}
            </Stack.Item>
          </FormLayout.Group>
        </FormLayout>
      </Card>

      {/* toaster */}
      {toasttoggle && (
        <ToasterToggle
          toastmessage={toastMessage}
          toastactive={() => setToastToggle(false)}
          isErrorMessage={isError}
        />
      )}
    </Page>
  );
};

export default UpdateEvents;
